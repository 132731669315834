import { Alert } from 'antd';
import { FlexBox } from 'components/Helpers';
import { useMemo } from 'react';
import { validateJsonProperty } from 'utlis/validationUtil';
import {
  ShiftsOverwriteConfigSchema,
  ShiftsOverwriteConfigTime,
  shiftsOverwriteDeviceProperty,
} from './ShiftsOverwriteFeature';

interface Props {
  values: Record<string, string>;
}

const ShiftsOverwriteFeatureSummary: React.FC<Props> = ({ values }) => {
  const [config, validationError] = useMemo(
    () =>
      validateJsonProperty(
        values[shiftsOverwriteDeviceProperty],
        ShiftsOverwriteConfigSchema,
      ),
    [values],
  );

  if (validationError !== null) {
    return (
      <Alert
        message="Fehler"
        description={validationError}
        type="error"
        showIcon
      />
    );
  }

  if (config === null) return null;

  const renderTime = (time: ShiftsOverwriteConfigTime) => {
    return (
      <span>
        {time.hours}:{time.minutes.toString().padStart(2, '0')} Uhr
        {time.days === 1 && ' (Nächster Tag)'}
      </span>
    );
  };

  const renderShift = (
    name: string,
    shift: {
      start: ShiftsOverwriteConfigTime;
      end: ShiftsOverwriteConfigTime;
    },
  ) => {
    return (
      <tr>
        <td>{name}</td>
        <td>{renderTime(shift.start)}</td>
        <td>{renderTime(shift.end)}</td>
      </tr>
    );
  };

  return (
    <FlexBox direction="column" withgap>
      <table style={{ textAlign: 'left' }} cellPadding={4}>
        <thead>
          <tr>
            <th>Schicht</th>
            <th>Start</th>
            <th>Ende</th>
          </tr>
        </thead>
        {renderShift('Frühschicht', config.early)}
        {renderShift('Spätschicht', config.late)}
        {renderShift('Nachtschicht', config.night)}
      </table>
    </FlexBox>
  );
};

export default ShiftsOverwriteFeatureSummary;

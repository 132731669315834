import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Alert, Button, message, Popconfirm, Table } from 'antd';
import {
  deleteSeniorCareOrganizationsUsers,
  writeSeniorCareOrganizationsUsers,
} from 'api/seniorcare/seniorCareApiScopes';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import { FlexBox } from 'components/Helpers';
import useAuthContext from 'hooks/auth/useAuthContext';
import useLoadAllPaginatedCursorQuery from 'hooks/useLoadAllPaginatedCursorQuery';
import useSeniorCareManagementUsers from 'hooks/useSeniorCareUsers';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { SeniorCareManagementUser } from 'interfaces/seniorcare/seniorCareUser';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { useManagementPathOrganization } from 'router';
import { isUserAdvancedAtom } from 'state/state';
import { appIsCoachKey, appRoleKey } from 'userMetadataKeys';
import SeniorCareAppUserEditModal from './SeniorCareAppUserEditModal';

const SeniorCareAppUserTable: React.FC = () => {
  const { getUsers, deleteUser, updateUser } = useSeniorCareManagementUsers();
  const { scope } = useAuthContext();
  const [userToEdit, setUserToEdit] = useState<null | SeniorCareManagementUser>(
    null,
  );
  const organization = useManagementPathOrganization();

  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);

  const {
    data: users,
    isLoading,
    error,
  } = useLoadAllPaginatedCursorQuery({
    queryKey: ['management-senior-care-users', organization],
    queryFn: getUsers,
  });

  const getColumnSearchProps = useTableSearchFilter<SeniorCareManagementUser>();

  const columns = [
    {
      title: 'Vorname',
      key: 'firstname',
      sorter: (a: SeniorCareManagementUser, b: SeniorCareManagementUser) =>
        a.firstname.localeCompare(b.firstname),
      ...getColumnSearchProps(
        'Vorname',
        'firstname',
        (element) => element.firstname,
      ),
      onlyAdvanced: false,
    },
    {
      title: 'Nachname',
      key: 'lastname',
      sorter: (a: SeniorCareManagementUser, b: SeniorCareManagementUser) =>
        a.lastname.localeCompare(b.lastname),
      ...getColumnSearchProps(
        'Nachname',
        'lastname',
        (element) => element.lastname,
      ),
      onlyAdvanced: false,
    },
    {
      title: 'Rolle',
      key: 'role',
      sorter: (a: SeniorCareManagementUser, b: SeniorCareManagementUser) =>
        a.lastname.localeCompare(b.lastname),
      ...getColumnSearchProps(
        'Rolle',
        'role',
        (element) => element.metadata[appRoleKey] ?? 'Keine',
      ),
      onlyAdvanced: true,
    },
    {
      title: 'Coach',
      key: 'is-coach',
      sorter: (a: SeniorCareManagementUser, b: SeniorCareManagementUser) =>
        (a.metadata[appIsCoachKey] === 'true') >
        (b.metadata[appIsCoachKey] === 'true')
          ? 1
          : -1,
      render: (record: SeniorCareManagementUser) =>
        record.metadata[appIsCoachKey] === 'true' ? (
          <CheckCircleOutlined />
        ) : (
          ''
        ),
      onlyAdvanced: false,
    },
    {
      title: 'Letzte Anmeldung',
      key: 'last-seen-at',
      sorter: (a: SeniorCareManagementUser, b: SeniorCareManagementUser) => {
        const lastSeen = (x: SeniorCareManagementUser) =>
          x.lastSeenAt !== null ? new Date(x.lastSeenAt).getTime() : 0;
        return lastSeen(b) - lastSeen(a);
      },
      render: (record: SeniorCareManagementUser) => (
        <RelativeDateTimeWithTooltip
          timestamp={record.lastSeenAt}
          noneText="Noch keine Anmeldung"
        />
      ),
      onlyAdvanced: false,
    },
    {
      title: 'Schnittstellen-Zugangsdaten-Typ',
      key: 'credentials.type',
      sorter: (a: SeniorCareManagementUser, b: SeniorCareManagementUser) => {
        const type = (x: SeniorCareManagementUser) =>
          x.integrationCredentials.type;
        return type(b).localeCompare(type(a));
      },
      ...getColumnSearchProps(
        'Schnittstellen-Zugangsdaten-Typ',
        'credentials.type',
        (element) => element.integrationCredentials.type,
      ),
      onlyAdvanced: true,
    },
    {
      title: 'Schnittstellen-Nutzer-ID',
      key: 'credentials.userId',
      sorter: (a: SeniorCareManagementUser, b: SeniorCareManagementUser) => {
        const userId = (x: SeniorCareManagementUser) =>
          x.integrationCredentials.userId;
        return userId(b).localeCompare(userId(a));
      },
      ...getColumnSearchProps(
        'Schnittstellen-Nutzer-ID',
        'credentials.userId',
        (element) => element.integrationCredentials.userId,
      ),
      onlyAdvanced: false,
    },
    {
      title: 'Aktionen',
      key: 'actions',
      render: (user: SeniorCareManagementUser) => (
        <FlexBox
          withgap
          gap={10}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {scope.includes(writeSeniorCareOrganizationsUsers) && (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setUserToEdit(user);
              }}
            />
          )}

          {scope.includes(deleteSeniorCareOrganizationsUsers) && (
            <Popconfirm
              title="Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?"
              onConfirm={async () => {
                await deleteUser(user.id);
                message.success('Benutzer wurde gelöscht.');
              }}
              okText="Yes"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </FlexBox>
      ),
      onlyAdvanced: false,
    },
  ].filter((column) => isUserAdvanced || !column.onlyAdvanced);

  return (
    <>
      {userToEdit !== null && (
        <SeniorCareAppUserEditModal
          user={userToEdit}
          onSubmit={(data) => updateUser(userToEdit.id, data)}
          onEdited={() => setUserToEdit(null)}
          onClose={() => setUserToEdit(null)}
        />
      )}
      {error && <Alert type="error" message={error.message} />}
      {users && <span>{users.length} Benutzer</span>}
      <Table
        columns={columns}
        rowKey={(element: SeniorCareManagementUser) => element.id}
        dataSource={users}
        loading={isLoading}
        pagination={{
          defaultPageSize: 100,
          position: ['bottomRight', 'topRight'],
          pageSizeOptions: ['20', '50', '100', '500', '1000'],
        }}
      />
    </>
  );
};

export default SeniorCareAppUserTable;

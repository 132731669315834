import { createBooleanFeature } from 'components/device-configuration/features/base/BooleanFeature';
import { createSingleSelectFeature } from 'components/device-configuration/features/base/SingleSelectFeature';
import InspectionCatalogMetadataFeature from 'components/device-configuration/features/inspection-catalog-metadata/InspectionCatalogMetadataFeature';
import IntegrationCacheServiceConfigFeature from 'components/device-configuration/features/integration-cache-service-config/IntegrationCacheServiceConfigFeature';
import SeniorCareFeaturesBlacklistFeature from 'components/device-configuration/features/senior-care-integration-config/SeniorCareFeaturesBlacklistFeature';
import ShiftsOverwriteFeature, {
  shiftsOverwriteDeviceProperty,
} from 'components/device-configuration/features/shifts-overwrite/ShiftsOverwriteFeature';
import RecordingAnonymizationFeature from '../components/device-configuration/features/RecordingAnonymizationFeature';
import SeniorCareIntegrationAreasFeature from '../components/device-configuration/features/senior-care-integration-config/SeniorCareIntegrationAreasFeature';
import SeniorCareIntegrationConfigFeature from '../components/device-configuration/features/senior-care-integration-config/SeniorCareIntegrationConfigFeature';
import TICIntegrationConfigFeature from '../components/device-configuration/features/tic-integration-config/TICIntegrationConfigFeature';
import UserManagerOIDCConfigFeature from '../components/device-configuration/features/user-auth-manager-oidc/UserManagerOIDCConfigFeature';
import SeniorCareResidentsRoomPrefixFeature from '../components/device-configuration/senior-care-residents-room-prefix/SeniorCareResidentsRoomPrefixFeature';

export interface FeatureRenderProps {
  /**
   * @deprecated only used because to fix properties that should be in the same feature but are not due to backwards compatibility
   */
  allDeviceProperties: Record<string, string>;
  values: Record<string, string>;
  onUpdate: (values: Record<string, string>) => void;
  isEditing: boolean;
  onEditDone?: () => void;
}

export interface Feature {
  id: string;
  name: string;
  description: string;
  properties: string[];
  initialValues: Record<string, string>;
  isEditable?: boolean;
  isMultiline?: boolean;
  Component: React.ComponentType<FeatureRenderProps>;
}

export const seniorCareIntegrationConfigsFeature: Feature = {
  id: 'INTEGRATION_CONFIG_V2',
  name: 'Senior Care Schnittstelle',
  description: 'Konfiguriert die von der App verwendete Schnittstelle.',
  properties: [
    'INTEGRATION_CONFIG_V2',
    'STANDARDIZED_API_AUTHENTICATION_CONFIG',
    'STANDARDIZED_API_USER_AUTHENTICATION_OIDC_CONFIG',
    'USE_VIVENDI_WINDOWS_LOGIN',
    'DISABLE_SYNC_USER_VIVENDI_WINDOWS_LOGIN',
  ],
  initialValues: {
    INTEGRATION_CONFIG_V2:
      '{"type":"", "baseUrl":"", "credentialsId":"", "credentialsSecret":""}',
  },
  isEditable: true,
  isMultiline: true,
  Component: SeniorCareIntegrationConfigFeature,
};

export const userAuthenticationManagerOIDCConfigFeature: Feature = {
  id: 'USER_AUTHENTICATION_MANAGER_OIDC_CONFIG',
  name: 'User Authentication Manager OIDC Config',
  description: 'Konfiguriert die Benutzer Authentifizierung via OIDC.',
  properties: ['USER_AUTHENTICATION_MANAGER_OIDC_CONFIG'],
  initialValues: {
    USER_AUTHENTICATION_MANAGER_OIDC_CONFIG: '{}',
  },
  isEditable: true,
  isMultiline: true,
  Component: UserManagerOIDCConfigFeature,
};

export const features: Feature[] = [
  {
    id: 'HANDSFREE_DOCUMENTATION',
    name: 'Freihand Dokumentieren',
    description:
      'Durch anschalten dieser Funktion können Nutzer das freihändige Dokumentieren verwenden.',
    properties: ['IS_HANDSFREE_ENABLED'],
    initialValues: { IS_HANDSFREE_ENABLED: 'false' },
    Component: createBooleanFeature({
      on: { IS_HANDSFREE_ENABLED: 'true' },
      off: { IS_HANDSFREE_ENABLED: 'false' },
    }),
  },
  {
    id: 'RECORDING_ANONYMIZATION',
    name: 'Audio-Aufnahmen Anonymisierung',
    description:
      'Diese Funktion bestimmt wie Audio-Aufnahmen mit Namendaten aus Spracheingaben verarbeited werden sollen. Diese Audio-Aufnahmen können entweder komplett gelöscht, ausgeschnitten oder maskiert werden. Es gibt auch die Möglichkeit diese unverändert zu lassen, dies darf aber nur benutzt werden wenn die Aufnahmen keine echten Namen enthalten. Nur wenn die Audio-Aufnahmen nicht gelöscht werden, können diese zur Verbesserung der KI genutzt werden.',
    properties: [
      'TRANSCRIPTION_PRIVACY_CHECKER_ENABLED',
      'SENIOR_CARE_RECORDING_NAME_SPLITTING_ENABLED',
      'SENIOR_CARE_RECORDING_NAME_MUTING_ENABLED',
    ],
    initialValues: {
      TRANSCRIPTION_PRIVACY_CHECKER_ENABLED: 'false',
      SENIOR_CARE_RECORDING_NAME_SPLITTING_ENABLED: 'true',
      SENIOR_CARE_RECORDING_NAME_MUTING_ENABLED: 'false',
    },
    isMultiline: true,
    isEditable: true,
    Component: RecordingAnonymizationFeature,
  },
  /*
  {
    id: 'CLAP',
    name: 'Clap',
    description:
      'Durch diese Funktion wird die verbesserte Namenserkennung Clap aktiviert (falls noch nicht standardmäßig aktiv).',
    properties: ['STREAMING_ASR_ENGINE'],
    Component: createBooleanFeature({
      on: { STREAMING_ASR_ENGINE: 'CLAP' },
      off: {},
    }),
  },
  */
  {
    id: 'LLM_GRAMMAR_CORRECTION',
    name: 'Grammatik Korrektur via LLM (experimentell)',
    description:
      'Durch diese Funktion wird die Grammatik Korrektur via LLM aktiviert (experimentell).',
    properties: ['LLM_GRAMMAR_CORRECTION_ENABLED'],
    initialValues: {
      LLM_GRAMMAR_CORRECTION_ENABLED: 'true',
    },
    Component: createBooleanFeature({
      on: {
        LLM_GRAMMAR_CORRECTION_ENABLED: 'true',
      },
      off: {
        LLM_GRAMMAR_CORRECTION_ENABLED: 'false',
      },
    }),
  },
  {
    id: 'LLM_GROUP_OFFERINGS_ENABLED',
    name: 'Verbesserte Gruppenangebote mit LLMs (experimentell)',
    description:
      'Durch diese Funktion wird das Model filling von Gruppenangeboten mit LLMs aktiviert (experimentell).',
    properties: ['LLM_GROUP_OFFERINGS_ENABLED'],
    initialValues: {
      LLM_GROUP_OFFERINGS_ENABLED: 'true',
    },
    Component: createBooleanFeature({
      on: {
        LLM_GROUP_OFFERINGS_ENABLED: 'true',
      },
      off: {
        LLM_GROUP_OFFERINGS_ENABLED: 'false',
      },
    }),
  },
  {
    id: 'LLM_SIS_CONVERSATION_ENABLED',
    name: 'SIS Aufnahmegespräch mit LLMs (experimentell)',
    description:
      'Durch diese Funktion können in der voize-app längere Gespräche analysiert und in die SIS übertragen werden (experimentell).',
    properties: ['LLM_SIS_CONVERSATION_ENABLED'],
    initialValues: {
      LLM_SIS_CONVERSATION_ENABLED: 'true',
    },
    Component: createBooleanFeature({
      on: {
        LLM_SIS_CONVERSATION_ENABLED: 'true',
      },
      off: {
        LLM_SIS_CONVERSATION_ENABLED: 'false',
      },
    }),
  },
  {
    id: 'INTEGRATION_AREAS',
    name: 'Bereiche',
    description: 'Konfiguriert welche Bereiche das Gerät sehen soll.',
    properties: ['INTEGRATION_AREAS'],
    initialValues: {
      INTEGRATION_AREAS: '[]',
    },
    isEditable: true,
    isMultiline: true,
    Component: SeniorCareIntegrationAreasFeature,
  },
  {
    id: 'RESIDENT_ROOM_PREFIXES',
    name: 'Bewohnergruppierung nach Zimmername',
    description:
      'Mit dieser Option können Bewohner:innen in der voize App anhand von den Zimmernamen mittels REGEX gruppiert werden. Die Konfiguration bitte mit Ihrem voize-Ansprechpartner abstimmen.',
    properties: [
      'IS_GROUP_RESIDENTS_BY_ROOM_PREFIX_ACTIVE',
      'ROOM_PREFIXES_TO_GROUP_RESIDENTS',
    ],
    initialValues: {
      IS_GROUP_RESIDENTS_BY_ROOM_PREFIX_ACTIVE: 'true',
      ROOM_PREFIXES_TO_GROUP_RESIDENTS: '[]',
    },
    isEditable: true,
    isMultiline: true,
    Component: SeniorCareResidentsRoomPrefixFeature,
  },
  seniorCareIntegrationConfigsFeature,
  {
    id: 'FEATURES_BLACKLIST',
    name: 'Pflege App Funktionen',
    description: 'Erlaubt einzelne Funktionen der Pflege App auszuschalten.',
    properties: ['FEATURES_BLACKLIST'],
    initialValues: {
      FEATURES_BLACKLIST: '[]',
    },
    isEditable: true,
    isMultiline: true,
    Component: SeniorCareFeaturesBlacklistFeature,
  },
  {
    id: 'TIC_INTEGRATION_CONFIG',
    name: 'TIC Schnittstelle',
    description: 'Konfiguriert die von der App verwendete Schnittstelle.',
    properties: [
      'TIC_APP_INTEGRATION_ADAPTER_TYPE',
      'TIC_APP_INTEGRATION_ADAPTER_TUEV_NORD_CONFIGURATION',
    ],
    initialValues: {
      TIC_APP_INTEGRATION_ADAPTER_TYPE: 'NONE',
      TIC_APP_INTEGRATION_ADAPTER_TUEV_NORD_CONFIGURATION: '{"baseUrl":""}',
    },
    isEditable: true,
    isMultiline: true,
    Component: TICIntegrationConfigFeature,
  },
  userAuthenticationManagerOIDCConfigFeature,
  {
    id: 'INTEGRATION_CACHE_SERVICE_CONFIG',
    name: 'Integration Cache Service Config',
    description: 'Konfiguriert den Integration Cache Service.',
    properties: ['INTEGRATION_CACHE_SERVICE_CONFIG'],
    initialValues: {
      INTEGRATION_CACHE_SERVICE_CONFIG: '{}',
    },
    isEditable: true,
    isMultiline: true,
    Component: IntegrationCacheServiceConfigFeature,
  },
  {
    id: 'SHIFTS_OVERWRITE',
    name: 'Schichtzeiten überschreiben',
    description:
      'Durch diese Funktion können die Schichtzeiten überschrieben werden.',
    properties: [shiftsOverwriteDeviceProperty],
    initialValues: {
      [shiftsOverwriteDeviceProperty]: '{}',
    },
    isEditable: true,
    isMultiline: true,
    Component: ShiftsOverwriteFeature,
  },
  {
    id: 'REFRESH_INTERVAL_CONFIG_TEMPLATE',
    name: 'Aktualisierungsintervall',
    description: 'Konfiguriert das Aktualisierungsintervall.',
    properties: ['REFRESH_INTERVAL_CONFIG_TEMPLATE'],
    initialValues: {
      REFRESH_INTERVAL_CONFIG_TEMPLATE: 'FAST',
    },
    isEditable: false,
    isMultiline: true,
    Component: createSingleSelectFeature('REFRESH_INTERVAL_CONFIG_TEMPLATE', [
      {
        value: 'FAST',
        label: 'Schnell',
      },
      {
        value: 'MEDIUM',
        label: 'Mittel',
      },
      {
        value: 'SLOW',
        label: 'Langsam',
      },
    ]),
  },
  {
    id: 'INSPECTION_CATALOG_METADATA',
    name: 'Mangelkatalog',
    description: 'Erlaubt die Konfiguration des Mangelkatalogs.',
    properties: ['INSPECTION_CATALOG_METADATA'],
    initialValues: {
      INSPECTION_CATALOG_METADATA: '',
    },
    isEditable: true,
    isMultiline: true,
    Component: InspectionCatalogMetadataFeature,
  },
  {
    id: 'ARE_TOURS_ENABLED',
    name: 'Stationäre Tourenplanung',
    description:
      'Erlaubt die Verwendung der stationären Tourenplanung. Nur verfügbar für Vivendi >=24.3',
    properties: ['ARE_TOURS_ENABLED'],
    initialValues: { ARE_TOURS_ENABLED: 'false' },
    Component: createBooleanFeature({
      on: { ARE_TOURS_ENABLED: 'true' },
      off: { ARE_TOURS_ENABLED: 'false' },
    }),
  },
];

export const isFeaturePresent = (
  feature: Feature,
  properties: Record<string, string>,
) => {
  return feature.properties.some((key) => {
    return properties[key] !== undefined;
  });
};

export const hasPropertyFeature = (key: string): boolean => {
  return features.some((feature) => feature.properties.includes(key));
};

export const getPresentFeatures = (
  properties: Record<string, string>,
): Feature[] => {
  return features.filter((feature) => isFeaturePresent(feature, properties));
};

import { Modal } from 'antd';
import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

interface Props {
  onClose?: () => void;
  deviceId: string;
  activationCode: string | null;
  isLoading: boolean;
  error: any | null;
}

const DeviceActivationCodeModal: React.FC<Props> = ({
  onClose,
  deviceId,
  activationCode,
  isLoading,
  error,
}) => {
  const errorMessage = useMemo(() => {
    if (error instanceof AxiosError) {
      switch (error.status) {
        case 401:
          return 'Bitte melden sie sich an, um diese Aktion auszuführen.';
        case 403:
          return 'Sie haben keine Berechtigung um Aktivierungscodes zu sehen. Wenn sie denken, dass das ein Fehler ist, kontaktieren sie den Support.';
        case 404:
          return 'Gerät nicht gefunden';

        default:
          break;
      }
    }

    if (error?.response?.data?.message) {
      return error.response.data.message;
    }
    return null;
  }, [error]);

  return (
    <Modal open={true} onCancel={onClose} footer="" width={700}>
      {errorMessage ? (
        <div>{errorMessage}</div>
      ) : activationCode ? (
        <Container>
          <QRCode
            value={JSON.stringify({
              deviceId,
              activationCode,
            })}
          />
          <div>
            <Label>Geräte-ID</Label>
            <span>{deviceId}</span>
            <Label>Aktivierungscode</Label>
            <span>{activationCode}</span>
          </div>
        </Container>
      ) : isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          Aktivierungscode wurde bereits verwendet, Device Secrets müssen
          zurückgesetzt werden um einen neuen Aktivierungscode für das Gerät zu
          generieren.
        </div>
      )}
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-weight: bold;
`;

export default DeviceActivationCodeModal;

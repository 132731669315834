import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { message, Skeleton } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import Breadcrumb from 'components/common/Breadcrumb';
import ErrorAlert from 'components/common/ErrorAlert';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import GlobalDeviceConfigurationForm from 'components/device-configuration/GlobalDeviceConfigurationForm';
import { PostGlobalDeviceConfiguration } from 'interfaces/adminGlobalDeviceConfig';
import { adminGlobalDeviceConfigurationRoute } from 'router';

const AdminGlobalDeviceConfigurationCreateView: React.FC = () => {
  const queryclient = useQueryClient();
  const navigate = useNavigate();

  const { globalDeviceConfigurationId } =
    adminGlobalDeviceConfigurationRoute.useParams();

  const { putGlobalDeviceConfiguration, getGlobalDeviceConfiguration } =
    useAdminDeviceApi();

  const { data: globalDeviceConfig, isLoading } = useQuery({
    queryKey: [
      'admin-global-device-configurations',
      globalDeviceConfigurationId,
    ],
    queryFn: () => getGlobalDeviceConfiguration(globalDeviceConfigurationId),
  });

  const {
    mutate: updateGlobalDeviceConfiguration,
    isPending: isUpdating,
    error,
  } = useMutation({
    mutationFn: (
      postGlobalDeviceConfiguration: PostGlobalDeviceConfiguration,
    ) =>
      putGlobalDeviceConfiguration(
        globalDeviceConfigurationId,
        postGlobalDeviceConfiguration,
      ),
    onSuccess: () => {
      queryclient.invalidateQueries({
        queryKey: ['admin-global-device-configurations'],
      });
      message.success('Global Device Configuration wurde bearbeitet.');
      navigate({
        to: '/admin/global-device-configurations',
      });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Globale Gerätekonfigurationen',
            href: '/admin/global-device-configurations',
          },
        ]}
      />
      <PageTitleBar
        title={globalDeviceConfig?.name ?? '...'}
        Icon={ResourceIcons.GlobalDeviceConfiguration}
      />
      <PageContentContainer>
        {isLoading ? (
          <Skeleton active />
        ) : !globalDeviceConfig ? (
          <ErrorAlert
            errorCode="404"
            subtitle="Global Device Configuration konnte nicht gefunden werden."
          />
        ) : (
          <GlobalDeviceConfigurationForm
            onSubmit={updateGlobalDeviceConfiguration}
            isLoading={isUpdating}
            error={error}
            initialValues={globalDeviceConfig}
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default AdminGlobalDeviceConfigurationCreateView;

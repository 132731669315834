import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import Editable from 'components/common/Editable';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { ManagementDevice } from 'interfaces/device';
import { deviceDisplayNameLabel } from 'labels';

interface Props {
  device: ManagementDevice;
  onEdited?: () => void;
}
const DeviceNameEditable: React.FC<Props> = ({ device, onEdited }) => {
  const queryClient = useQueryClient();
  const { patchDeviceLabels } = useManagementApiFromContext();

  const { mutate: updateDevice, isPending } = useMutation({
    mutationFn: async (displayName: string) => {
      if (displayName === '') {
        await patchDeviceLabels(device.id, {
          [deviceDisplayNameLabel]: null,
        });
      } else {
        await patchDeviceLabels(device.id, {
          [deviceDisplayNameLabel]: displayName,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-devices'] });
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      onEdited?.();
      message.success('Gerätename wurde erfolgreich aktualisiert');
    },
  });

  const handleUpdateDevice = (displayName: string) => {
    if (displayName === device.labels[deviceDisplayNameLabel]) return;
    updateDevice(displayName);
  };

  return (
    <>
      <Editable
        initialValue={device.labels[deviceDisplayNameLabel]}
        onBlur={handleUpdateDevice}
        placeholder="Gerätename"
      />
      {isPending && <LoadingOutlined style={{ marginLeft: 5 }} />}
    </>
  );
};

export default DeviceNameEditable;

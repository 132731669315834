import Joi from 'joi';

export enum IntegrationAdapterType {
  NONE = 'NONE',
  MOCK = 'MOCK',
  TUEV_NORD = 'TUEV_NORD',
  TUEV_SUED = 'TUEV_SUED',
}

export const integrationTypeName: Record<IntegrationAdapterType, string> = {
  [IntegrationAdapterType.NONE]: 'Keine',
  [IntegrationAdapterType.MOCK]: 'Mock',
  [IntegrationAdapterType.TUEV_NORD]: 'TÜV Nord',
  [IntegrationAdapterType.TUEV_SUED]: 'TÜV Süd',
};

export interface TICIntegrationConfig {
  type: IntegrationAdapterType;
  baseUrl: string;
}

export const ticIntegrationConfigSchema = Joi.object<TICIntegrationConfig>({
  type: Joi.string()
    .valid(...Object.values(IntegrationAdapterType))
    .required(),
  baseUrl: Joi.string().uri(),
});

import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Popconfirm } from 'antd';
import ActivationRequestEmail from 'components/common/ActivationRequestEmail';
import IdTag from 'components/common/IdTag';
import InfoTagVertical from 'components/common/InfoTagVertical';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import Metadata from 'components/common/Metadata';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import StandardHighlighter from 'components/common/StandardHighlighter';
import { FlexBox } from 'components/Helpers';
import LabelList from 'components/views/LabelList';
import { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { deviceRoleLabel } from 'labels';
import { styled } from 'styled-components';

interface Props {
  activationRequest:
    | DeviceActivationRequest
    | ManagementDeviceActivationRequest;
  isIdCollapsed?: boolean;
  searchText?: string;
  toggleSelect?: (id: string) => void;
  isChecked?: boolean;
  setIsActivationModalOpen: (value: boolean) => void;
  remove: () => Promise<void>;
  organizationInfo?: React.ReactNode;
}
const DevicesActivationRequestInfo: React.FC<Props> = ({
  activationRequest,
  isIdCollapsed = true,
  searchText,
  toggleSelect,
  isChecked,
  setIsActivationModalOpen,
  remove,
  organizationInfo,
}) => {
  return (
    <Container>
      {toggleSelect !== undefined && (
        <FlexBox
          withgap
          justifycontent="center"
          alignitems="center"
          style={{ gridArea: 'checkbox' }}
        >
          <Checkbox
            onClick={() => toggleSelect(activationRequest.id)}
            checked={isChecked}
          />
        </FlexBox>
      )}
      <FlexBox
        withgap
        direction="column"
        justifycontent="space-between"
        style={{ gridArea: 'prio-score' }}
      >
        <FlexBox withgap gap={4}>
          {activationRequest.approvedAt && (
            <Popconfirm
              title="Anfrage löschen"
              description="Wenn sie diese Anfrage ablehnen, wird sie direkt gelöscht und kann nicht wiederhergestellt werden. Fortfahren?"
              okText="Ja"
              cancelText="Nein"
              onConfirm={async () => await remove()}
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
          <IdTag
            id={activationRequest.id}
            isCollapsed={isIdCollapsed}
            highlight={searchText}
          />
        </FlexBox>
        <FlexBox
          withgap
          gap={6}
          alignitems="center"
          style={{
            border: '1px solid hsl(200, 30%, 92%)',
            padding: '4px 8px',
            borderRadius: '4px',
          }}
        >
          {activationRequest.approvedAt ? (
            <>
              <CheckSquareOutlined
                style={{
                  fontSize: '20px',
                  color: 'hsl(120, 50%, 50%)',
                }}
              />
              Angenommen
              {activationRequest.deviceId && (
                <InfoTagVertical label="Geräte-ID">
                  <IdTag id={activationRequest.deviceId} />
                </InfoTagVertical>
              )}
            </>
          ) : (
            <>
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                onClick={() => setIsActivationModalOpen(true)}
              >
                Annehmen
              </Button>
              <Popconfirm
                title="Anfrage löschen"
                description="Wenn sie diese Anfrage ablehnen, wird sie direkt gelöscht und kann nicht wiederhergestellt werden. Fortfahren?"
                okText="Ja"
                cancelText="Nein"
                onConfirm={async () => await remove()}
              >
                <Button type="primary" danger icon={<StopOutlined />}>
                  Löschen
                </Button>
              </Popconfirm>
            </>
          )}
        </FlexBox>
      </FlexBox>

      <IconLabelGrid style={{ gridArea: 'forms' }}>
        <ListItemInfoTag label="Username">
          {activationRequest.userName}
        </ListItemInfoTag>
        <ListItemInfoTag label="E-Mail Adresse">
          <ActivationRequestEmail activationRequest={activationRequest} />
        </ListItemInfoTag>

        {organizationInfo}
        <ListItemInfoTag label="Rolle">
          <StandardHighlighter
            searchWords={[searchText ?? '']}
            text={activationRequest.metadata[deviceRoleLabel] ?? ''}
          />
        </ListItemInfoTag>
        <ListItemInfoTag label="Erstellt">
          <RelativeDateTimeWithTooltip
            timestamp={activationRequest.createdAt}
          />
        </ListItemInfoTag>
        <ListItemInfoTag
          label="E-Mail Adresse bestätigt"
          valueStyle={{
            backgroundColor: activationRequest.userEmailVerifiedAt
              ? 'hsla(90, 100%, 72%, 1)'
              : 'hsla(0, 100%, 72%, 1)',
          }}
        >
          <RelativeDateTimeWithTooltip
            timestamp={activationRequest.userEmailVerifiedAt}
            noneText="Noch nicht bestätigt"
          />
        </ListItemInfoTag>
      </IconLabelGrid>

      <FlexBox style={{ gridArea: 'icon-labels' }}>
        <LabelList
          labels={activationRequest.labels}
          highlightTexts={searchText ? [searchText] : []}
        />
      </FlexBox>

      <FlexBox style={{ gridArea: 'metadata' }}>
        <Metadata metadata={activationRequest.metadata} />
      </FlexBox>
    </Container>
  );
};

export default DevicesActivationRequestInfo;

const Container = styled.div`
  @media (min-width: 700px) {
    grid-template-columns: 10px auto 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'checkbox prio-score prio-score'
      'checkbox forms forms'
      'checkbox icon-labels icon-labels';
    align-items: center;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 10px 250px 1000px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'checkbox prio-score forms icon-labels'
      'checkbox metadata metadata metadata';
  }

  display: grid;
  grid-template-columns: 10px auto;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'checkbox prio-score'
    'checkbox forms'
    'checkbox icon-labels';

  align-items: flex-start;
  gap: 25px;

  padding: 18px 20px;
  border-bottom: 1px solid hsl(200, 30%, 92%);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: hsl(200, 10%, 98%);
  }
`;

const IconLabelGrid = styled.div`
  @media (min-width: 700px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    display: grid;
    grid-auto-flow: column;
  }

  @media (min-width: 1400px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }

  display: flex;
  flex-wrap: wrap;

  align-items: center;

  column-gap: 50px;
  row-gap: 5px;
`;

import Highlighter from 'react-highlight-words';

interface Props {
  text: string;
  searchWords: string[];
}
const StandardHighlighter: React.FC<Props> = ({ text, searchWords }) => {
  return (
    <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={searchWords}
      autoEscape
      textToHighlight={text}
    />
  );
};

export default StandardHighlighter;

import { Input } from 'antd';
import { DeviceProperty, DevicePropertyType } from 'defaults/deviceProperties';
import { useMemo } from 'react';
import recursivelyMaskPasswordValues from 'utlis/maskSecrets';

interface Props {
  value: string | undefined;
  deviceProperty: DeviceProperty;
}
const DevicePropertyInputForFeature: React.FC<Props> = ({
  value,
  deviceProperty,
}) => {
  const maskedValue = useMemo(() => {
    if (value === undefined) {
      return '';
    }

    if (deviceProperty.type !== DevicePropertyType.JSON) {
      return value;
    }
    if (value.length > 1000) {
      return value;
    }

    try {
      return JSON.stringify(
        recursivelyMaskPasswordValues(JSON.parse(value)),
        null,
        0,
      );
    } catch (e) {
      return value;
    }
  }, [value, deviceProperty]);

  return <Input style={{ flex: 1 }} readOnly disabled value={maskedValue} />;
};

export default DevicePropertyInputForFeature;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useManagementDeviceConfigurationsProviderFromContext } from 'components/scaffold/contexts';
import { useNullablePathOrganization } from 'router';

const useManagementDeviceConfigurations = () => {
  const getManagementDeviceConfigurations =
    useManagementDeviceConfigurationsProviderFromContext();

  const organization = useNullablePathOrganization();

  const query = useQuery({
    queryKey: ['management-organization-device-configurations', organization],
    queryFn: getManagementDeviceConfigurations,
  });

  return query;
};

export const useManagementDeviceConfigurationsInvalidation = () => {
  const queryClient = useQueryClient();
  const organization = useNullablePathOrganization();

  return () => {
    queryClient.invalidateQueries({
      queryKey: ['management-organization-device-configurations', organization],
    });
  };
};

export default useManagementDeviceConfigurations;

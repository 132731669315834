import { Alert } from 'antd';
import LabelRow from 'components/common/LabelRow';
import { FlexBox } from 'components/Helpers';
import {
  integrationConfigDeviceProperty,
  IntegrationType,
  SeniorCareIntegrationConfigSchema,
} from 'components/seniorcare/SeniorCareIntegrationConfig';
import { useMemo } from 'react';
import { validateJsonPropertyAndMaskSecrets } from 'utlis/validationUtil';
import {
  StandardizedApiAuthenticationConfigSchema,
  StandardizedUserAuthenticationOIDCConfigSchema,
} from './SeniorCareIntegrationAreasFeature';

interface Props {
  properties: Record<string, string>;
}

const SeniorCareIntegrationConfigSummary: React.FC<Props> = ({
  properties,
}) => {
  const [integrationConfig, validationError] = useMemo(
    () =>
      validateJsonPropertyAndMaskSecrets(
        properties[integrationConfigDeviceProperty],
        SeniorCareIntegrationConfigSchema,
      ),
    [properties],
  );

  const [standardizedApiAuthenticationConfig] = useMemo(() => {
    if (integrationConfig?.type !== IntegrationType.STANDARDIZED) {
      return [null, null];
    }

    return validateJsonPropertyAndMaskSecrets(
      properties.STANDARDIZED_API_AUTHENTICATION_CONFIG,
      StandardizedApiAuthenticationConfigSchema,
    );
  }, [properties, integrationConfig]);

  const [standardizedOIDCConfig] = useMemo(
    () =>
      integrationConfig?.type === IntegrationType.STANDARDIZED
        ? validateJsonPropertyAndMaskSecrets(
            properties.STANDARDIZED_API_USER_AUTHENTICATION_OIDC_CONFIG,
            StandardizedUserAuthenticationOIDCConfigSchema,
          )
        : [null, null],
    [properties, integrationConfig],
  );

  return (
    <FlexBox direction="column">
      {validationError !== null && (
        <Alert
          message="Fehler"
          description={validationError}
          type="error"
          showIcon
        />
      )}
      {integrationConfig !== null && (
        <FlexBox direction="column" withgap>
          <FlexBox direction="column">
            <LabelRow label="Integrations-Typ" value={integrationConfig.type} />
            <LabelRow
              label="Integrations-URL"
              value={integrationConfig.baseUrl}
            />
          </FlexBox>
          {(() => {
            switch (integrationConfig.type) {
              case IntegrationType.STANDARDIZED:
                return (
                  <>
                    <FlexBox direction="column">
                      <span style={{ marginBottom: 5 }}>API Auth</span>
                      <LabelRow
                        label="Client ID"
                        value={integrationConfig.credentialsId}
                      />
                      <LabelRow
                        label="Client Secret"
                        value={integrationConfig.credentialsSecret}
                      />
                      {standardizedApiAuthenticationConfig !== null ? (
                        <>
                          <LabelRow
                            label="Token URL"
                            value={standardizedApiAuthenticationConfig.tokenUrl}
                          />
                          <LabelRow
                            label="Client Auth Method"
                            value={
                              standardizedApiAuthenticationConfig.clientAuthMethod
                            }
                          />
                        </>
                      ) : (
                        <Alert
                          message="Fehler"
                          description="Fehler beim Laden der Authentifizierungskonfiguration"
                          type="error"
                          showIcon
                        />
                      )}
                    </FlexBox>
                    <FlexBox direction="column">
                      <span style={{ marginBottom: 5 }}>OIDC Auth</span>
                      {standardizedOIDCConfig !== null ? (
                        <>
                          <LabelRow
                            label="Client ID"
                            value={standardizedOIDCConfig.clientId}
                          />
                          <LabelRow
                            label="Client Secret"
                            value={standardizedOIDCConfig.clientSecret ?? ''}
                          />
                          <LabelRow
                            label="Client Auth Method"
                            value={
                              standardizedOIDCConfig.clientAuthMethod ?? '-'
                            }
                          />
                          <LabelRow
                            label="Discovery URI"
                            value={standardizedOIDCConfig.discoveryUri}
                          />
                          <LabelRow
                            label="Scopes"
                            value={standardizedOIDCConfig.scopes.join(', ')}
                          />
                          <LabelRow
                            label="Refresh Token Rotation"
                            value={
                              standardizedOIDCConfig.isRefreshTokenRotationEnabled ===
                              true
                                ? 'Ja'
                                : 'Nein' // default
                            }
                          />
                          <LabelRow
                            label="End Session on Logout"
                            value={
                              standardizedOIDCConfig.endSessionOnLogout === true
                                ? 'Ja'
                                : 'Nein' // default
                            }
                          />
                          <LabelRow
                            label="Skip E-Mail Verified Check"
                            value={
                              standardizedOIDCConfig.skipEmailVerifiedCheck ===
                              true
                                ? 'Ja'
                                : 'Nein' // default
                            }
                          />
                          <LabelRow
                            label="Use Refresh Tokens"
                            value={
                              standardizedOIDCConfig.useRefreshTokens === false
                                ? 'Nein'
                                : 'Ja' // default
                            }
                          />
                          <LabelRow
                            label="Force Re-Authenticate"
                            value={
                              standardizedOIDCConfig.forceReAuthenticate ===
                              true
                                ? 'Ja'
                                : 'Nein' // default
                            }
                          />
                          <LabelRow
                            label="Response Mode"
                            value={standardizedOIDCConfig.responseMode ?? '-'}
                          />
                          <LabelRow
                            label="Additional Authorization Parameters"
                            value={
                              standardizedOIDCConfig.additionalAuthorizationParameters
                                ? JSON.stringify(
                                    standardizedOIDCConfig.additionalAuthorizationParameters,
                                  )
                                : '-'
                            }
                          />
                        </>
                      ) : (
                        <Alert
                          message="Fehler"
                          description="Fehler beim Laden der OIDC-Konfiguration"
                          type="error"
                          showIcon
                        />
                      )}
                    </FlexBox>
                  </>
                );
              case IntegrationType.VIVENDI: {
                const useVivendiWindowsLogin =
                  properties.USE_VIVENDI_WINDOWS_LOGIN === 'true';
                const disableVivendiWindowsLoginForSyncUser =
                  properties.DISABLE_SYNC_USER_VIVENDI_WINDOWS_LOGIN === 'true';

                return (
                  <>
                    <LabelRow
                      label="Sync-Nutzer Nutzername"
                      value={integrationConfig.credentialsId}
                    />
                    <LabelRow
                      label="Sync-Nutzer Passwort"
                      value={integrationConfig.credentialsSecret}
                    />
                    <LabelRow
                      label="Windows Login"
                      value={useVivendiWindowsLogin ? 'Ja' : 'Nein'}
                    />
                    <LabelRow
                      label="Sync-Nutzer Windows Login"
                      value={
                        useVivendiWindowsLogin &&
                        !disableVivendiWindowsLoginForSyncUser
                          ? 'Ja'
                          : 'Nein'
                      }
                    />
                  </>
                );
              }
              default:
                return (
                  <>
                    <LabelRow
                      label="Sync-Nutzer Nutzername"
                      value={integrationConfig.credentialsId}
                    />
                    <LabelRow
                      label="Sync-Nutzer Passwort"
                      value={integrationConfig.credentialsSecret}
                    />
                  </>
                );
            }
          })()}
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default SeniorCareIntegrationConfigSummary;

export const readSeniorCareOrganizationsUsers =
  'senior-care.api.voize.de/read:organization-users';
export const writeSeniorCareOrganizationsUsers =
  'senior-care.api.voize.de/write:organization-users';
export const deleteSeniorCareOrganizationsUsers =
  'senior-care.api.voize.de/delete:organization-users';

export const readSeniorCareAdminUsers = 'senior-care.api.voize.de/read:users';
export const writeSeniorCareAdminUsers = 'senior-care.api.voize.de/write:users';
export const deleteSeniorCareAdminUsers =
  'senior-care.api.voize.de/delete:users';

export const readSeniorCareIntegrationAreas =
  'senior-care.api.voize.de/read:integration-areas';

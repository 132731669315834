import {
  AreaChartOutlined,
  AudioOutlined,
  LinkOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Alert, Skeleton, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { usePermissionServiceApi } from 'api/contracts/contractsApi';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { shouldForwardProp } from 'utlis/styledPropforwarding';

const supportedTypes = [
  'user-analytics',
  'recording',
  'named-user-analytics',
  'named-speaker',
];

interface Permission {
  type: string;
  accepted: boolean;
}

interface TableRow {
  speakerId: string;
  organization: string;
  permissions: Permission[];
}

const stringSorter = (a: string, b: string) => a.localeCompare(b);

const ContractsView: React.FC = () => {
  const { getLatestContractsByType } = usePermissionServiceApi();

  const {
    data: tableRows,
    isLoading,
    isError,
  } = useQuery<TableRow[]>({
    queryKey: ['contracts'],
    queryFn: async () => {
      const contracts = (
        await Promise.all(
          supportedTypes.map((type) => getLatestContractsByType(type)),
        )
      ).flat();

      const groupedContracts = _.groupBy(contracts, 'speakerId');

      return Object.keys(groupedContracts)
        .map((speakerId) => {
          const contracts = groupedContracts[speakerId];

          return {
            speakerId,
            organization: contracts[0].organization,
            permissions: contracts.map(({ type, accepted }) => ({
              type,
              accepted,
            })),
          };
        })
        .sort((a, b) => stringSorter(a.organization, b.organization));
    },
  });

  const renderPermission = ({ type, accepted }: Permission) => {
    const color = accepted ? 'green' : 'red';

    if (type === 'user-analytics')
      return (
        <Tooltip title={type} key={type}>
          <AreaChartOutlined key={type} style={{ color }} />
        </Tooltip>
      );
    if (type === 'recording')
      return (
        <Tooltip key={type} title={type}>
          <AudioOutlined key={type} style={{ color }} />
        </Tooltip>
      );
    if (type === 'named-user-analytics')
      return (
        <Tooltip key={type} title={type}>
          <UserOutlined key={type} style={{ color }} />
        </Tooltip>
      );
    if (type === 'named-speaker')
      return (
        <Tooltip key={type} title={type}>
          <LinkOutlined key={type} style={{ color }} />
        </Tooltip>
      );

    return (
      <ColoredText key={type} color={color}>
        {type}
      </ColoredText>
    );
  };

  const columns: ColumnsType<TableRow> = [
    {
      title: 'Speaker ID',
      key: 'speakerId',
      render: (_, record) => record.speakerId,
    },
    {
      title: 'Organization',
      key: 'organization',
      sorter: (a: TableRow, b: TableRow) =>
        stringSorter(a.organization, b.organization),
      filters: _.uniq(
        (tableRows ?? []).map((tableRow) => tableRow.organization),
      ).map((organization) => ({ text: organization, value: organization })),
      onFilter: (value: any, record: TableRow) => record.organization === value,
      render: (_, record) => record.organization,
    },
    {
      title: 'Permissions',
      key: 'permissions',
      render: (_, record) => record.permissions.map(renderPermission),
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[{ name: 'Zustimmungen', href: '/admin/contracts' }]}
      />
      <PageTitleBar title="Zustimmungen" Icon={ResourceIcons.Contract} />
      <PageContentContainer>
        {isError && <Alert type="error" message="Something went wrong" />}
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Table
            dataSource={tableRows}
            columns={columns}
            pagination={{ pageSize: 200 }}
            rowKey={(record) => record.speakerId}
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default ContractsView;

const ColoredText = styled.span.withConfig({
  shouldForwardProp: shouldForwardProp(['color']),
})<{ color: string }>`
  color: ${(props) => props.color};
`;

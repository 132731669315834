import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { useCallback } from 'react';

const useManagementDevice = (deviceId: string) => {
  const queryClient = useQueryClient();
  const { getDevice, organization } = useManagementApiFromContext();

  const { data: device, ...query } = useQuery({
    queryKey: ['management-devices', organization, deviceId],
    queryFn: () => getDevice(deviceId),
    placeholderData: keepPreviousData,
  });

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['management-devices', organization, deviceId],
    });
  }, [queryClient, organization, deviceId]);

  return {
    device,
    ...query,
    invalidate,
  };
};

export default useManagementDevice;

import { Outlet } from '@tanstack/react-router';
import { DeviceConfigurationsContextProvider } from './contexts';
import ScaffoldWithAuth from './ScaffoldWithAuth';

const AdminView: React.FC = () => {
  return (
    <ScaffoldWithAuth>
      <DeviceConfigurationsContextProvider>
        <Outlet />
      </DeviceConfigurationsContextProvider>
    </ScaffoldWithAuth>
  );
};

export default AdminView;

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  message,
} from 'antd';
import { FlexBox } from 'components/Helpers';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import TagList from 'components/common/TagList';
import DevicePropertiesForm from 'components/common/device-properties/DevicePropertiesForm';
import ResourceIcons from 'components/common/resourceIcons';
import DuplicateManagementDeviceConfigurationButton from 'components/device-configuration/DuplicateManagementDeviceConfigurationButton';
import { integrationAreasDevicePropertyKey } from 'components/device-configuration/features/senior-care-integration-config/SeniorCareIntegrationAreasFeature';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { useSeniorCareDeviceConfigs } from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import DevicePropertiesList from 'components/views/DevicePropertiesList';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import { features } from 'defaults/featuresToDeviceProperties';
import { deviceConfigurationKey } from 'deviceMetadataKeys';
import { useManagementDeviceConfigurationsInvalidation } from 'hooks/useManagementDeviceConfigurations';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import {
  ManagementDeviceConfiguration,
  PutManagementDeviceConfiguration,
} from 'interfaces/managementDeviceConfiguration';
import { Operator } from 'interfaces/matchers';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useNullablePathOrganization } from 'router';
import { isUserAdvancedAtom } from 'state/state';
import { v4 as uuidv4 } from 'uuid';
import { SeniorCareDeviceConfig } from './SeniorCareDeviceConfig';

const SeniorCareDeviceConfigsView: React.FC = () => {
  const organization = useNullablePathOrganization();
  const [editItem, setEditItem] =
    useState<ManagementDeviceConfiguration | null>(null);
  const [isCreateViewOpen, setIsCreateViewOpen] = useState(false);

  const deviceConfigs = useSeniorCareDeviceConfigs();

  const {
    putManagementDeviceConfiguration,
    postManagementDeviceConfiguration,
    deleteManagementDeviceConfiguration,
  } = useManagementApiFromContext();
  const invalidateManagementDeviceConfigurations =
    useManagementDeviceConfigurationsInvalidation();

  const {
    mutate: createConfig,
    error: createError,
    isPending: isSubmittingCreation,
  } = useMutation({
    mutationFn: postManagementDeviceConfiguration,
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Konfiguration wurde erstellt.');
      setIsCreateViewOpen(false);
    },
  });

  const {
    mutate: updateConfig,
    error: updateError,
    isPending: isSubmittingEdit,
  } = useMutation({
    mutationFn: async (data: PutManagementDeviceConfiguration) => {
      if (editItem === null) throw new Error('Edit item is null');
      await putManagementDeviceConfiguration(editItem.id, data);
    },
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Konfiguration wurde bearbeitet.');
      setEditItem(null);
    },
  });

  const { mutate: deleteConfig } = useMutation({
    mutationFn: deleteManagementDeviceConfiguration,
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Konfiguration wurde gelöscht.');
    },
  });

  const getColumnSearchProps = useTableSearchFilter<SeniorCareDeviceConfig>();

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Pflege App Gerätekonfigurationen',
            href: `/management/${organization}/senior-care-device-configurations`,
          },
        ]}
      />
      <PageTitleBar
        title="Pflege App Gerätekonfigurationen"
        Icon={ResourceIcons.DeviceConfiguration}
        renderToolbar={() => (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsCreateViewOpen(true)}
          >
            Gerätekonfiguration anlegen
          </Button>
        )}
      />
      {editItem !== null && (
        <Modal
          open
          title={`${editItem.name} bearbeiten`}
          onCancel={() => setEditItem(null)}
          styles={{
            body: {
              padding: '20px 0',
            },
          }}
          width={1100}
          footer={null}
        >
          <ConfigForm
            error={updateError}
            isSubmitting={isSubmittingEdit}
            onSubmit={(values) => {
              updateConfig({
                name: values.name,
                priority: editItem.priority,
                deviceSelector: editItem.deviceSelector,
                properties: values.properties,
              });
            }}
            initialValues={{
              name: editItem.name,
              properties: editItem.properties,
            }}
          />
        </Modal>
      )}
      <Modal
        open={isCreateViewOpen}
        title="Gerätekonfiguration erstellen"
        onCancel={() => setIsCreateViewOpen(false)}
        styles={{
          body: {
            padding: '20px 0',
          },
        }}
        width={800}
        footer={null}
      >
        <ConfigForm
          error={createError}
          isSubmitting={isSubmittingCreation}
          onSubmit={(values) => {
            createConfig({
              name: values.name,
              priority: 100,
              deviceSelector: {
                matchLabels: [
                  {
                    key: deviceConfigurationKey,
                    operator: Operator.IN,
                    values: [uuidv4()],
                  },
                ],
                matchRole: {
                  in: [seniorCareAppDeviceRole],
                },
              },
              properties: values.properties,
            });
          }}
          initialValues={{
            name: '',
            properties:
              features.find(
                (feature) => feature.id === integrationAreasDevicePropertyKey,
              )?.initialValues ?? {},
          }}
        />
      </Modal>
      <PageContentContainer>
        <Table<SeniorCareDeviceConfig>
          dataSource={deviceConfigs}
          columns={[
            {
              title: 'Name',
              ...getColumnSearchProps('name', 'name', (record) => record.name),
            },
            {
              title: 'Bereiche',
              render: (_, config) => <TagList tags={config.areaIds ?? []} />,
            },
            {
              title: 'Geräte',
              render: (_, config) => config.devicesCount,
            },
            {
              title: 'Eigenschaften',
              render: (_, config) => (
                <DevicePropertiesList deviceProperties={config.properties} />
              ),
            },
            {
              title: 'Aktionen',
              render: (_, config) => (
                <FlexBox withgap>
                  <Button
                    icon={<EditOutlined />}
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditItem(config);
                    }}
                  />
                  <DuplicateManagementDeviceConfigurationButton
                    managementDeviceConfiguration={config}
                  />
                  <Popconfirm
                    title="Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?"
                    onConfirm={(e) => {
                      e?.stopPropagation();
                      deleteConfig(config.id);
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      type="text"
                      danger
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Popconfirm>
                </FlexBox>
              ),
            },
          ]}
        />
      </PageContentContainer>
    </>
  );
};

const ConfigForm: React.FC<{
  error: Error | null;
  isSubmitting: boolean;
  onSubmit: (data: {
    name: string;
    properties: Record<string, string>;
  }) => void;
  initialValues?: {
    name: string;
    properties: Record<string, string>;
  };
}> = ({
  onSubmit,
  isSubmitting,
  initialValues = {
    name: '',
    properties: {},
  },
  error,
}) => {
  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);

  return (
    <Form<{ name: string; properties: Record<string, string> }>
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      {error !== null && (
        <Form.Item>
          <Alert
            message="Ein Fehler ist aufgetreten."
            description={error.message}
            type="error"
            showIcon
          />
        </Form.Item>
      )}
      <Form.Item
        label="Name"
        labelAlign="left"
        labelCol={{ span: 6 }}
        name="name"
        rules={[
          { required: true, message: 'Bitte geben Sie einen Namen ein.' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="properties" noStyle />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.properties !== currentValues.properties
        }
      >
        {({ getFieldValue, setFieldValue }) => (
          <DevicePropertiesForm
            value={getFieldValue('properties')}
            onChange={(properties) => setFieldValue('properties', properties)}
            canAdd={isUserAdvanced}
            canDelete={isUserAdvanced}
          />
        )}
      </Form.Item>
      <Button
        key="submit"
        htmlType="submit"
        type="primary"
        loading={isSubmitting}
      >
        Speichern
      </Button>
    </Form>
  );
};

export default SeniorCareDeviceConfigsView;

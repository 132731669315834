import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import AdminSeniorCareAppUserTable from 'components/seniorcare/AdminSeniorCareAppUserTable';
import React from 'react';

const AdminSeniorCareUsersView: React.FC = () => {
  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Pflegebenutzer',
            href: `/admin/senior-care-users`,
          },
        ]}
      />
      <PageTitleBar title="Pflegebenutzer" Icon={ResourceIcons.User} />
      <PageContentContainer>
        <AdminSeniorCareAppUserTable />
      </PageContentContainer>
    </>
  );
};

export default AdminSeniorCareUsersView;

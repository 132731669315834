import { useQuery } from '@tanstack/react-query';
import yaml from 'js-yaml';

export const enum DevicePropertyType {
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  JSON = 'JSON',
  DURATION = 'DURATION',
  NUMBER = 'NUMBER',
}

export type DeviceProperty =
  | StringDeviceProperty
  | BooleanDeviceProperty
  | JSONDeviceProperty
  | DurationDeviceProperty
  | NumberDeviceProperty;

export interface BaseDeviceProperty<T extends DevicePropertyType> {
  name: string;
  description: string;
  source: string;
  type: T;
}

export interface StringDeviceProperty
  extends BaseDeviceProperty<DevicePropertyType.STRING> {
  type: DevicePropertyType.STRING;
  default: string | null;
}

export interface BooleanDeviceProperty
  extends BaseDeviceProperty<DevicePropertyType.BOOLEAN> {
  type: DevicePropertyType.BOOLEAN;
  default: boolean | null;
}

export interface JSONDeviceProperty
  extends BaseDeviceProperty<DevicePropertyType.JSON> {
  type: DevicePropertyType.JSON;
  default: string | null;
}

export interface DurationDeviceProperty
  extends BaseDeviceProperty<DevicePropertyType.DURATION> {
  type: DevicePropertyType.DURATION;
  default: string | null;
}

export interface NumberDeviceProperty
  extends BaseDeviceProperty<DevicePropertyType.NUMBER> {
  type: DevicePropertyType.NUMBER;
  default: number | null;
}

/**
 * Loads and parses device properties yaml file
 */
async function loadAndParseDevicePropertiesFile(
  file: string,
  source: string,
): Promise<DeviceProperty[]> {
  const response = await fetch(file);
  const text = await response.text();
  const properties = yaml.load(text) as {
    properties: Record<
      string,
      {
        description: string;
        type: string;
        default: unknown;
      }
    >;
  };

  return Object.entries(properties.properties).map(
    ([name, { description, type, default: def }]) => {
      switch (type) {
        case 'string':
          return {
            name,
            description,
            source,
            type: DevicePropertyType.STRING,
            default: def as string,
          };
        case 'boolean':
          return {
            name,
            description,
            source,
            type: DevicePropertyType.BOOLEAN,
            default: def as boolean,
          };
        case 'json':
          return {
            name,
            description,
            source,
            type: DevicePropertyType.JSON,
            default: def as string,
          };
        case 'duration':
          return {
            name,
            description,
            source,
            type: DevicePropertyType.DURATION,
            default: def as string,
          };
        case 'number':
          return {
            name,
            description,
            source,
            type: DevicePropertyType.NUMBER,
            default: def as number,
          };
        default:
          throw new Error(`Unknown device property type: ${type}`);
      }
    },
  );
}

const devicePropertiesFiles = {
  'core': '/device-properties/voize-core.yaml',
  'senior-care-app': '/device-properties/voize-app.yaml',
  'tic-app': '/device-properties/voize-app-tic.yaml',
  'sdk': '/device-properties/voize-sdk.yaml',
};

const emptyDeviceProperties: DeviceProperty[] = [];

export function useDeviceProperties() {
  const { data } = useQuery({
    queryKey: ['device-properties'],
    queryFn: async () => {
      const results = await Promise.all(
        Object.entries(devicePropertiesFiles).map(async ([source, file]) => {
          return await loadAndParseDevicePropertiesFile(file, source);
        }),
      );
      return results.flat();
    },
  });

  return data ?? emptyDeviceProperties;
}

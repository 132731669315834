import styled from 'styled-components';
import { shouldForwardProp } from 'utlis/styledPropforwarding';

interface Props {
  isClickable?: boolean;
}

const CodeBlockInline = styled.div.withConfig({
  shouldForwardProp: shouldForwardProp(['isClickable']),
})<Props>`
  font-family: monospace;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 3px 7px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  transition: background-color 0.2s ease-in-out;

  ${({ isClickable }) =>
    isClickable &&
    `
  &:hover {
    background-color: #e0e0e0;
  }
  `}
`;

export default CodeBlockInline;

import { Typography } from 'antd';
import { FlexBox } from 'components/Helpers';

interface Props {
  label?: React.ReactNode;
  color?: string;
  children: React.ReactNode;
}

const InfoTagVertical: React.FC<Props> = ({ label, children, color }) => {
  return (
    <FlexBox
      direction="column"
      justifycontent="center"
      style={{ textAlign: 'center' }}
    >
      <Typography.Text type="secondary" style={{ fontSize: '10px', color }}>
        {label}
      </Typography.Text>
      {children}
    </FlexBox>
  );
};

export default InfoTagVertical;

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://579c46fe9f000d5b1e5911ca7f945b3f@o1411975.ingest.us.sentry.io/4507974516604928',
  integrations: [
    Sentry.breadcrumbsIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/.*voize\.de\//],
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  beforeBreadcrumb: (breadcrumb) => {
    if (breadcrumb.category === 'console') {
      // Ignore console logs
      return null;
    }

    return breadcrumb;
  },
});

import {
  deleteContractsScope,
  readContractScope,
  writeContractScope,
} from 'api/contracts/contractsApiScopes';
import {
  deleteAdminDeviceActivationRequestsScope,
  deleteDeviceConfigurationsScope,
  deleteDevicesScope,
  deleteManagementDeviceActivationRequestsScope,
  deleteOrganizationDeviceConfigurationsScope,
  deleteOrganizationDevicesScope,
  deleteOrganizationsScope,
  deleteUsersScope,
  readAdminDeviceActivationRequestsScope,
  readDeviceActivationCodes,
  readDeviceConfigurationsScope,
  readDevicesScope,
  readManagementDeviceActivationRequestsScope,
  readOrganizationDeviceActivationCodes,
  readOrganizationDeviceConfigurationsScope,
  readOrganizationDevicesScope,
  readOrganizationSelfScope,
  readOrganizationsScope,
  readUsersScope,
  sendDeviceRegistrationMails,
  writeAdminDeviceActivationRequestsScope,
  writeDeviceConfigurationsScope,
  writeDevicesScope,
  writeManagementDeviceActivationRequestsScope,
  writeOrganizationDeviceConfigurationsScope,
  writeOrganizationDevicesScope,
  writeOrganizationsScope,
  writeUsersScope,
} from 'api/devices/deviceManagementApiScopes';
import {
  deleteSeniorCareAdminUsers,
  deleteSeniorCareOrganizationsUsers,
  readSeniorCareAdminUsers,
  readSeniorCareIntegrationAreas,
  readSeniorCareOrganizationsUsers,
  writeSeniorCareAdminUsers,
  writeSeniorCareOrganizationsUsers,
} from 'api/seniorcare/seniorCareApiScopes';

export const scope = [
  readDevicesScope,
  writeDevicesScope,
  deleteDevicesScope,
  readDeviceConfigurationsScope,
  writeDeviceConfigurationsScope,
  deleteDeviceConfigurationsScope,
  readDeviceActivationCodes,
  sendDeviceRegistrationMails,
  readAdminDeviceActivationRequestsScope,
  writeAdminDeviceActivationRequestsScope,
  deleteAdminDeviceActivationRequestsScope,
  readOrganizationDevicesScope,
  writeOrganizationDevicesScope,
  deleteOrganizationDevicesScope,
  readOrganizationDeviceActivationCodes,
  readManagementDeviceActivationRequestsScope,
  writeManagementDeviceActivationRequestsScope,
  deleteManagementDeviceActivationRequestsScope,
  readOrganizationDeviceConfigurationsScope,
  writeOrganizationDeviceConfigurationsScope,
  deleteOrganizationDeviceConfigurationsScope,

  readOrganizationSelfScope,

  readUsersScope,
  writeUsersScope,
  deleteUsersScope,

  readSeniorCareOrganizationsUsers,
  writeSeniorCareOrganizationsUsers,
  deleteSeniorCareOrganizationsUsers,

  readSeniorCareAdminUsers,
  writeSeniorCareAdminUsers,
  deleteSeniorCareAdminUsers,

  readSeniorCareIntegrationAreas,

  readContractScope,
  writeContractScope,
  deleteContractsScope,

  readOrganizationsScope,
  writeOrganizationsScope,
  deleteOrganizationsScope,
].join(' ');

import { DeleteOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, message, Popconfirm } from 'antd';
import { useAdminUserApi } from 'api/devices/useAdminUserApi';
import { User } from 'interfaces/user';
import { useState } from 'react';
import getErrorMessage from 'utlis/getErrorMessage';
import AdminUserModal from './AdminUserModal';
import AssignNewPasswordModal from './AssignNewPasswordModal';

interface Props {
  user: User;
  onUpdated: () => void;
  onDeleted: () => void;
}
const UserControls: React.FC<Props> = ({ user, onUpdated, onDeleted }) => {
  const { deleteUser } = useAdminUserApi();

  const { mutateAsync: removeUser } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      message.success('Nutzer wurde gelöscht');
      onDeleted();
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    },
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAssignNewPasswordModalOpen, setIsAssignNewPasswordModalOpen] =
    useState(false);

  return (
    <>
      <AdminUserModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        user={user}
        organizationId={undefined}
        onUpdated={onUpdated}
        onCreated={undefined}
      />
      <Button
        icon={<EditOutlined />}
        onClick={() => setIsEditModalOpen(true)}
      />
      <AssignNewPasswordModal
        isOpen={isAssignNewPasswordModalOpen}
        onClose={() => {
          setIsAssignNewPasswordModalOpen(false);
        }}
        userId={user.id}
      />
      <Button
        icon={<UndoOutlined />}
        onClick={() => {
          setIsAssignNewPasswordModalOpen(true);
        }}
      />
      <Popconfirm
        title="Möchten Sie den Nutzer löschen?"
        cancelText="Abbrechen"
        okText="Ja"
        onConfirm={() => removeUser(user.id)}
      >
        <Button danger icon={<DeleteOutlined />} />
      </Popconfirm>
    </>
  );
};

export default UserControls;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, message, Modal } from 'antd';
import KeyValueForm from 'components/common/KeyValueForm';
import { useAvailableDeviceSelectors } from 'components/device-configuration/DeviceConfigurationHelper';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { ManagementDevice } from 'interfaces/device';
import React from 'react';
import { toBaseError } from 'utlis/BaseError';
import { getLabelsDiff } from 'utlis/getLabelsDiff';

interface Props {
  device: ManagementDevice;
  onClose?: () => void;
  onEdited?: () => void;
}

interface FormData {
  labels: Record<string, string>;
}

const ManagementDeviceEditModal: React.FC<Props> = ({
  device,
  onClose,
  onEdited,
}) => {
  const queryClient = useQueryClient();
  const { patchDeviceLabels, updateDeviceProperties } =
    useManagementApiFromContext();
  const availableDeviceSelectorLabels = useAvailableDeviceSelectors();

  const [form] = Form.useForm<FormData>();

  const { mutate: onSubmit, isPending } = useMutation({
    mutationKey: ['management-device', device.id],
    mutationFn: async (data: FormData) => {
      await patchDeviceLabels(
        device.id,
        getLabelsDiff(device.labels, data.labels),
      );
      await updateDeviceProperties(device.id);

      return {
        ...device,
        labels: data.labels,
      };
    },
    onSuccess: () => {
      onEdited?.();
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      message.success('Gerät wurde bearbeiten.');
    },
    onError: (error) => {
      const baseError = toBaseError(error);
      message.error(
        `Gerät konnte nicht bearbeitet werden: ${baseError.message}`,
      );
    },
  });

  return (
    <Modal
      title="Gerät bearbeiten"
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      okText="Speichern"
      width={900}
      confirmLoading={isPending}
    >
      <Form<FormData>
        form={form}
        onFinish={onSubmit}
        initialValues={{
          labels: device.labels,
        }}
      >
        <Form.Item name="labels" noStyle>
          <KeyValueForm labelOptions={availableDeviceSelectorLabels} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManagementDeviceEditModal;

export const seniorCareAppDeviceRole = 'senior-care-app-device';
export const sdkDeviceRole = 'sdk-device';
export const ticAppDeviceRole = 'tic-app-device';
export const projectManagementAppDeviceRole = 'project-management-app-device';

const deviceRoles = [
  sdkDeviceRole,
  ticAppDeviceRole,
  seniorCareAppDeviceRole,
  projectManagementAppDeviceRole,
];

export default deviceRoles;

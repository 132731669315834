import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Alert, Button, message, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  deleteSeniorCareAdminUsers,
  writeSeniorCareAdminUsers,
} from 'api/seniorcare/seniorCareApiScopes';
import { useSeniorCareApi } from 'api/seniorcare/useSeniorCareApi';
import { FlexBox } from 'components/Helpers';
import LabelList from 'components/views/LabelList';
import useAuthContext from 'hooks/auth/useAuthContext';
import useLoadAllPaginatedCursorQuery from 'hooks/useLoadAllPaginatedCursorQuery';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { SeniorCareAdminUser } from 'interfaces/seniorcare/seniorCareUser';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import SeniorCareAppUserEditModal from './SeniorCareAppUserEditModal';

const AdminSeniorCareAppUserTable: React.FC = () => {
  const {
    getAdminSeniorCareUsersV2,
    deleteAdminSeniorCareUser,
    updateAdminSeniorCareUser,
  } = useSeniorCareApi();
  const { scope } = useAuthContext();
  const [userToEdit, setUserToEdit] = useState<null | SeniorCareAdminUser>(
    null,
  );

  const {
    data: users,
    isLoading,
    error,
  } = useLoadAllPaginatedCursorQuery(
    {
      queryKey: ['admin-senior-care-users'],
      queryFn: getAdminSeniorCareUsersV2,
    },
    500,
  );

  const organisations = useMemo(() => {
    return _.uniq((users ?? []).map((user) => user.organization));
  }, [users]);

  const getColumnSearchProps = useTableSearchFilter<SeniorCareAdminUser>();

  const columns: ColumnsType<SeniorCareAdminUser> = [
    {
      title: 'Organisation',
      key: 'organization',
      sorter: (a: SeniorCareAdminUser, b: SeniorCareAdminUser) =>
        a.organization.localeCompare(b.organization),
      render: (element: SeniorCareAdminUser) => element.organization,
      filters: organisations.map((org) => ({ text: org, value: org })),
      onFilter: (value, record) => record.organization === value,
      filterSearch: true,
    },
    {
      title: 'Vorname',
      key: 'firstname',
      sorter: (a: SeniorCareAdminUser, b: SeniorCareAdminUser) =>
        a.firstname.localeCompare(b.firstname),
      ...getColumnSearchProps(
        'Vorname',
        'firstname',
        (element) => element.firstname,
      ),
    },
    {
      title: 'Nachname',
      key: 'lastname',
      sorter: (a: SeniorCareAdminUser, b: SeniorCareAdminUser) =>
        a.lastname.localeCompare(b.lastname),
      ...getColumnSearchProps(
        'Nachname',
        'lastname',
        (element) => element.lastname,
      ),
    },
    {
      title: 'Letzte Anmeldung',
      key: 'last-seen-at',
      sorter: (a: SeniorCareAdminUser, b: SeniorCareAdminUser) => {
        const lastSeen = (x: SeniorCareAdminUser) =>
          x.lastSeenAt !== null ? new Date(x.lastSeenAt).getTime() : 0;
        return lastSeen(b) - lastSeen(a);
      },
      render: (element: SeniorCareAdminUser) =>
        element.lastSeenAt !== null
          ? moment(element.lastSeenAt)
              .locale('de')
              .format('dd. DD.MM.YY, HH:mm')
          : '(noch keine Anmeldung)',
    },
    {
      title: 'Erstellt',
      key: 'created-at',
      sorter: (a: SeniorCareAdminUser, b: SeniorCareAdminUser) => {
        const createdAt = (x: SeniorCareAdminUser) =>
          new Date(x.createdAt).getTime();
        return createdAt(b) - createdAt(a);
      },
      render: (element: SeniorCareAdminUser) =>
        moment(element.createdAt).locale('de').format('dd. DD.MM.YY, HH:mm'),
    },
    {
      title: 'Schnittstellen-Zugangsdaten-Typ',
      sorter: (a: SeniorCareAdminUser, b: SeniorCareAdminUser) => {
        const type = (x: SeniorCareAdminUser) => x.integrationCredentials.type;
        return type(b).localeCompare(type(a));
      },
      ...getColumnSearchProps(
        'Schnittstellen-Zugangsdaten-Typ',
        'credentials.type',
        (element) => element.integrationCredentials.type,
      ),
    },
    {
      title: 'Schnittstellen-Nutzer-ID',
      sorter: (a: SeniorCareAdminUser, b: SeniorCareAdminUser) => {
        const userId = (x: SeniorCareAdminUser) =>
          x.integrationCredentials.userId;
        return userId(b).localeCompare(userId(a));
      },
      ...getColumnSearchProps(
        'Schnittstellen-Nutzer-ID',
        'credentials.userId',
        (element) => element.integrationCredentials.userId,
      ),
    },
    {
      title: 'Metadaten',
      render: (record: SeniorCareAdminUser) => (
        <LabelList labels={record.metadata} />
      ),
    },
    {
      title: 'Aktionen',
      key: 'actions',
      render: (user: SeniorCareAdminUser) => (
        <FlexBox
          withgap
          gap={10}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {scope.includes(writeSeniorCareAdminUsers) && (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setUserToEdit(user);
              }}
            />
          )}

          {scope.includes(deleteSeniorCareAdminUsers) && (
            <Popconfirm
              title="Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?"
              onConfirm={async () => {
                await deleteAdminSeniorCareUser(user.id);
                message.success('Benutzer wurde gelöscht.');
              }}
              okText="Yes"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </FlexBox>
      ),
    },
  ];

  return (
    <>
      {userToEdit !== null && (
        <SeniorCareAppUserEditModal
          user={userToEdit}
          onSubmit={(data) => updateAdminSeniorCareUser(userToEdit.id, data)}
          onEdited={() => setUserToEdit(null)}
          onClose={() => setUserToEdit(null)}
        />
      )}
      {error && <Alert type="error" message={error.message} />}
      {users && <span>{users.length} Benutzer</span>}
      <Table
        dataSource={users}
        columns={columns}
        rowKey={(element: SeniorCareAdminUser) => element.id}
        loading={isLoading}
        pagination={{
          defaultPageSize: 100,
          position: ['bottomRight', 'topRight'],
          pageSizeOptions: ['20', '50', '100', '500', '1000'],
        }}
      />
    </>
  );
};

export default AdminSeniorCareAppUserTable;

import {
  integrationAreasDevicePropertyKey,
  SeniorIntegrationAreasSchema,
} from 'components/device-configuration/features/senior-care-integration-config/SeniorCareIntegrationAreasFeature';
import { OrganizationDeviceSelector } from 'interfaces/adminOrganizationDeviceConfig';
import { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import { validateOrThrow } from 'utlis/validationUtil';

/**
 * Only used inside of the admin tool for abstracting the integration configuration
 */
export interface SeniorCareDeviceConfig {
  id: string;
  name: string;
  areaIds: string[] | null;
  devicesCount: number;
  deviceSelector: OrganizationDeviceSelector;
  priority: number;
  properties: Record<string, string>;
}

export const toSeniorCareDeviceConfig = (
  config: ManagementDeviceConfiguration,
): SeniorCareDeviceConfig => {
  const integrationAreaIds = config.properties[
    integrationAreasDevicePropertyKey
  ]
    ? validateOrThrow(
        JSON.parse(config.properties[integrationAreasDevicePropertyKey]),
        SeniorIntegrationAreasSchema,
      )
    : null;

  return {
    id: config.id,
    name: config.name,
    areaIds: integrationAreaIds,
    devicesCount: config.devicesCount,
    properties: config.properties,
    deviceSelector: config.deviceSelector,
    priority: config.priority,
  };
};

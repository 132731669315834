import { Outlet, useNavigate } from '@tanstack/react-router';
import useAdminToManagementApi from 'api/devices/useAdminToManagementApi';
import useManagementApi from 'api/devices/useManagementApi';
import ErrorAlert from 'components/common/ErrorAlert';
import ManagementDeviceCreateModal from 'components/device/ManagementDeviceCreateModal';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import useUserOrg from 'hooks/auth/useUserOrg';
import useRegisterShortcutListener from 'hooks/useRegisterShortcutListener';
import { ManagementDevicePostResponse } from 'interfaces/device';
import { ManagementApi } from 'interfaces/managementApi';
import { createContext, useCallback, useContext, useState } from 'react';
import { organizationRoute } from 'router';
import { ShortcutEvent } from 'utlis/shortcuts';
import { DeviceConfigurationsContextProvider } from './contexts';

/**
 * Should only be used in OrganizationView because the management API requires a selected organization.
 */
const ManagementApiContext = createContext<ManagementApi | undefined>(
  undefined,
);

/**
 * Allows to safely use the ManagementApi for users with admin role that are not in the organization.
 */
export function useManagementApiFromContext() {
  const context = useContext(ManagementApiContext);

  if (context === undefined)
    throw new Error(
      'useManagementApiFromContext must be used within a ManagementApiContext',
    );

  return context;
}

const OrganizationView: React.FC = () => {
  const { organization } = organizationRoute.useParams();

  const navigate = useNavigate();
  const userOrg = useUserOrg();
  const isAdmin = useIsAdmin();

  const managementDeviceApi = useManagementApi();
  const adminToManagementDeviceApi = useAdminToManagementApi(organization);
  const [isDeviceCreateModalOpen, setIsDeviceCreateModalOpen] = useState(false);

  useRegisterShortcutListener(
    ShortcutEvent.CREATE_DEVICE,
    useCallback(() => {
      setIsDeviceCreateModalOpen(true);
    }, []),
  );

  if (!isAdmin && userOrg !== organization)
    return (
      <ErrorAlert
        redirect={() =>
          navigate({
            to: '/',
          })
        }
        errorCode="403"
        subtitle="You have no access to this page"
      />
    );

  return (
    <DeviceConfigurationsContextProvider>
      <ManagementApiContext.Provider
        value={isAdmin ? adminToManagementDeviceApi : managementDeviceApi}
      >
        <ManagementDeviceCreateModal
          isOpen={isDeviceCreateModalOpen}
          onClose={() => setIsDeviceCreateModalOpen(false)}
          onCreated={(device: ManagementDevicePostResponse) => {
            navigate({
              to: '/management/$organization/devices/$deviceId',
              params: { organization, deviceId: device.id },
              search: { isNew: true },
            });
          }}
        />

        <Outlet />
      </ManagementApiContext.Provider>
    </DeviceConfigurationsContextProvider>
  );
};

export default OrganizationView;

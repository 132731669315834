import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { getRequiredEnv } from 'config';
import {
  DevicesPostRegistrationMailRequest,
  MailTemplate,
} from 'interfaces/adminDevice';
import { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import {
  GlobalDeviceConfiguration,
  PostGlobalDeviceConfiguration,
} from 'interfaces/adminGlobalDeviceConfig';
import {
  OrganizationDeviceConfiguration,
  PostOrganizationDeviceConfiguration,
  PutOrganizationDeviceConfiguration,
} from 'interfaces/adminOrganizationDeviceConfig';
import { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { Version } from 'interfaces/version';
import { useCallback } from 'react';
import {
  deleteDeviceConfigurationsScope,
  readDeviceConfigurationsScope,
  writeDeviceConfigurationsScope,
  writeDevicesScope,
} from './deviceManagementApiScopes';

const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

export const voizeDeviceManagementApi = Axios.create({
  baseURL: getRequiredEnv('VITE_DEVICE_MANAGEMENT_API_URL'),
});

export const useDeviceManagementServiceVersion = () => {
  const fetchVersion = useCallback(async (): Promise<Version> => {
    const { data } = await voizeDeviceManagementApi.get('/version');
    return data;
  }, []);

  return fetchVersion;
};

export const useAdminDeviceApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(
    async (...scopes: string[]) =>
      await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: scopes.join(' '),
        },
      }),
    [getAccessTokenSilently],
  );

  const postDevicesAndSendRegistrationMails = useCallback(
    async (
      request: DevicesPostRegistrationMailRequest,
    ): Promise<AdminDeviceV4Dto[]> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      const { data } = await voizeDeviceManagementApi.post(
        `/v1/admin/registration-mails`,
        request,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const sendRegistrationMail = useCallback(
    async (
      deviceId: string,
      mailTemplate: MailTemplate,
    ): Promise<AdminDeviceV4Dto> => {
      const accessToken = await getAccessToken(writeDevicesScope);

      const { data } = await voizeDeviceManagementApi.post(
        `/v1/admin/registration-mails/devices/${deviceId}`,
        mailTemplate,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const getAdminDeviceActivationRequests = useCallback(
    async (organization?: string): Promise<DeviceActivationRequest[]> => {
      const accessToken = await getAccessToken();

      const { data } = await voizeDeviceManagementApi.get<
        DeviceActivationRequest[]
      >(`/v1/admin/device-activation-requests`, {
        params: {
          organization,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    },
    [getAccessToken],
  );

  const putAdminDeviceActivationRequestOrganization = useCallback(
    async (
      deviceActivationRequestId: string,
      organization: string,
    ): Promise<void> => {
      const accessToken = await getAccessToken();

      await voizeDeviceManagementApi.put<void>(
        `/v1/admin/device-activation-requests/${deviceActivationRequestId}/organization`,
        organization,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'text/plain',
          },
        },
      );
    },
    [getAccessToken],
  );

  const postAdminDeviceActivationRequestApprove = useCallback(
    async (
      deviceActivationRequestId: string,
      deviceRole: string,
      deviceLabels: Record<string, string>,
      deviceConfiguration: string | null = null,
    ): Promise<void> => {
      const accessToken = await getAccessToken();

      await voizeDeviceManagementApi.post<void>(
        `/v1/admin/device-activation-requests/${deviceActivationRequestId}/approve`,
        {
          deviceRole,
          deviceLabels,
          deviceConfiguration,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteAdminDeviceActivationRequest = useCallback(
    async (deviceActivationRequestId: string): Promise<void> => {
      const accessToken = await getAccessToken();

      await voizeDeviceManagementApi.delete<void>(
        `/v1/admin/device-activation-requests/${deviceActivationRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const getGlobalDeviceConfigurations = useCallback(async (): Promise<
    GlobalDeviceConfiguration[]
  > => {
    const accessToken = await getAccessToken(readDeviceConfigurationsScope);

    const { data } = await voizeDeviceManagementApi.get<
      GlobalDeviceConfiguration[]
    >(`/v1/admin/global-device-configurations`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  }, [getAccessToken]);

  const getGlobalDeviceConfiguration = useCallback(
    async (id: string): Promise<GlobalDeviceConfiguration> => {
      const accessToken = await getAccessToken(readDeviceConfigurationsScope);

      const { data } =
        await voizeDeviceManagementApi.get<GlobalDeviceConfiguration>(
          `/v1/admin/global-device-configurations/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    },
    [getAccessToken],
  );

  const postGlobalDeviceConfiguration = useCallback(
    async (
      deviceConfiguration: PostGlobalDeviceConfiguration,
    ): Promise<GlobalDeviceConfiguration> => {
      const accessToken = await getAccessToken(writeDeviceConfigurationsScope);

      const { data } =
        await voizeDeviceManagementApi.post<GlobalDeviceConfiguration>(
          `/v1/admin/global-device-configurations`,
          deviceConfiguration,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    },
    [getAccessToken],
  );

  const putGlobalDeviceConfiguration = useCallback(
    async (
      id: string,
      putGlobalDeviceConfiguration: PostGlobalDeviceConfiguration,
    ): Promise<void> => {
      const accessToken = await getAccessToken(writeDeviceConfigurationsScope);

      await voizeDeviceManagementApi.put<GlobalDeviceConfiguration>(
        `/v1/admin/global-device-configurations/${id}`,
        putGlobalDeviceConfiguration,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteGlobalDeviceConfiguration = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(deleteDeviceConfigurationsScope);

      await voizeDeviceManagementApi.delete<GlobalDeviceConfiguration>(
        `/v1/admin/global-device-configurations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const getOrganizationDeviceConfigurations = useCallback(
    async (
      organization?: string,
    ): Promise<OrganizationDeviceConfiguration[]> => {
      const accessToken = await getAccessToken(readDeviceConfigurationsScope);

      const { data } = await voizeDeviceManagementApi.get<
        OrganizationDeviceConfiguration[]
      >(`/v1/admin/organization-device-configurations`, {
        params: {
          organization,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },
    [getAccessToken],
  );

  const getOrganizationDeviceConfiguration = useCallback(
    async (id: string): Promise<OrganizationDeviceConfiguration> => {
      const accessToken = await getAccessToken(readDeviceConfigurationsScope);

      const { data } =
        await voizeDeviceManagementApi.get<OrganizationDeviceConfiguration>(
          `/v1/admin/organization-device-configurations/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    },
    [getAccessToken],
  );

  const postOrganizationDeviceConfiguration = useCallback(
    async (
      deviceConfiguration: PostOrganizationDeviceConfiguration,
    ): Promise<OrganizationDeviceConfiguration> => {
      const accessToken = await getAccessToken(writeDeviceConfigurationsScope);

      const { data } =
        await voizeDeviceManagementApi.post<OrganizationDeviceConfiguration>(
          `/v1/admin/organization-device-configurations`,
          deviceConfiguration,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

      return data;
    },
    [getAccessToken],
  );

  const putOrganizationDeviceConfiguration = useCallback(
    async (
      id: string,
      putOrganizationDeviceConfiguration: PutOrganizationDeviceConfiguration,
    ): Promise<void> => {
      const accessToken = await getAccessToken(writeDeviceConfigurationsScope);

      await voizeDeviceManagementApi.put<OrganizationDeviceConfiguration>(
        `/v1/admin/organization-device-configurations/${id}`,
        putOrganizationDeviceConfiguration,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteOrganizationDeviceConfiguration = useCallback(
    async (id: string): Promise<void> => {
      const accessToken = await getAccessToken(deleteDeviceConfigurationsScope);

      await voizeDeviceManagementApi.delete<OrganizationDeviceConfiguration>(
        `/v1/admin/organization-device-configurations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  return {
    postDevicesAndSendRegistrationMails,
    sendRegistrationMail,
    getAdminDeviceActivationRequests,

    putAdminDeviceActivationRequestOrganization,

    postAdminDeviceActivationRequestApprove,
    deleteAdminDeviceActivationRequest,

    getGlobalDeviceConfigurations,
    getGlobalDeviceConfiguration,
    postGlobalDeviceConfiguration,
    putGlobalDeviceConfiguration,
    deleteGlobalDeviceConfiguration,
    getOrganizationDeviceConfigurations,
    getOrganizationDeviceConfiguration,
    postOrganizationDeviceConfiguration,
    putOrganizationDeviceConfiguration,
    deleteOrganizationDeviceConfiguration,
  };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import Editable from 'components/common/Editable';
import { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import { deviceDisplayNameLabel } from 'labels';

interface Props {
  device: AdminDeviceV4Dto;
  onEdited?: () => void;
}
const AdminDeviceNameEditable: React.FC<Props> = ({ device, onEdited }) => {
  const queryClient = useQueryClient();
  const { patchDeviceLabels } = useAdminDeviceApiV4();

  const { mutate: updateDevice } = useMutation({
    mutationFn: (displayName: string) => {
      if (displayName === device.labels[deviceDisplayNameLabel])
        return Promise.resolve();

      if (displayName === '') {
        return patchDeviceLabels(device.id, {
          [deviceDisplayNameLabel]: null,
        });
      }

      return patchDeviceLabels(device.id, {
        [deviceDisplayNameLabel]: displayName,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-devices'] });
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      onEdited?.();
      message.success('Gerätename wurde erfolgreich aktualisiert');
    },
  });

  return (
    <Editable
      initialValue={device.labels[deviceDisplayNameLabel]}
      onBlur={(value) => updateDevice(value)}
    />
  );
};

export default AdminDeviceNameEditable;

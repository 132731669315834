import { useRouter } from '@tanstack/react-router';
import React from 'react';
import { styled } from 'styled-components';

interface Props {
  errorCode?: string;
  subtitle?: string;
  redirect?: () => void;
  redirectText?: string;
}

const ErrorAlert: React.FC<Props> = ({
  errorCode,
  subtitle,
  redirect,
  redirectText = 'Zurück',
}) => {
  const router = useRouter();

  return (
    <ErrorAlertWrapper>
      {errorCode && <ErrorAlertTitle>{errorCode}</ErrorAlertTitle>}
      {subtitle && <ErrorAlertSubtitle>{subtitle}</ErrorAlertSubtitle>}
      <ErrorAlertButton
        onClick={() => {
          if (redirect) {
            redirect();
          } else {
            router.history.back();
          }
        }}
      >
        {redirectText}
      </ErrorAlertButton>
    </ErrorAlertWrapper>
  );
};

export default ErrorAlert;

const ErrorAlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ErrorAlertTitle = styled.div`
  font-size: 100px;
  font-weight: 900;
  margin: 0;
`;

const ErrorAlertSubtitle = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
`;

const ErrorAlertButton = styled.button`
  all: unset;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  margin-top: 40px;
  text-decoration: none;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

import { useQuery } from '@tanstack/react-query';
import { useAdminOrganizationApi } from 'api/useAdminOrganizationApi';
import { useMemo } from 'react';

const useOrganizations = () => {
  const { getOrganizations } = useAdminOrganizationApi();

  const { data: organizations, ...query } = useQuery({
    queryKey: ['organizations'],
    queryFn: getOrganizations,
  });

  const sortedOrganizations = useMemo(() => {
    if (!organizations) {
      return [];
    }

    return organizations.sort((a, b) => a.name.localeCompare(b.name));
  }, [organizations]);

  return { sortedOrganizations, organizations, ...query };
};

export default useOrganizations;

import { message } from 'antd';
import { FlexBox } from 'components/Helpers';
import Highlighter from 'react-highlight-words';
import { styled } from 'styled-components';
import CodeBlockInline from './CodeBlockInline';

interface Props {
  id: string;
  isCollapsed?: boolean;
  label?: React.ReactNode;
  highlight?: string;
  copyable?: boolean;
}

const IdTag: React.FC<Props> = ({
  id,
  isCollapsed = true,
  label,
  highlight,
  copyable = true,
}) => {
  return (
    <FlexBox alignitems="center" withgap>
      {label && <Label>{label}: </Label>}
      <CodeBlockInline
        isClickable={copyable}
        onClick={
          copyable
            ? () => {
                navigator.clipboard.writeText(id);
                message.success('ID in die Zwischenablage kopiert!');
              }
            : undefined
        }
      >
        {highlight && id.includes(highlight) ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[highlight]}
            autoEscape
            textToHighlight={id}
          />
        ) : (
          <>{isCollapsed ? id.slice(0, 8) : id}</>
        )}
      </CodeBlockInline>
    </FlexBox>
  );
};

export default IdTag;

const Label = styled.div`
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: hsl(200, 10%, 60%);
  align-items: center;
  white-space: nowrap;

  span {
    display: none;
  }
`;

import React, { useMemo, useState } from 'react';

import {
  Alert,
  AutoComplete,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Typography,
} from 'antd';
import DeviceLabelSelectors from 'components/common/device-labels/DeviceLabelSelectors';
import DevicePropertiesForm from 'components/common/device-properties/DevicePropertiesForm';
import device from 'defaults/deviceRoles';
import useOrganizationIds from 'hooks/organizations/useOrganizationIds';
import {
  OrganizationDeviceConfiguration,
  PostOrganizationDeviceConfiguration,
} from 'interfaces/adminOrganizationDeviceConfig';
import { MatchExpression, SetMatch } from 'interfaces/matchers';
import getErrorMessage from 'utlis/getErrorMessage';
import SetMatchSelector from './selectors/SetMatchSelector';

interface Props {
  initialValues?: OrganizationDeviceConfiguration;
  fixedOrganization: string | null;
  onSubmit: (dc: PostOrganizationDeviceConfiguration) => void | Promise<void>;
  error: any;
  readonly?: boolean;
  isLoading?: boolean;
}

interface FormData {
  name: string;
  organization: string;
  properties: Record<string, string>;
  priority: number;
  matchLabels: MatchExpression[] | undefined;
  matchRole: SetMatch | undefined;
}

const OrganizationDeviceConfigurationForm: React.FC<Props> = ({
  initialValues,
  fixedOrganization,
  onSubmit,
  error,
  readonly = false,
  isLoading = false,
}) => {
  const [form] = Form.useForm<FormData>();
  const orgs = useOrganizationIds();

  const [orgSearch, setOrgSearch] = useState(
    orgs.map((org) => ({ value: org })),
  );

  const inits = useMemo<FormData>(() => {
    if (initialValues === undefined) {
      return {
        name: '',
        organization: '',
        properties: {},
        matchLabels: undefined,
        matchRole: undefined,
        priority: 10,
      };
    }

    return {
      name: initialValues.name,
      organization: initialValues.organization,
      properties: initialValues.properties,
      matchLabels: initialValues.deviceSelector.matchLabels,
      matchRole: initialValues.deviceSelector.matchRole,
      priority: initialValues.priority,
    };
  }, [initialValues]);

  const transformFormValues = (
    values: FormData,
  ): PostOrganizationDeviceConfiguration => {
    return {
      properties: values.properties,
      deviceSelector: {
        matchLabels: values.matchLabels,
        matchRole: values.matchRole,
      },
      name: values.name,
      priority: values.priority,
      organization: fixedOrganization ?? values.organization,
    };
  };

  const formId = 'device-config-form';

  return (
    <Form
      labelAlign="left"
      labelCol={{ span: 3 }}
      initialValues={inits}
      id={formId}
      form={form}
      onFinish={(values) => {
        const deviceConfig = transformFormValues(values);
        onSubmit?.(deviceConfig);
      }}
      style={{ width: '900px' }}
    >
      {error && (
        <Form.Item>
          <Alert type="error" message={getErrorMessage(error)} />
        </Form.Item>
      )}
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Geben Sie einen Namen ein.' }]}
        hasFeedback
      >
        <Input readOnly={readonly} />
      </Form.Item>
      <Divider />
      {fixedOrganization == null && (
        <Form.Item
          label="Organization"
          name="organization"
          rules={[
            { required: true, message: 'Geben Sie eine Organisation ein.' },
          ]}
        >
          <AutoComplete
            options={orgSearch}
            onSearch={(text) =>
              setOrgSearch(
                orgs
                  .filter((org) => org.includes(text))
                  .map((org) => ({ value: org })),
              )
            }
          />
        </Form.Item>
      )}

      <Form.Item name="properties" noStyle />

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.properties !== currentValues.properties
        }
      >
        {({ getFieldValue, setFieldValue }) => (
          <DevicePropertiesForm
            value={getFieldValue('properties')}
            onChange={(properties) => setFieldValue('properties', properties)}
          />
        )}
      </Form.Item>

      <Typography.Title level={5}>Selektoren</Typography.Title>
      <Form.Item label="Labels">
        <DeviceLabelSelectors />
      </Form.Item>

      <Form.Item name="matchRole" label="Rollen">
        <SetMatchSelector
          options={device.map((role) => ({
            label: role,
            value: role,
          }))}
        />
      </Form.Item>

      <Form.Item
        label="Priorität"
        name="priority"
        rules={[
          { required: true, message: 'Geben Sie eine Priorität ein.' },
          {
            type: 'number',
            min: 0,
            max: 1000,
            message: 'Geben Sie eine Priorität zwischen 0 und 1000 ein.',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Button
        key="submit"
        htmlType="submit"
        type="primary"
        form={formId}
        loading={isLoading}
      >
        Speichern
      </Button>
    </Form>
  );
};

export default OrganizationDeviceConfigurationForm;

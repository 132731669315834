import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  message,
  Pagination,
  Select,
  Skeleton,
  Tooltip,
} from 'antd';
import Search from 'antd/es/input/Search';
import AdminDeviceActivationRequestInfo from 'components/admin/device-activation/AdminDeviceActivationRequestInfo';
import Breadcrumb from 'components/common/Breadcrumb';
import ListActionPicker from 'components/common/ListActionPicker';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import { FlexBox, FlexOne } from 'components/Helpers';
import useAdminDeviceActivationRequests, {
  SortOptions,
} from 'hooks/admin-device-activation-requests/useAdminDeviceActivationRequests';
import useAdminDeviceActivationRequestSelectionList from 'hooks/admin-device-activation-requests/useAdminDeviceActivationRequestSelectionList';
import useDataPagination from 'hooks/useDataPagination';
import _ from 'lodash';
import { useMemo, useState } from 'react';

const AdminDeviceActivationView: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState(SortOptions.APPROVED_AT_ASC);

  const { sortedRequests, refetch, isLoading, isRefetching } =
    useAdminDeviceActivationRequests(sorting);

  const jsonRequests: Map<string, string> = useMemo(() => {
    if (!sortedRequests) return new Map();
    const jsonMap = new Map();
    for (const request of sortedRequests) {
      jsonMap.set(request.id, JSON.stringify(request));
    }
    return jsonMap;
  }, [sortedRequests]);

  const filteredRequests = useMemo(() => {
    return (
      sortedRequests?.filter((request) => {
        const json = jsonRequests.get(request.id);
        if (!json) return false;
        return json.toLowerCase().includes(searchText.toLowerCase());
      }) ?? []
    );
  }, [searchText, sortedRequests, jsonRequests]);

  const reload = async () => {
    await refetch();
    message.success('Aktivierungsanfragen wurden aktualisiert');
  };

  const listActionManagerState = useAdminDeviceActivationRequestSelectionList(
    filteredRequests ?? [],
  );
  const { toggleItem, isSelected } = listActionManagerState;

  const {
    entries: paginatedRequests,
    setLimit,
    setPage,
    limit,
    page,
  } = useDataPagination(filteredRequests ?? []);

  return (
    <>
      <Breadcrumb
        items={[
          { name: 'Aktivierungsanfragen', href: '/admin/activation-requests' },
        ]}
      />
      <PageTitleBar
        title="Aktivierungsanfragen (deprecated)"
        Icon={ResourceIcons.ActivationRequest}
      />
      <PageContentContainer>
        {isLoading ? (
          <Skeleton active />
        ) : !sortedRequests ? (
          <Empty description="Es wurden keine Aktivierungsanfragen gefunden." />
        ) : (
          <FlexBox direction="column" withgap>
            <FlexBox>
              <FlexOne>
                <Search
                  placeholder="Suche"
                  onChange={_.debounce(
                    (e) => setSearchText(e.target.value),
                    500,
                  )}
                  style={{
                    maxWidth: '800px',
                  }}
                />
              </FlexOne>
              <Pagination
                onChange={(page, pageSize) => {
                  setPage(page - 1);
                  setLimit(pageSize);
                }}
                current={page + 1}
                total={filteredRequests.length}
                defaultPageSize={limit}
              />
            </FlexBox>
            <FlexBox withgap>
              <FlexOne>
                <ListActionPicker state={listActionManagerState} />
              </FlexOne>
              <div>
                <span>Sortieren: </span>
                <Select
                  style={{
                    width: '200px',
                  }}
                  options={[
                    {
                      label: 'Angenommen',
                      value: SortOptions.APPROVED_AT_ASC,
                    },
                    {
                      label: 'Neueste zuerst',
                      value: SortOptions.CREATED_AT_ASC,
                    },
                    {
                      label: 'Älteste zuerst',
                      value: SortOptions.CREATED_AT_DESC,
                    },
                  ]}
                  defaultValue={sorting}
                  onChange={(value) => setSorting(value)}
                />
              </div>
              <Tooltip title="Aktualisieren">
                <Button
                  icon={<ReloadOutlined />}
                  onClick={reload}
                  loading={isRefetching}
                />
              </Tooltip>
            </FlexBox>
            {paginatedRequests.map((activationRequest) => (
              <AdminDeviceActivationRequestInfo
                key={activationRequest.id}
                activationRequest={activationRequest}
                toggleSelect={toggleItem}
                isChecked={isSelected(activationRequest.id)}
              />
            ))}
            {paginatedRequests.length === 0 && (
              <Empty description="Es wurden keine Aktivierungsanfragen gefunden." />
            )}
          </FlexBox>
        )}
      </PageContentContainer>
    </>
  );
};

export default AdminDeviceActivationView;

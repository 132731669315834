import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal, Typography } from 'antd';
import { FlexBox } from 'components/Helpers';
import { DeviceProperty, useDeviceProperties } from 'defaults/deviceProperties';
import {
  Feature,
  features,
  getPresentFeatures,
} from 'defaults/featuresToDeviceProperties';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { isUserAdvancedAtom } from 'state/state';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { HoverItemInfo } from './HoverItemInfo';
import { HoverListItem } from './HoverListItem';

interface Props {
  open: boolean;
  onClose: () => void;
  currentDeviceProperties: Record<string, string>;
  onChange: (value: Record<string, string>) => void;
}

export enum HoverItemType {
  FEATURE = 'FEATURE',
  DEVICE_PROPERTY = 'DEVICE_PROPERTY',
}

export interface BaseHoverItem<T extends HoverItemType> {
  id: string;
  type: T;
}

export interface FeatureHoverItem extends BaseHoverItem<HoverItemType.FEATURE> {
  feature: Feature;
}

export interface DevicePropertyHoverItem
  extends BaseHoverItem<HoverItemType.DEVICE_PROPERTY> {
  deviceProperty: DeviceProperty;
}

export type HoverItem = FeatureHoverItem | DevicePropertyHoverItem;

const DevicePropertiesModal: React.FC<Props> = ({
  open,
  onClose,
  currentDeviceProperties,
  onChange,
}) => {
  const [hoveredItem, setHoveredItem] = useState<null | HoverItem>(null);
  const [searchText, setSearchText] = useState('');
  const deviceProperties = useDeviceProperties();

  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);

  const hoverItems: HoverItem[] = useMemo(() => {
    const hoverItems = features.map(
      (feature) =>
        ({
          id: uuidv4(),
          type: HoverItemType.FEATURE,
          feature,
        }) as FeatureHoverItem,
    );

    if (!isUserAdvanced) return hoverItems;

    return [
      ...hoverItems,
      ...deviceProperties.map(
        (deviceProperty) =>
          ({
            id: uuidv4(),
            type: HoverItemType.DEVICE_PROPERTY,
            deviceProperty,
          }) as DevicePropertyHoverItem,
      ),
    ];
  }, [deviceProperties, isUserAdvanced]);

  const filteredHoverItems = useMemo(() => {
    return hoverItems.filter((item) => {
      if (item.type === HoverItemType.FEATURE) {
        return item.feature.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      } else {
        return item.deviceProperty.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }
    });
  }, [hoverItems, searchText]);

  useEffect(() => {
    if (filteredHoverItems.length === 1) {
      setHoveredItem(filteredHoverItems[0]);
    } else {
      setHoveredItem(null);
    }
  }, [filteredHoverItems]);

  const presentFeatures = useMemo(() => {
    return getPresentFeatures(currentDeviceProperties ?? {});
  }, [currentDeviceProperties]);

  const addFeature = (featureId: string) => {
    const feature = features.find((feature) => feature.id === featureId);
    if (!feature) return;

    onChange?.({
      ...currentDeviceProperties,
      ...feature.initialValues,
    });
    onClose();
  };

  const onAdd = (item: HoverItem) => {
    if (item.type === HoverItemType.FEATURE) {
      if (presentFeatures.map((f) => f.id).includes(item.feature.id)) {
        message.error('Feature bereits hinzugefügt');
        return;
      }
      addFeature(item.feature.id);
    } else {
      if (
        Object.keys(currentDeviceProperties).includes(item.deviceProperty.name)
      ) {
        message.error('Device Property bereits hinzugefügt');
        return;
      }
      onChange({
        ...currentDeviceProperties,
        [item.deviceProperty.name]: item.deviceProperty.default
          ? String(item.deviceProperty.default)
          : '',
      });
      onClose();
    }
  };

  const addCustomDeviceProperty = (name: string) => {
    if (Object.keys(currentDeviceProperties).includes(name)) {
      message.error('Device Property bereits hinzugefügt');
      return;
    }
    onChange({
      ...currentDeviceProperties,
      [name]: '',
    });
    onClose();
  };

  return (
    <Modal open={open} width={1200} onCancel={() => onClose()} footer={null}>
      <FlexBox withgap gap={30}>
        <Column>
          <Input
            placeholder="Hier suchen..."
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus={true}
          />
          <SearchResults onMouseLeave={() => setHoveredItem(null)}>
            {filteredHoverItems.length === 0 && (
              <FlexBox direction="column" alignitems="flex-start" withgap>
                <Typography.Text type="secondary">
                  Keine Ergebnisse gefunden.
                </Typography.Text>
                <Button
                  onClick={() => {
                    if (searchText === '') return;
                    addCustomDeviceProperty(searchText.toUpperCase());
                  }}
                  icon={<PlusOutlined />}
                >
                  Property &quot;{searchText.toUpperCase()}&quot; hinzufügen
                </Button>
              </FlexBox>
            )}
            {filteredHoverItems.map((item) => (
              <HoverItemDiv
                key={item.id}
                onMouseEnter={() => setHoveredItem(item)}
              >
                <HoverListItem
                  item={item}
                  onAdd={onAdd}
                  deviceProperties={currentDeviceProperties}
                />
              </HoverItemDiv>
            ))}
          </SearchResults>
        </Column>
        <Column>{hoveredItem && <HoverItemInfo item={hoveredItem} />}</Column>
      </FlexBox>
    </Modal>
  );
};

export default DevicePropertiesModal;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SearchResults = styled.div`
  height: 500px;
  overflow-y: auto;
`;

const HoverItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 12px;
  transition: background-color 150ms ease-in-out;
  border-radius: 4px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

import ManagementDeviceActivationRequestInfo from 'components/admin/device-activation/ManagementDeviceActivationRequestInfo';
import useManagementDeviceActivationRequests from 'hooks/management-device-activation-requests/useManagementDeviceActivationRequests';
import { useMemo } from 'react';

interface Props {
  deviceId: string;
  shouldShowDeviceId?: boolean;
}
const ManagementActivationRequestInfoWrapper: React.FC<Props> = ({
  deviceId,
}) => {
  const { data, isLoading } = useManagementDeviceActivationRequests();

  const activationRequest = useMemo(() => {
    return data?.find((request) => request.deviceId === deviceId);
  }, [data, deviceId]);

  if (isLoading) return <>Loading...</>;
  if (!activationRequest) return <>No data</>;

  return (
    <ManagementDeviceActivationRequestInfo
      activationRequest={activationRequest}
    />
  );
};

export default ManagementActivationRequestInfoWrapper;

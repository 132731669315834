import styled from 'styled-components';
import { shouldForwardProp } from 'utlis/styledPropforwarding';

export const FlexBox = styled.div.withConfig({
  shouldForwardProp: shouldForwardProp([
    'alignItems',
    'direction',
    'justifyContent',
    'withgap',
    'gap',
    'wrap',
  ]),
})<{
  alignitems?: 'center' | 'strech' | 'flex-start' | 'flex-end' | 'baseline';
  direction?: 'column' | 'row';
  justifycontent?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'center'
    | 'flex-start'
    | 'flex-end';
  withgap?: boolean;
  gap?: number;
  wrap?: boolean;
}>`
  display: flex;
  align-items: ${({ alignitems: alignItems }) => alignItems || 'initial'};
  justify-content: ${({ justifycontent: justifyContent }) =>
    justifyContent || 'initial'};
  gap: ${({ withgap: withGap, gap, theme }) =>
    withGap ? (gap || theme.antd.sizeXS) + 'px' : 'initial'};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'initial')};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
`;

export const FlexOne = styled.div`
  flex: 1;
`;

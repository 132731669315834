import { MobileOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createRoute } from '@tanstack/react-router';
import {
  Alert,
  Button,
  Card,
  message,
  Skeleton,
  Steps,
  Typography,
} from 'antd';
import { useUserApi } from 'api/useUserApi';
import { FlexBox } from 'components/Helpers';
import { useEffect } from 'react';
import { MdMarkEmailRead } from 'react-icons/md';
import { TbDeviceMobileCheck } from 'react-icons/tb';

import { rootRoute } from 'router';
import { styled } from 'styled-components';
import getErrorMessage from 'utlis/getErrorMessage';

interface SearchParams {
  token: string | undefined;
}
export const emailVerificationRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'user/device-activation-request/$id/verify-email',
  component: EmailVerificationView,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      token: (search.token as string) || undefined,
    };
  },
});

function EmailVerificationView() {
  const queryClient = useQueryClient();
  const { getDeviceActivationRequest, postDeviceActivationRequestVerifyEmail } =
    useUserApi();
  const { id } = emailVerificationRoute.useParams();
  const { token } = emailVerificationRoute.useSearch();

  useEffect(() => {
    document.body.style.backgroundColor = '#33bbff';
  }, []);

  const { data: verificationRequest, isLoading } = useQuery({
    queryKey: ['email-verification', id, token],
    queryFn: () => {
      if (!token) return Promise.reject('id is not defined');
      if (!id) return Promise.reject('id is not defined');
      return getDeviceActivationRequest(id, token);
    },
  });

  const {
    mutate: verifyEmail,
    isPending: isVerifyingEmail,
    error: verifyEmailError,
  } = useMutation({
    mutationFn: async () => {
      if (!id) throw new Error('ID is not defined');
      if (!token) throw new Error('Token is not defined');
      await postDeviceActivationRequestVerifyEmail(id, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['email-verification', id, token],
      });
    },
    onError: () => {
      message.error('E-Mail Adresse konnte nicht bestätigt werden');
    },
  });

  // this property is currently not exposed in the API
  const isApproved = false;

  if (isLoading)
    return (
      <Center>
        <StyledCard>
          <Skeleton active />
        </StyledCard>
      </Center>
    );
  if (!id || !token || !verificationRequest)
    return (
      <Center>
        <StyledCard>Wir konnten deine Anfrage nicht finden.</StyledCard>
      </Center>
    );

  return (
    <Center>
      <StyledCard>
        <FlexBox direction="column" withgap alignitems="center">
          <img
            src={'/voize-logo-with-name-export-2951x996.png'}
            alt="voize logo"
            style={{
              width: '125px',
            }}
          />

          {verificationRequest.userEmailVerifiedAt !== null ? (
            <>
              {!isApproved ? (
                <FlexBox alignitems="center" justifycontent="center" withgap>
                  <Icon>
                    <MdMarkEmailRead
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </Icon>
                  <Typography.Title level={2}>
                    Deine E-Mail Adresse wurde bestätigt
                  </Typography.Title>
                </FlexBox>
              ) : (
                <FlexBox alignitems="center" justifycontent="center" withgap>
                  <Icon>
                    <TbDeviceMobileCheck
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </Icon>
                  <Typography.Title level={2}>
                    Dein Gerät wurde freigeschaltet
                  </Typography.Title>
                </FlexBox>
              )}
              <FlexBox
                alignitems="center"
                justifycontent="center"
                direction="column"
              >
                <Typography.Text type="secondary">
                  Vielen Dank, dass du deine E-Mai Adresse{' '}
                  {verificationRequest.userEmail} bestätigt hast.
                </Typography.Text>
                {!isApproved ? (
                  <Typography.Text type="secondary">
                    Wir verarbeiten deine Anfrage nun und werden dich per E-Mail
                    benachrichtigen, sobald dein Gerät freigeschaltet wurde.
                    Dies kann bis zu mehreren Tagen dauern.
                  </Typography.Text>
                ) : (
                  <Typography.Text type="secondary">
                    Dein Gerät wurde freigeschaltet. Du kannst nun die voize App
                    benutzen.
                  </Typography.Text>
                )}
              </FlexBox>
              <FlexBox
                alignitems="center"
                justifycontent="center"
                direction="column"
                withgap
              >
                <Typography.Title level={4}>
                  Wie geht es weiter?
                </Typography.Title>
                <Steps
                  direction="vertical"
                  current={!isApproved ? 2 : 3}
                  items={[
                    {
                      title: 'Registrierung auf dem Gerät',
                      description: 'Das hast du schon gemacht. Super!',
                    },
                    {
                      title: 'E-Mail Adresse bestätigen',
                      description:
                        'Du hast deine E-Mail Adresse erfolgreich bestätigt. Danke!',
                    },
                    ...(!isApproved
                      ? [
                          {
                            icon: <MobileOutlined />,
                            title: (
                              <>
                                Ein{' '}
                                <Typography.Text strong>
                                  voize Admin
                                </Typography.Text>{' '}
                                aktiviert dein Gerät
                              </>
                            ),
                            description: (
                              <>
                                Das kann ein paar Tage dauern. Bei Fragen wende
                                dich an{' '}
                                <a href="mailto: support@voize.de">
                                  support@voize.de
                                </a>
                              </>
                            ),
                          },
                        ]
                      : [
                          {
                            title: 'Gerät aktiviert',
                            description: 'Dein Gerät wurde freigeschaltet.',
                          },
                          {
                            icon: <MobileOutlined />,
                            title: 'App kann benutzt werden',
                            description: (
                              <>
                                Du kannst nun die voize App benutzten. Bei
                                Fragen wende dich an{' '}
                                <a href="mailto: support@voize.de">
                                  support@voize.de
                                </a>
                              </>
                            ),
                          },
                        ]),
                  ]}
                />
              </FlexBox>
            </>
          ) : (
            <>
              <FlexBox direction="column" withgap gap={2}>
                <FlexBox
                  direction="column"
                  alignitems="center"
                  justifycontent="center"
                >
                  <Typography.Title level={2}>
                    Willkommen {verificationRequest.userName}
                  </Typography.Title>
                </FlexBox>
                <FlexBox alignitems="center" justifycontent="center">
                  <Typography.Text type="secondary">
                    {verificationRequest.userEmail}
                  </Typography.Text>
                </FlexBox>
                <FlexBox alignitems="center" justifycontent="center" withgap>
                  <Typography.Paragraph>
                    Du hast die Freischaltung der{' '}
                    <Typography.Text strong>voize App</Typography.Text> auf
                    deinem Handy angefragt. Um diese Anfrage abzuschicken musst
                    du deine E-Mail bestätigen.
                  </Typography.Paragraph>
                </FlexBox>
              </FlexBox>
            </>
          )}

          {!verificationRequest.userEmailVerifiedAt && (
            <>
              <VoizeButton
                size="large"
                onClick={() => verifyEmail()}
                loading={isVerifyingEmail}
                type="primary"
              >
                E-Mail Adresse bestätigen
              </VoizeButton>
              {!!verifyEmailError && (
                <Alert
                  type="error"
                  message={<>{getErrorMessage(verifyEmailError)}</>}
                />
              )}
            </>
          )}
          {!verificationRequest.userEmailVerifiedAt && (
            <Typography.Text>
              Falls du nicht die{' '}
              <Typography.Text strong>voize App</Typography.Text> benutzt hast,
              ignoriere diese E-Mail.
            </Typography.Text>
          )}
        </FlexBox>
      </StyledCard>
    </Center>
  );
}

export default EmailVerificationView;

const Icon = styled.div`
  @media (min-width: 640px) {
    width: 50px;
    height: 50px;
  }
  width: 100px;
  height: 100px;
`;

const Center = styled.div`
  @media (min-width: 900px) {
    padding-top: 15vh;
    align-items: baseline;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background: #33bbff;
  text-align: center;
`;

const StyledCard = styled(Card)`
  @media (min-width: 900px) {
    width: 800px;
  }

  margin: 20px;
`;

const VoizeButton = styled(Button)`
  background-color: #33bbff;
`;

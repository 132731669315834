import { Button, Checkbox, Dropdown, message, Modal, Select } from 'antd';
import { FlexBox } from 'components/Helpers';
import {
  EntryWithId,
  ListActionManagerState,
} from 'hooks/useListActionManager';
import { useMemo, useState } from 'react';

interface Props<T extends EntryWithId> {
  state: ListActionManagerState<T>;
}

const ListActionPicker = <T extends EntryWithId>({ state }: Props<T>) => {
  const [selectedActionKey, setSelectedActionKey] = useState<null | string>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    toggleAll,
    allSelected,
    anySelected,
    actions,
    selectedItems,
    selectors,
    onSelector,
  } = state;

  const selectedAction = useMemo(
    () => actions.find((action) => action.key === selectedActionKey) ?? null,
    [actions, selectedActionKey],
  );

  const onSelect = (key: string) => {
    if (!anySelected) {
      message.error(
        'Es wurden keine Elemente ausgewählt. Bitte wählen Sie mindestens ein Element aus.',
      );
      return;
    }
    setSelectedActionKey(key);
  };

  return (
    <FlexBox style={{}} withgap alignitems="center" justifycontent="flex-start">
      <Modal
        open={selectedActionKey !== null}
        onCancel={() => setSelectedActionKey(null)}
        okText="Ja"
        cancelText="Nein"
        confirmLoading={isLoading}
        onOk={async () => {
          setIsLoading(true);
          try {
            await selectedAction?.action(selectedItems);
          } finally {
            setSelectedActionKey(null);
            setIsLoading(false);
          }
        }}
      >
        {selectedAction && selectedAction.renderConfirmText?.(selectedItems)}
      </Modal>

      <Dropdown.Button
        menu={{
          items: selectors.map((selector) => ({
            key: selector.key,
            label: selector.label,
            onClick: () => onSelector(selector),
            icon: selector.icon,
          })),
        }}
        style={{ width: 'auto' }}
      >
        <Checkbox
          onChange={toggleAll}
          checked={allSelected}
          indeterminate={anySelected && !allSelected}
        />
      </Dropdown.Button>

      {actions.length > 1 ? (
        <Select
          style={{
            width: '200px',
          }}
          placeholder="Aktion auswählen"
          options={actions.map((action) => ({
            label: action.label,
            value: action.key,
          }))}
          onSelect={onSelect}
        />
      ) : (
        actions.length > 0 && (
          <Button onClick={() => onSelect(actions[0].key)}>
            {actions[0].label}
          </Button>
        )
      )}
    </FlexBox>
  );
};

export default ListActionPicker;

import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import React from 'react';

interface Props {
  items: {
    name: string;
    href: string;
  }[];
}

const Breadcrumb: React.FC<Props> = ({ items }) => {
  return (
    <AntdBreadcrumb
      style={{
        padding: '30px 30px 0',
        marginBottom: 18,
      }}
      items={[
        {
          href: '/',
          title: <HomeOutlined />,
        },
        ...items.map((item) => ({
          href: item.href,
          title: item.name,
        })),
      ]}
    />
  );
};

export default Breadcrumb;

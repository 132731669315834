import { HoverItem, HoverItemType } from './DevicePropertiesModal';
import DevicePropertyInfo from './DevicePropertyInfo';
import FeatureInfo from './FeatureInfo';

export const HoverItemInfo = ({ item }: { item: HoverItem }) => {
  switch (item.type) {
    case HoverItemType.FEATURE:
      return <FeatureInfo feature={item.feature} />;
    case HoverItemType.DEVICE_PROPERTY:
      return <DevicePropertyInfo deviceProperty={item.deviceProperty} />;
  }
};

import { useCallback, useMemo, useState } from 'react';

export interface EntryWithId {
  id: string;
}

export interface ListActionManagerState<T extends EntryWithId> {
  selectedItems: T[];
  ids: string[];
  setSelectedIds: (ids: string[]) => void;
  toggleItem: (id: string) => void;
  isSelected: (id: string) => boolean;
  toggleAll: () => void;
  allSelected: boolean;
  anySelected: boolean;
  actions: ListAction<T>[];
  selectors: Selector<T>[];
  onSelector: (selector: Selector<T>) => void;
}

export interface ListAction<T> {
  key: string;
  label: string;
  action: (items: T[]) => void | Promise<any>;
  renderConfirmText?: (items: T[]) => React.ReactNode;
  icon?: string;
}

export interface Selector<T extends EntryWithId> {
  key: string;
  label: string;
  icon?: string;
  onClick: (items: T[]) => T[];
}

const useListActionManager = <T extends EntryWithId>(
  allItems: T[],
  actions: ListAction<T>[],
  selectors: Selector<T>[],
): ListActionManagerState<T> => {
  const [ids, setIds] = useState<string[]>([]);

  const selectedItems = useMemo(() => {
    return ids.map((id) => {
      const item = allItems.find((item) => item.id === id);
      if (!item) {
        throw new Error(`Item with id ${id} not found`);
      }
      return item;
    });
  }, [ids, allItems]);

  const toggleItem = useCallback(
    (id: string) => {
      if (allItems.find((item) => item.id === id) === undefined) {
        throw new Error(`Item with id ${id} not found`);
      }

      if (ids.includes(id)) {
        setIds(ids.filter((i) => i !== id));
      } else {
        setIds([...ids, id]);
      }
    },
    [ids, allItems],
  );

  const isSelected = useCallback(
    (id: string) => {
      return ids.includes(id);
    },
    [ids],
  );

  const toggleAll = useCallback(() => {
    if (ids.length === allItems.length) {
      setIds([]);
    } else {
      setIds(allItems.map((item) => item.id));
    }
  }, [ids, allItems]);

  const allSelected = useMemo(
    () => ids.length === allItems.length,
    [ids, allItems],
  );

  const anySelected = useMemo(() => ids.length > 0, [ids]);

  const onSelector = useCallback(
    (selector: Selector<T>) => {
      setIds(selector.onClick(allItems).map((item) => item.id));
    },
    [allItems],
  );

  return {
    selectedItems,
    ids,
    setSelectedIds: setIds,
    toggleItem,
    isSelected,
    toggleAll,
    allSelected,
    anySelected,
    actions,
    selectors,
    onSelector,
  };
};

export default useListActionManager;

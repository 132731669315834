import { FeatureRenderProps } from '../../../../defaults/featuresToDeviceProperties';
import TICIntegrationConfigForm from './TICIntegrationConfigForm';
import TICIntegrationConfigSummary from './TICIntegrationConfigSummary';

const TICIntegrationConfigFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
  onEditDone,
}) => {
  if (isEditing) {
    return (
      <TICIntegrationConfigForm
        initialProperties={values}
        onSubmit={(config: Record<string, string>) => {
          onUpdate(config);
          onEditDone?.();
        }}
      />
    );
  }

  return <TICIntegrationConfigSummary properties={values} />;
};

export default TICIntegrationConfigFeature;

import { FormInstance, useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';

const useAntdIsFormSubmittable = (form: FormInstance) => {
  const [isFormSubmittable, setIsFormSubmittable] = useState(false);

  const formValues = useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setIsFormSubmittable(true);
      })
      .catch(() => {
        setIsFormSubmittable(false);
      });
  }, [form, formValues]);

  return isFormSubmittable;
};

export default useAntdIsFormSubmittable;

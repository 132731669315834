import { useMemo } from 'react';
import useOrganizations from './useOrganizations';

const useOrganizationIds = () => {
  const { organizations } = useOrganizations();

  const sortedOrganizations = useMemo(() => {
    if (!organizations) {
      return [];
    }

    return organizations
      .map((organization) => organization.id)
      .sort((a, b) => a.localeCompare(b));
  }, [organizations]);

  return sortedOrganizations;
};

export default useOrganizationIds;

import { UndoOutlined } from '@ant-design/icons';
import { Button, Radio, Tooltip } from 'antd';
import { AuthMethod } from 'components/device-configuration/features/user-auth-manager-oidc/UserManagerOIDCConfigForm';

interface Props {
  onChange?: (value: AuthMethod | null) => void;
  value?: AuthMethod | null;
}
const AuthMethodPicker: React.FC<Props> = ({ onChange, value }) => {
  return (
    <>
      <Radio.Group onChange={(e) => onChange?.(e.target.value)} value={value}>
        <Radio value={AuthMethod.BASIC}>BASIC</Radio>
        <Radio value={AuthMethod.POST}>POST</Radio>
      </Radio.Group>
      <Tooltip title="Reset" mouseEnterDelay={0.7}>
        <Button onClick={() => onChange?.(null)} icon={<UndoOutlined />} />
      </Tooltip>
    </>
  );
};

export default AuthMethodPicker;

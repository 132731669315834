import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { message } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import OrganizationDeviceConfigurationForm from 'components/device-configuration/OrganizationDeviceConfigurationForm';

const AdminOrganizationDeviceConfigurationCreateView: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { postOrganizationDeviceConfiguration } = useAdminDeviceApi();

  const {
    mutate: createOrganizationDeviceConfiguration,
    isPending: isCreating,
    error: createError,
  } = useMutation({
    mutationFn: postOrganizationDeviceConfiguration,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['admin-organization-device-configurations'],
      });
      message.success('Organisation Device Configuration wurde erstellt.');
      navigate({
        to: '/admin/organization-device-configurations',
      });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Organisations-Gerätekonfigurationen',
            href: '/admin/organization-device-configurations',
          },
        ]}
      />
      <PageTitleBar
        title="Organisations-Gerätekonfiguration anlegen"
        Icon={ResourceIcons.OrganizationDeviceConfiguration}
      />
      <PageContentContainer>
        <OrganizationDeviceConfigurationForm
          onSubmit={createOrganizationDeviceConfiguration}
          isLoading={isCreating}
          error={createError}
          fixedOrganization={null}
        />
      </PageContentContainer>
    </>
  );
};

export default AdminOrganizationDeviceConfigurationCreateView;

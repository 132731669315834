import { ManagementDevice } from 'interfaces/device';
import { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { useMemo } from 'react';
import useManagementDeviceActivationRequests from './management-device-activation-requests/useManagementDeviceActivationRequests';
import useManagementDevices from './management-devices/useManagementDevices';

export enum CombinedActivationRequestAndDeviceType {
  DEVICE = 'device',
  ACTIVATION_REQUEST = 'activation_request',
}

interface BaseCombined<T extends CombinedActivationRequestAndDeviceType> {
  type: T;
  id: string; // for table
}

export interface ManagementDeviceWithActivationRequest
  extends BaseCombined<CombinedActivationRequestAndDeviceType.DEVICE> {
  device: ManagementDevice;
  activationRequest: ManagementDeviceActivationRequest | null;
}

export interface ManagementActivationRequest
  extends BaseCombined<CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST> {
  activationRequest: ManagementDeviceActivationRequest;
}

export type CombinedActivationRequestAndDevice =
  | ManagementDeviceWithActivationRequest
  | ManagementActivationRequest;

const useManagementCombinedActivationRequestsAndDevices = () => {
  const {
    data: activationRequests,
    isLoading: isLoadingActivationRequests,
    refetch: refetchActivationRequests,
  } = useManagementDeviceActivationRequests();

  const {
    devices,
    invalidateDevices,
    isLoading: isLoadingDevices,
    refetch: refetchDevices,
  } = useManagementDevices();

  const combined: CombinedActivationRequestAndDevice[] = useMemo(() => {
    if (!activationRequests) return [];

    const tempActivationRequests = [...activationRequests];

    const devicesWithActivationRequests =
      devices.map<ManagementDeviceWithActivationRequest>((device) => {
        const activationRequestIndex = tempActivationRequests.findIndex(
          (request) => request.deviceId === device.id,
        );

        const activationRequest =
          activationRequestIndex > -1
            ? tempActivationRequests[activationRequestIndex]
            : null;

        if (activationRequestIndex > -1) {
          tempActivationRequests.splice(activationRequestIndex, 1);
        }

        return {
          id: device.id,
          type: CombinedActivationRequestAndDeviceType.DEVICE,
          device,
          activationRequest,
        };
      });

    const restActivationRequests =
      tempActivationRequests.map<ManagementActivationRequest>(
        (activationRequest) => ({
          id: activationRequest.id,
          type: CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST,
          activationRequest,
        }),
      );

    return [...devicesWithActivationRequests, ...restActivationRequests];
  }, [activationRequests, devices]);

  return {
    data: combined,
    isLoading: isLoadingActivationRequests || isLoadingDevices,
    refetch: () => {
      refetchActivationRequests();
      refetchDevices();
    },
    invalidateDevices,
    devicesNumber: devices?.length ?? 0,
    activationRequestsNumber: activationRequests?.length ?? 0,
  };
};

export default useManagementCombinedActivationRequestsAndDevices;

import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import { seniorCareDeviceManagementResetDataConfigurationKey } from 'deviceMetadataKeys';
import { Operator } from 'interfaces/matchers';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Creates a device config with the device property "REMOTE_RESET_AT" set to the current time.
 * This device config is assigned to the device.
 * As soon as the device receives this config, it will reset itself.
 */
const useDeleteSeniorCareDeviceData = () => {
  const {
    postManagementDeviceConfiguration,
    patchDeviceLabels,
    updateDeviceProperties,
  } = useManagementApiFromContext();

  return useCallback(async (deviceId: string) => {
    const selectorValue = uuidv4();

    const deviceConfig = await postManagementDeviceConfiguration({
      name: `Reset senior care data of device ${deviceId}`,
      properties: {
        ['REMOTE_RESET_AT']: new Date().toISOString(),
      },
      priority: 10,
      deviceSelector: {
        matchLabels: [
          {
            key: seniorCareDeviceManagementResetDataConfigurationKey,
            operator: Operator.IN,
            values: [selectorValue],
          },
        ],
        matchRole: {
          in: [seniorCareAppDeviceRole],
        },
      },
    });

    await patchDeviceLabels(deviceId, {
      [seniorCareDeviceManagementResetDataConfigurationKey]: selectorValue,
    });
    await updateDeviceProperties(deviceId);
  }, []);
};

export default useDeleteSeniorCareDeviceData;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { message, Skeleton } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import Breadcrumb from 'components/common/Breadcrumb';
import ErrorAlert from 'components/common/ErrorAlert';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import OrganizationDeviceConfigurationForm from 'components/device-configuration/OrganizationDeviceConfigurationForm';
import { PostOrganizationDeviceConfiguration } from 'interfaces/adminOrganizationDeviceConfig';
import { adminOrganizationDeviceConfigurationEditRoute } from 'router';

const AdminOrganizationDeviceConfigurationCreateView: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { organizationDeviceConfigurationId } =
    adminOrganizationDeviceConfigurationEditRoute.useParams();

  const {
    putOrganizationDeviceConfiguration,
    getOrganizationDeviceConfiguration,
  } = useAdminDeviceApi();

  const { data: editConfig, isLoading } = useQuery({
    queryKey: [
      'admin-organization-device-configurations',
      organizationDeviceConfigurationId,
    ],
    queryFn: () =>
      getOrganizationDeviceConfiguration(organizationDeviceConfigurationId),
  });

  const {
    mutate: updateOrganizationDeviceConfiguration,
    isPending: isUpdating,
    error,
  } = useMutation({
    mutationFn: (dc: PostOrganizationDeviceConfiguration) =>
      putOrganizationDeviceConfiguration(organizationDeviceConfigurationId, dc),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['admin-organization-device-configurations'],
      });
      message.success('Organisation Device Configuration wurde bearbeitet.');
      navigate({
        to: '/admin/organization-device-configurations',
      });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Organisations-Gerätekonfigurationen',
            href: '/admin/organization-device-configurations',
          },
        ]}
      />
      <PageTitleBar
        title={editConfig?.name ?? '...'}
        Icon={ResourceIcons.OrganizationDeviceConfiguration}
      />
      <PageContentContainer>
        {isLoading ? (
          <Skeleton active />
        ) : !editConfig ? (
          <ErrorAlert
            errorCode="404"
            subtitle="Organisation Device Configuration nicht gefunden"
          />
        ) : (
          <OrganizationDeviceConfigurationForm
            onSubmit={updateOrganizationDeviceConfiguration}
            isLoading={isUpdating}
            error={error}
            initialValues={editConfig}
            fixedOrganization={editConfig.organization}
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default AdminOrganizationDeviceConfigurationCreateView;

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Button, Popconfirm, Skeleton, Switch, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Search from 'antd/lib/input/Search';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import AdminDeviceEditModal from 'components/admin/AdminDeviceEditModal';
import CreateDevicesFromCSVModal from 'components/admin/CreateDevicesFromCSVModal';
import SendDeviceMailModal from 'components/admin/SendDeviceMailModal';
import ActivationRequestEmail from 'components/common/ActivationRequestEmail';
import ErrorAlert from 'components/common/ErrorAlert';
import IdTag from 'components/common/IdTag';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import AdminDeviceActivationActions from 'components/device-activation/AdminDeviceActivationActions';
import { FlexBox, FlexOne } from 'components/Helpers';
import LabelList from 'components/views/LabelList';
import device from 'defaults/deviceRoles';
import useOrganizationIds from 'hooks/organizations/useOrganizationIds';
import useCombinedActivationRequestsAndDevices, {
  CombinedActivationRequestAndDevice,
  CombinedActivationRequestAndDeviceType,
} from 'hooks/useCombinedActivationRequestsAndDevices';
import useJsonSearch from 'hooks/useJsonSearch';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import { deviceCreatedAtLabel, deviceDisplayNameLabel } from 'labels';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UnreachableCaseError } from 'ts-essentials';
import AdminDeviceActivationCodeModal from '../AdminDeviceActivationCodeModal';
import AdminDeviceCreateModal from '../AdminDeviceCreateModal';
import AdminDeviceActivationAlert from '../device-activation/AdminDeviceActivationAlert';
import AdminDeviceNameEditable from './AdminDeviceNameEditable';

const AdminDevicesList: React.FC = () => {
  const organizations = useOrganizationIds();

  const { resetDeviceSecret, deleteDevice } = useAdminDeviceApiV4();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createFromCSVModalOpen, setCreateFromCSVModalOpen] = useState(false);
  const [createdDeviceId, setCreatedDeviceId] = useState<null | string>(null);
  const [editDevice, setEditDevice] = useState<null | AdminDeviceV4Dto>(null);
  const [mailModalDevice, setMailModalDevice] =
    useState<null | AdminDeviceV4Dto>(null);
  const [showLabels, setShowLabels] = useState(false);

  const {
    data: combined,
    refetch,
    isLoading,
    invalidateDevices,
  } = useCombinedActivationRequestsAndDevices();

  const [currentDataSource, setCurrentDataSource] =
    useState<CombinedActivationRequestAndDevice[]>(combined);

  useEffect(() => {
    setCurrentDataSource(combined);
  }, [combined]);

  const currentDevicesCount = useMemo(
    () =>
      currentDataSource.filter(
        (d) => d.type === CombinedActivationRequestAndDeviceType.DEVICE,
      ).length,
    [currentDataSource],
  );
  const currentActivationRequestsCount = useMemo(
    () => currentDataSource.filter((d) => d.activationRequest !== null).length,
    [currentDataSource],
  );

  const { filteredItems, setSearchText } = useJsonSearch(combined);

  const onEdit = async () => {
    setEditDevice(null);
    refetch();
  };

  const onDelete = useCallback(
    async (device: AdminDeviceV4Dto) => {
      await deleteDevice(device.id);
      invalidateDevices();
    },
    [deleteDevice, invalidateDevices],
  );

  const getColumnSearchProps =
    useTableSearchFilter<CombinedActivationRequestAndDevice>();

  if (isLoading) return <Skeleton active />;

  if (!combined)
    return <ErrorAlert subtitle="Irgendetwas ist schief gelaufen" />;

  const columns: ColumnsType<CombinedActivationRequestAndDevice> = [
    {
      title: 'Device ID',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        return a.device.id.localeCompare(b.device.id);
      },
      ...getColumnSearchProps(
        'device id',
        'device_id',
        (record) => {
          switch (record.type) {
            case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
              return null;
            case CombinedActivationRequestAndDeviceType.DEVICE:
              return record.device.id;
            default:
              throw new UnreachableCaseError(record);
          }
        },
        (record, _, searchText) => {
          const deviceId = (() => {
            switch (record.type) {
              case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
                return record.activationRequest.deviceId;
              case CombinedActivationRequestAndDeviceType.DEVICE:
                return record.device.id;
              default:
                throw new UnreachableCaseError(record);
            }
          })();

          if (deviceId === null) return 'Noch nicht aktiviert';

          const organization = (() => {
            switch (record.type) {
              case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
                return record.activationRequest.organization;
              case CombinedActivationRequestAndDeviceType.DEVICE:
                return record.device.organization;
              default:
                throw new UnreachableCaseError(record);
            }
          })();

          if (organization === null) {
            return (
              <IdTag id={deviceId} isCollapsed={true} highlight={searchText} />
            );
          }

          return (
            <Link
              to="/management/$organization/devices/$deviceId"
              params={{
                organization,
                deviceId,
              }}
            >
              <IdTag id={deviceId} isCollapsed={true} highlight={searchText} />
            </Link>
          );
        },
      ),
    },
    {
      title: 'Organization',
      sorter: (a, b) => {
        if (!a.organization) return -1;
        if (!b.organization) return 1;
        return a.organization.localeCompare(b.organization);
      },
      filters: [
        {
          text: 'Keine',
          value: 'no-org',
        },
        ...organizations.map((organization) => ({
          text: organization,
          value: organization,
        })),
      ],
      filterSearch: true,
      onFilter: (value, record) => {
        if (value === 'no-org') return !record.organization;

        return record.organization === value;
      },
      render: (_, { organization }) => {
        if (!organization) return null;
        return (
          <Link
            to="/admin/organizations/$organizationId"
            params={{ organizationId: organization }}
          >
            {organization}
          </Link>
        );
      },
    },
    {
      title: 'Name',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return null;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return (
              <AdminDeviceNameEditable
                device={record.device}
                key={record.device.id}
              />
            );
          default:
            throw new UnreachableCaseError(record);
        }
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;

        return (
          a.device.labels[deviceDisplayNameLabel]?.localeCompare(
            b.device.labels[deviceDisplayNameLabel],
          ) ?? 0
        );
      },
    },
    {
      title: 'Aktivierungsanfrage',
      filters: [
        {
          text: 'Aktiviert',
          value: 'activated',
        },
        {
          text: 'Nicht aktiviert',
          value: 'notActivated',
        },
        {
          text: 'Nicht existent',
          value: 'notExistent',
        },
      ],
      sorter: (a, b) => {
        if (!a.activationRequest?.approvedAt) return -1;
        if (!b.activationRequest?.approvedAt) return 1;
        return (
          a.activationRequest.approvedAt.localeCompare(
            b.activationRequest.approvedAt,
          ) ?? 0
        );
      },
      onFilter: (value, record) => {
        if (value === 'activated') {
          return !!record.activationRequest?.approvedAt;
        }
        if (value === 'notActivated') {
          return record.activationRequest?.approvedAt === null;
        }
        if (value === 'notExistent') {
          return record.activationRequest === null;
        }
        return false;
      },
      render: (_, { activationRequest }) => {
        if (!activationRequest)
          return (
            <Tooltip
              title="Es existiert keine Aktivierungsanfrage"
              mouseEnterDelay={0.7}
            >
              <CloseOutlined />
            </Tooltip>
          );
        return (
          <FlexBox withgap direction="column">
            <FlexBox withgap>
              <AdminDeviceActivationActions
                activationRequest={activationRequest}
                onDeleted={() => {
                  refetch();
                }}
              />
            </FlexBox>
            {!activationRequest.approvedAt &&
            activationRequest.userEmailVerifiedAt ? (
              <ListItemInfoTag
                label="E-Mail Adresse bestätigt"
                valueStyle={{
                  backgroundColor: activationRequest.userEmailVerifiedAt
                    ? 'hsla(90, 100%, 72%, 1)'
                    : 'hsla(0, 100%, 72%, 1)',
                }}
              >
                <RelativeDateTimeWithTooltip
                  timestamp={activationRequest.userEmailVerifiedAt}
                  noneText="Noch nicht bestätigt"
                />
              </ListItemInfoTag>
            ) : null}
          </FlexBox>
        );
      },
    },
    {
      title: 'Akt.Anfr. erstellt am',
      ...getColumnSearchProps(
        'request date',
        'activation_request_date',
        (record) => {
          if (!record.activationRequest) return null;
          return record.activationRequest.createdAt;
        },
        (record) => {
          if (!record.activationRequest) return null;
          return (
            <RelativeDateTimeWithTooltip
              timestamp={record.activationRequest.createdAt}
              noneText="Noch nie"
            />
          );
        },
      ),
      sorter: (a, b) => {
        if (!a.activationRequest) return -1;
        if (!b.activationRequest) return 1;
        return (
          a.activationRequest.createdAt.localeCompare(
            b.activationRequest.createdAt,
          ) ?? 0
        );
      },
    },
    {
      title: 'Nutzer*in',
      ...getColumnSearchProps('user name', 'user_name', (record) => {
        if (!record.activationRequest) return null;
        return record.activationRequest.userName;
      }),
    },
    {
      title: 'E-Mail Adresse',
      ...getColumnSearchProps(
        'email',
        'user_email',
        (record) => {
          if (!record.activationRequest) return null;
          return record.activationRequest.userEmail;
        },
        (record, _, searchText) => {
          if (!record.activationRequest) return null;
          return (
            <ActivationRequestEmail
              activationRequest={record.activationRequest}
              searchWords={[searchText]}
            />
          );
        },
      ),
    },
    {
      title: 'Role',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return null;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return record.device.role;
          default:
            throw new UnreachableCaseError(record);
        }
      },
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        return a.device.role?.localeCompare(b.device.role ?? '') ?? 0;
      },
      filters: device.map((role) => ({ text: role, value: role })),
      filterSearch: true,
      onFilter: (value, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return false;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return record.device.role === value;
          default:
            throw new UnreachableCaseError(record);
        }
      },
    },
    {
      title: 'Gerät erstellt am',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return 'Noch nie';
          case CombinedActivationRequestAndDeviceType.DEVICE: {
            const createdAt =
              record.device.labels[deviceCreatedAtLabel] ?? null;

            return createdAt
              ? DateTime.fromISO(
                  record.device.labels[deviceCreatedAtLabel],
                ).toLocaleString(DateTime.DATETIME_SHORT)
              : 'Noch nie';
          }
          default:
            throw new UnreachableCaseError(record);
        }
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        return (
          a.device.labels[deviceCreatedAtLabel]?.localeCompare(
            b.device.labels[deviceCreatedAtLabel],
          ) ?? 0
        );
      },
      responsive: ['xxl'],
    },
    {
      title: 'Zuletzt gesehen',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return 'Noch nie';
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return (
              <RelativeDateTimeWithTooltip
                timestamp={record.device.lastSeenAt}
                noneText="Noch nie"
              />
            );
          default:
            throw new UnreachableCaseError(record);
        }
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        return (
          a.device.lastSeenAt?.localeCompare(b.device.lastSeenAt ?? '') ?? 0
        );
      },
      responsive: ['xxl'],
    },
    {
      title: 'Labels',
      hidden: !showLabels,
      ...getColumnSearchProps(
        'device labels',
        'device_labels',
        (record) => {
          switch (record.type) {
            case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
              return null;
            case CombinedActivationRequestAndDeviceType.DEVICE:
              return JSON.stringify(record.device.labels);
            default:
              throw new UnreachableCaseError(record);
          }
        },
        (record, _, searchText) => {
          switch (record.type) {
            case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
              return null;
            case CombinedActivationRequestAndDeviceType.DEVICE:
              return (
                <LabelList
                  labels={record.device.labels}
                  highlightTexts={searchText ? [searchText] : []}
                  containerStyle={{
                    maxWidth: '350px',
                    overflowX: 'auto',
                  }}
                />
              );
            default:
              throw new UnreachableCaseError(record);
          }
        },
      ),
    },
    {
      title: 'Actions',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return null;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return (
              <FlexBox
                withgap
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                  e.stopPropagation()
                }
              >
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setEditDevice(record.device)}
                />
                <Button
                  icon={<QrcodeOutlined />}
                  onClick={() => setCreatedDeviceId(record.device.id)}
                />
                <Button
                  icon={<MailOutlined />}
                  onClick={() => setMailModalDevice(record.device)}
                />
                <Popconfirm
                  title="You will unregister the device if is already registered. Are you sure?"
                  onConfirm={() => resetDeviceSecret(record.device.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>Reset</Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure you want to delete this Device?"
                  onConfirm={() => onDelete(record.device)}
                  okText="Yes"
                >
                  <Button danger icon={<DeleteOutlined />} />
                </Popconfirm>
              </FlexBox>
            );
          default:
            throw new UnreachableCaseError(record);
        }
      },
    },
  ];

  return (
    <FlexBox withgap direction="column" style={{ minHeight: '100%' }}>
      <AdminDeviceActivationAlert />

      <AdminDeviceCreateModal
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreated={(device: AdminDeviceV4Dto) => {
          invalidateDevices();
          setCreatedDeviceId(device.id);
          setCreateModalOpen(false);
        }}
      />
      <CreateDevicesFromCSVModal
        isOpen={createFromCSVModalOpen}
        onClose={() => setCreateFromCSVModalOpen(false)}
        onCreated={() => {
          invalidateDevices();
          setCreateModalOpen(false);
        }}
      />
      {mailModalDevice && (
        <SendDeviceMailModal
          isOpen={true}
          device={mailModalDevice}
          onClose={() => setMailModalDevice(null)}
        />
      )}
      {editDevice !== null && (
        <AdminDeviceEditModal
          device={editDevice}
          onEdit={onEdit}
          onClose={() => setEditDevice(null)}
        />
      )}
      {createdDeviceId && (
        <AdminDeviceActivationCodeModal
          deviceId={createdDeviceId}
          onClose={() => setCreatedDeviceId(null)}
        />
      )}
      <FlexBox withgap alignitems="center">
        <FlexOne>
          <b>{currentDataSource.length}</b> Elemente in Tabelle, davon haben{' '}
          <b>{currentDevicesCount}</b> Geräte und{' '}
          <b>{currentActivationRequestsCount}</b> Aktivierungsanfragen
        </FlexOne>
        <Button onClick={() => setCreateFromCSVModalOpen(true)}>
          Geräte aus CSV importieren
        </Button>
        <Button onClick={() => setCreateModalOpen(true)}>
          Gerät hinzufügen
        </Button>
        Labels anzeigen <Switch checked={showLabels} onChange={setShowLabels} />
      </FlexBox>

      <Search
        placeholder="Schnellsuche"
        onChange={_.debounce((e) => setSearchText(e.target.value), 500)}
        style={{
          maxWidth: '800px',
        }}
      />

      <Table
        style={{ marginTop: 5 }}
        size="small"
        dataSource={filteredItems}
        columns={columns}
        pagination={{
          defaultPageSize: 100,
          position: ['bottomRight', 'topRight'],
          pageSizeOptions: ['20', '50', '100', '500', '1000', '5000', '500000'],
        }}
        onChange={(pagination, filters, sorter, extra) => {
          setCurrentDataSource(extra.currentDataSource);
        }}
      />
    </FlexBox>
  );
};

export default AdminDevicesList;

import { TableRowSelection } from 'antd/es/table/interface';
import { useState } from 'react';

const useAntdTableRowSelection = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  return {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  } satisfies TableRowSelection;
};

export default useAntdTableRowSelection;

interface SeniorCareLastUsedDeviceConfigState {
  lastUsedIntegrationConfigSelector: Record<string, string> | null;
  lastUsedDeviceConfigSelector: Record<string, string> | null;
}

import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const seniorCareLastUsedDeviceConfigAtom =
  atomWithStorage<SeniorCareLastUsedDeviceConfigState>(
    'SeniorCareLastUsedDeviceConfigState',
    {
      lastUsedIntegrationConfigSelector: null,
      lastUsedDeviceConfigSelector: null,
    },
  );

export const useSeniorCareLastUsedDeviceConfigs = (organization: string) => {
  const [state, setState] = useAtom(seniorCareLastUsedDeviceConfigAtom);

  const setLastUsedIntegrationConfigSelector = (id: string) => {
    setState((prev) => ({
      ...prev,
      lastUsedIntegrationConfigSelector: {
        ...prev.lastUsedIntegrationConfigSelector,
        [organization]: id,
      },
    }));
  };

  const setLastUsedDeviceConfigSelector = (id: string) => {
    setState((prev) => ({
      ...prev,
      lastUsedDeviceConfigSelector: {
        ...prev.lastUsedDeviceConfigSelector,
        [organization]: id,
      },
    }));
  };

  return {
    setLastUsedIntegrationConfigSelector,
    setLastUsedDeviceConfigSelector,
    lastUsedIntegrationConfigSelector:
      state.lastUsedIntegrationConfigSelector?.[organization],
    lastUsedDeviceConfigSelector:
      state.lastUsedDeviceConfigSelector?.[organization],
  };
};

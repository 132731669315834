import { atomWithStorage } from 'jotai/utils';

export const isUserAdvancedAtom = atomWithStorage('isUserAdvanced', false);

export const shouldAnonymiseTelemetryAtom = atomWithStorage(
  'shouldAnonymiseTelemetry',
  false,
);

export const isSidebarCollapsedAtom = atomWithStorage(
  'isSidebarCollapsed',
  false,
);

import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { FlexBox } from 'components/Helpers';
import { FiLogOut } from 'react-icons/fi';
import styled from 'styled-components';

const NoPermissionsView: React.FC = () => {
  const { user, logout } = useAuth0();

  return (
    <ErrorAlertWrapper>
      <ErrorAlertTitle>
        Sie haben nicht die nötigen Berechtigungen um das voize Admin Tool zu
        benutzten.
      </ErrorAlertTitle>
      <FlexBox
        alignitems="center"
        withgap
        style={{
          padding: '0 10px',
          marginTop: '20px',
        }}
        justifycontent="space-between"
      >
        <FlexBox withgap gap={9} alignitems="center">
          <UserName>{user?.name}</UserName>
        </FlexBox>
        <Button
          type="text"
          icon={<FiLogOut />}
          style={{ color: 'hsl(200, 10%, 50%)', fontSize: '26px' }}
          onClick={() =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            })
          }
          size="large"
        >
          Logout
        </Button>
      </FlexBox>
    </ErrorAlertWrapper>
  );
};

const ErrorAlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 100px;
`;

const ErrorAlertTitle = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin: 0;
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 30px;
`;

export default NoPermissionsView;

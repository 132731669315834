import { CopyOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, message, Tooltip } from 'antd';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import {
  deviceConfigurationKey,
  seniorCareDeviceManagementIntegrationConfigurationKey,
} from 'deviceMetadataKeys';
import { useManagementDeviceConfigurationsInvalidation } from 'hooks/useManagementDeviceConfigurations';
import { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import { MatchExpression, Operator } from 'interfaces/matchers';
import getErrorMessage from 'utlis/getErrorMessage';
import { v4 as uuid } from 'uuid';

const DuplicateManagementDeviceConfigurationButton = ({
  managementDeviceConfiguration,
}: {
  managementDeviceConfiguration: ManagementDeviceConfiguration;
}) => {
  const { postManagementDeviceConfiguration } = useManagementApiFromContext();

  const invalidateManagementDeviceConfigurations =
    useManagementDeviceConfigurationsInvalidation();

  const { mutate: createManagementDeviceConfiguration } = useMutation({
    mutationFn: postManagementDeviceConfiguration,
    onSuccess: () => {
      invalidateManagementDeviceConfigurations();
      message.success('Gerätekonfiguration wurde erfolgreich dupliziert.');
    },
    onError: (error: unknown) => {
      message.error(getErrorMessage(error));
    },
  });

  let adaptedMatchLabels =
    managementDeviceConfiguration.deviceSelector.matchLabels;

  adaptedMatchLabels = replaceWithDefault(
    deviceConfigurationKey,
    adaptedMatchLabels,
  );
  adaptedMatchLabels = replaceWithDefault(
    seniorCareDeviceManagementIntegrationConfigurationKey,
    adaptedMatchLabels,
  );

  return (
    <Tooltip title="Duplizieren">
      <Button
        icon={<CopyOutlined />}
        onClick={() => {
          createManagementDeviceConfiguration({
            name: `${managementDeviceConfiguration.name} (Kopie)`,
            priority: managementDeviceConfiguration.priority,
            deviceSelector: {
              ...managementDeviceConfiguration.deviceSelector,
              matchLabels: adaptedMatchLabels,
            },
            properties: managementDeviceConfiguration.properties,
          });
        }}
        type="text"
      />
    </Tooltip>
  );
};

const replaceWithDefault = (
  key: string,
  matchLabels: MatchExpression[] | undefined,
) => {
  const adaptedMatchLabels = matchLabels?.filter(
    (matchLabel) => matchLabel.key !== key,
  );

  if (adaptedMatchLabels?.length !== matchLabels?.length) {
    adaptedMatchLabels?.push({
      key,
      operator: Operator.IN,
      values: [uuid()],
    });
  }

  return adaptedMatchLabels;
};

export default DuplicateManagementDeviceConfigurationButton;

import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import { transformOrganizationDeviceConfig } from 'api/devices/useAdminToManagementApi';
import { useRawManagementApi } from 'api/devices/useRawManagementApi';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import useUserOrg from 'hooks/auth/useUserOrg';
import { ManagementDeviceConfiguration } from 'interfaces/managementDeviceConfiguration';
import { createContext, useCallback, useContext } from 'react';
import { useNullablePathOrganization } from '../../router';

/**
 * Allows components that need to load device configurations to be shared across admin and organization views.
 */
const DeviceConfigurationsContext = createContext<
  (() => Promise<ManagementDeviceConfiguration[]>) | undefined
>(undefined);

export const useManagementDeviceConfigurationsProviderFromContext =
  (): (() => Promise<ManagementDeviceConfiguration[]>) => {
    const context = useContext(DeviceConfigurationsContext);

    if (context === undefined) {
      throw new Error(
        'useDeviceConfigurationsProviderFromContext must be used within a DeviceConfigurationsContext',
      );
    }

    return context;
  };

export const DeviceConfigurationsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const organization = useNullablePathOrganization();
  const userOrg = useUserOrg();
  const isAdmin = useIsAdmin();

  const { getManagementDeviceConfigurations } = useRawManagementApi();
  const { getOrganizationDeviceConfigurations } = useAdminDeviceApi();

  const getDeviceConfigurations = useCallback(async () => {
    if (isAdmin) {
      if (organization === null) {
        return [];
      }

      return (await getOrganizationDeviceConfigurations(organization)).map(
        transformOrganizationDeviceConfig,
      );
    } else if (userOrg === organization) {
      return await getManagementDeviceConfigurations();
    } else {
      throw new Error('User is not authorized to get device configurations');
    }
  }, [
    userOrg,
    organization,
    isAdmin,
    getManagementDeviceConfigurations,
    getOrganizationDeviceConfigurations,
  ]);

  return (
    <DeviceConfigurationsContext.Provider value={getDeviceConfigurations}>
      {children}
    </DeviceConfigurationsContext.Provider>
  );
};

import { DeviceProperty, DevicePropertyType } from 'defaults/deviceProperties';
import { UnreachableCaseError } from 'ts-essentials';
import BooleanDevicePropertyValue from './BooleanDevicePropertyValue';
import DurationDevicePropertyValue from './DurationDevicePropertyValue';
import JSONDevicePropertyValue from './JSONDevicePropertyValue';
import NumberDevicePropertyValue from './NumberDevicePropertyValue';
import StringDevicePropertyValue from './StringDevicePropertyValue';

interface Props {
  deviceProperty: DeviceProperty;
  onChange: (value: string) => void;
  value: string;
  style?: React.CSSProperties;
}

const DevicePropertyValue: React.FC<Props> = ({
  deviceProperty,
  onChange,
  value,
  style,
}) => {
  switch (deviceProperty.type) {
    case DevicePropertyType.STRING:
      return (
        <StringDevicePropertyValue
          deviceProperty={deviceProperty}
          onChange={onChange}
          value={value}
          style={style}
        />
      );
    case DevicePropertyType.JSON:
      return (
        <JSONDevicePropertyValue
          deviceProperty={deviceProperty}
          onChange={onChange}
          value={value}
          style={style}
        />
      );
    case DevicePropertyType.BOOLEAN:
      return (
        <BooleanDevicePropertyValue
          deviceProperty={deviceProperty}
          onChange={(value) => onChange(value.toString())}
          value={value === 'true'}
          style={style}
        />
      );
    case DevicePropertyType.DURATION:
      return (
        <DurationDevicePropertyValue
          deviceProperty={deviceProperty}
          onChange={onChange}
          value={value}
          style={style}
        />
      );
    case DevicePropertyType.NUMBER:
      return (
        <NumberDevicePropertyValue
          deviceProperty={deviceProperty}
          onChange={onChange}
          value={value}
          style={style}
        />
      );
    default:
      throw new UnreachableCaseError(deviceProperty);
  }
};

export default DevicePropertyValue;

import { Row } from 'antd';
import styled from 'styled-components';

interface Props {
  label?: React.ReactNode;
  style?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  color?: string;
  children: React.ReactNode;
}

const ListItemInfoTag: React.FC<Props> = ({
  label,
  children,
  style,
  valueStyle,
  color,
}) => {
  return (
    <Container style={style}>
      <Label style={{ color }}>
        {label}
        {label && ':'}
      </Label>
      <Value style={{ color, ...valueStyle }}>{children}</Value>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
`;

const Label = styled(Row)`
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: hsl(200, 10%, 60%);
  align-items: center;
  white-space: nowrap;

  span {
    display: none;
  }
`;

const Value = styled.div`
  color: hsl(200, 10%, 20%);
  display: flex;
  align-items: center;
  gap: 7px;
  white-space: nowrap;
  flex-direction: row;

  background-color: #fafafa;
  border-radius: 5px;
  padding: 3px 7px;
`;

export default ListItemInfoTag;

import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import ManagementDevicesActivationRequestTable from 'components/device-activation/ManagementDevicesActivationRequestTable';

const ManagementDeviceActivationView: React.FC = () => {
  return (
    <>
      <Breadcrumb
        items={[
          { name: 'Aktivierungsanfragen', href: '/admin/activation-requests' },
        ]}
      />
      <PageTitleBar
        title="Aktivierungsanfragen"
        Icon={ResourceIcons.ActivationRequest}
      />
      <PageContentContainer>
        <ManagementDevicesActivationRequestTable />
      </PageContentContainer>
    </>
  );
};

export default ManagementDeviceActivationView;

import { Button, Form, Select } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { FeatureRenderProps } from 'defaults/featuresToDeviceProperties';
import useAntdIsFormSubmittable from 'hooks/useAntdIsFormSubmittable';
import { useEffect } from 'react';
import styled from 'styled-components';
import { UnreachableCaseError } from 'ts-essentials';
import {
  DevicePropertyInspectionCatalogsMetadata,
  DevicePropertyInspectionCatalogsMetadataSchema,
  InspectionCatalogMetadataType,
} from './schemas';
import IndustryServicesSubform, {
  industryServicesDefaultValues,
  IndustryServicesFormValues,
  industryServicesFormValuesToIndustryServices,
  industryServicesPath,
  industryServicesSelectOption,
  industryServicesToIndustryServicesFormValues,
} from './subforms/IndustryServicesSubform';
import VehicleInspectionSubform, {
  vehicleInspectionDefaultValues,
  VehicleInspectionFormValues,
  vehicleInspectionFormValuesToVehicleInspection,
  vehicleInspectionPath,
  vehicleInspectionSelectOption,
  vehicleInspectionToVehicleInspectionFormValues,
} from './subforms/VehicleInspectionSubform';

interface FormValues {
  type?: DevicePropertyInspectionCatalogsMetadata['type'];
  [industryServicesPath]?: IndustryServicesFormValues;
  [vehicleInspectionPath]?: VehicleInspectionFormValues;
}

const InspectionCatalogMetadataFeature = ({
  isEditing,
  onEditDone,
  onUpdate,
  values,
}: FeatureRenderProps) => {
  const inspectionCatalogMetadataString = values[
    'INSPECTION_CATALOG_METADATA'
  ] as string | undefined;
  const inspectionCatalogMetadata: DevicePropertyInspectionCatalogsMetadata =
    inspectionCatalogMetadataString !== undefined &&
    inspectionCatalogMetadataString !== ''
      ? DevicePropertyInspectionCatalogsMetadataSchema.parse(
          JSON.parse(inspectionCatalogMetadataString),
        )
      : {
          type: InspectionCatalogMetadataType.VEHICLE_INSPECTION,
          defectCatalogs: [],
          noticeCatalogs: [],
        };

  const [form] = useForm<FormValues>();
  const type = useWatch(['type'], form);

  const isFormSubmittable = useAntdIsFormSubmittable(form);

  useEffect(() => {
    if (!isEditing) {
      form.resetFields();
    }
  }, [form, isEditing]);

  useEffect(() => {
    requestAnimationFrame(() => {
      form.validateFields();
    });
  }, [form, type]);

  const renderSubform = () => {
    switch (type) {
      case InspectionCatalogMetadataType.INDUSTRY_SERVICES:
        return <IndustryServicesSubform isEditing={isEditing} />;
      case InspectionCatalogMetadataType.VEHICLE_INSPECTION:
        return <VehicleInspectionSubform isEditing={isEditing} />;
      case undefined:
        return null;
      default:
        throw new UnreachableCaseError(type);
    }
  };

  return (
    <Form
      form={form}
      initialValues={inspectionCatalogMetadataToFormValues(
        inspectionCatalogMetadata,
      )}
      labelAlign="left"
      labelCol={{ span: 6 }}
      onFinish={(formValues) => {
        onUpdate({
          INSPECTION_CATALOG_METADATA: JSON.stringify(
            formValuesToInspectionCatalogMetadata(formValues),
          ),
        });
        onEditDone?.();
      }}
    >
      <TypeFormItem label="Typ" name="type">
        <TypeSelect
          isEditing={isEditing}
          options={[
            industryServicesSelectOption,
            vehicleInspectionSelectOption,
          ]}
          suffixIcon={!isEditing ? null : undefined}
        />
      </TypeFormItem>
      {renderSubform()}
      {isEditing && (
        <Button
          disabled={!isFormSubmittable}
          onClick={() => {
            form.submit();
          }}
          type="primary"
        >
          Speichern
        </Button>
      )}
    </Form>
  );
};

const TypeFormItem = styled(Form.Item)`
  margin-bottom: ${({ theme }) => theme.antd.paddingSM}px;
`;

const TypeSelect = styled(Select)<{ isEditing: boolean }>`
  pointer-events: ${({ isEditing }) => (!isEditing ? 'none' : 'auto')};
`;

export const defaultValues = {
  ...industryServicesDefaultValues,
  ...vehicleInspectionDefaultValues,
};

export const inspectionCatalogMetadataToFormValues = (
  inspectionCatalogMetadata: DevicePropertyInspectionCatalogsMetadata,
): FormValues => {
  switch (inspectionCatalogMetadata.type) {
    case InspectionCatalogMetadataType.INDUSTRY_SERVICES:
      return {
        type: inspectionCatalogMetadata.type,
        [industryServicesPath]: industryServicesToIndustryServicesFormValues(
          inspectionCatalogMetadata,
        ),
      };
    case InspectionCatalogMetadataType.VEHICLE_INSPECTION:
      return {
        type: inspectionCatalogMetadata.type,
        [vehicleInspectionPath]: vehicleInspectionToVehicleInspectionFormValues(
          inspectionCatalogMetadata,
        ),
      };
    default:
      throw new UnreachableCaseError(inspectionCatalogMetadata);
  }
};

export const formValuesToInspectionCatalogMetadata = (
  formValues: FormValues,
): DevicePropertyInspectionCatalogsMetadata => {
  switch (formValues.type) {
    case InspectionCatalogMetadataType.INDUSTRY_SERVICES: {
      const industryServicesFormValues = formValues[industryServicesPath];
      if (industryServicesFormValues === undefined) {
        throw Error('Illegal form state.');
      }
      return industryServicesFormValuesToIndustryServices(
        industryServicesFormValues,
      );
    }
    case InspectionCatalogMetadataType.VEHICLE_INSPECTION: {
      const vehicleInspectionFormValues = formValues[vehicleInspectionPath];
      if (vehicleInspectionFormValues === undefined) {
        throw Error('Illegal form state.');
      }
      return vehicleInspectionFormValuesToVehicleInspection(
        vehicleInspectionFormValues,
      );
    }
    case undefined:
      throw Error('Illegal form state.');
    default:
      throw new UnreachableCaseError(formValues.type);
  }
};

export default InspectionCatalogMetadataFeature;

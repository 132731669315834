import {
  areaLabel,
  commentLabel,
  deviceRoleLabel,
  industryLabel,
  languageLabel,
  organizationLabel,
  platformLabel,
  sourceLabel,
} from 'labels';

const metadataCommonNames = {
  [platformLabel]: 'Plattform',
  [languageLabel]: 'Sprache',
  [industryLabel]: 'Branche',
  [sourceLabel]: 'Quelle',
  [areaLabel]: 'Bereich',
  [organizationLabel]: 'Organisation',
  [commentLabel]: 'Kommentar',
  [deviceRoleLabel]: 'Geräte-Rolle',
};

export default metadataCommonNames;

import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Button, Popconfirm } from 'antd';
import CodeBlockInline from 'components/common/CodeBlockInline';
import InfoTagVertical from 'components/common/InfoTagVertical';
import { FlexBox } from 'components/Helpers';
import { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { DateTime } from 'luxon';

interface Props {
  activationRequest:
    | ManagementDeviceActivationRequest
    | DeviceActivationRequest;
  organization: string | null;
  onDeleted: () => void;
  remove: () => Promise<void>;
  setIsActivationModalOpen: (value: boolean) => void;
  shouldShowDeviceId?: boolean;
}

const DeviceActivationActions: React.FC<Props> = ({
  activationRequest,
  organization,
  onDeleted,
  remove,
  setIsActivationModalOpen,
  shouldShowDeviceId = true,
}) => {
  return (
    <>
      {activationRequest.approvedAt ? (
        <FlexBox withgap alignitems="center">
          <CheckSquareOutlined
            style={{
              fontSize: '20px',
              color: 'hsl(120, 50%, 50%)',
            }}
          />
          <div
            style={{
              width: '140px',
            }}
          >
            Angenommen am{' '}
            {DateTime.fromISO(activationRequest.approvedAt).toFormat(
              'dd.MM.yyyy HH:mm:ss',
            )}
          </div>
          {activationRequest.deviceId && shouldShowDeviceId && organization && (
            <Link
              to="/management/$organization/devices/$deviceId"
              params={{
                organization,
                deviceId: activationRequest.deviceId,
              }}
            >
              <InfoTagVertical label="Geräte-ID">
                <CodeBlockInline style={{ cursor: 'pointer' }}>
                  #{activationRequest.deviceId.slice(0, 8)}
                </CodeBlockInline>
              </InfoTagVertical>
            </Link>
          )}
          <Popconfirm
            title="Anfrage löschen"
            description="Wenn sie diese Anfrage ablehnen, wird sie direkt gelöscht und kann nicht wiederhergestellt werden. Fortfahren?"
            okText="Ja"
            cancelText="Nein"
            onConfirm={async () => {
              await remove();
              onDeleted();
            }}
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </FlexBox>
      ) : (
        <>
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={() => setIsActivationModalOpen(true)}
          >
            Annehmen
          </Button>
          <Popconfirm
            title="Anfrage löschen"
            description="Wenn sie diese Anfrage ablehnen, wird sie direkt gelöscht und kann nicht wiederhergestellt werden. Fortfahren?"
            okText="Ja"
            cancelText="Nein"
            onConfirm={async () => {
              await remove();
              onDeleted();
            }}
          >
            <Button type="primary" danger icon={<StopOutlined />}>
              Löschen
            </Button>
          </Popconfirm>
        </>
      )}
    </>
  );
};

export default DeviceActivationActions;

import { MinusCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Form, Select, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FlexBox } from 'components/Helpers';
import wellKnownAdminDeviceLabels from 'defaults/deviceLabels';
import { Operator } from 'interfaces/matchers';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

const OPERATOR = 'operator';
const VALUES = 'values';

interface Props {
  listName: string;
  name: number;
  remove: (index: number | number[]) => void;
}

const DeviceLabelSelector: React.FC<Props> = ({ listName, name, remove }) => {
  const [selectedKey, setSelectedKey] = useState('');

  const valueOptions = wellKnownAdminDeviceLabels[selectedKey];

  const form = useFormInstance();
  const operator = useWatch([listName, name, OPERATOR], form);

  const isValuesDisabled = [Operator.EXISTS, Operator.DOES_NOT_EXIST].includes(
    operator,
  );

  useEffect(() => {
    if (isValuesDisabled) {
      form.setFieldValue([listName, name, VALUES], []);
    }
  }, [form, isValuesDisabled, listName, name]);

  return (
    <LabelSelector withgap alignitems="center">
      <Form.Item noStyle>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            noStyle
            name={[name, 'key']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Label darf nicht leer sein',
              },
            ]}
          >
            <AutoComplete
              placeholder="Name"
              options={Object.keys(wellKnownAdminDeviceLabels).map((label) => ({
                label,
                value: label,
              }))}
              onChange={(value) => setSelectedKey(value)}
            />
          </Form.Item>

          <Space.Compact style={{ width: '100%' }}>
            <Form.Item
              noStyle
              name={[name, OPERATOR]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Operator darf nicht leer sein',
                },
              ]}
            >
              <Select
                options={Object.entries(Operator).map(([operator, label]) => ({
                  label,
                  value: operator,
                }))}
                placeholder="Operator"
                style={{ width: '350px' }}
              />
            </Form.Item>

            <Form.Item noStyle name={[name, VALUES]}>
              <Select
                disabled={isValuesDisabled}
                placeholder="Werte"
                mode="tags"
                style={{ width: '100%' }}
                options={valueOptions?.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            </Form.Item>
          </Space.Compact>
        </Space>
      </Form.Item>
      <MinusCircleOutlined onClick={() => remove(name)} />
    </LabelSelector>
  );
};

export default DeviceLabelSelector;

const LabelSelector = styled(FlexBox)`
  border: 1px solid #d9d9d9;
  padding: 8px;
`;

import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Button, Dropdown, message, Popconfirm } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import {
  deleteDevicesScope,
  deleteOrganizationDevicesScope,
  readDeviceActivationCodes,
  readOrganizationDeviceActivationCodes,
  writeDevicesScope,
  writeOrganizationDevicesScope,
} from 'api/devices/deviceManagementApiScopes';
import { FlexBox } from 'components/Helpers';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import useAuthContext from 'hooks/auth/useAuthContext';
import { ManagementDevice } from 'interfaces/device';
import { LuCircleDashed, LuQrCode } from 'react-icons/lu';

interface Props {
  device: ManagementDevice;
  onShowQRCode: () => void;
  onDeleted?: () => void;
  showEdit?: boolean;
  onEdit?: () => void;
  additionalControls?: ItemType[];
}
const DeviceControls: React.FC<Props> = ({
  device,
  onShowQRCode = () => {},
  onDeleted = () => {},
  showEdit = true,
  additionalControls = [],
  onEdit,
}) => {
  const { resetDeviceSecret, deleteDevice } = useManagementApiFromContext();
  const { scope } = useAuthContext();

  const dropdownItems: ItemType[] = [
    ...(scope.includes(readDeviceActivationCodes) ||
    scope.includes(readOrganizationDeviceActivationCodes)
      ? [
          {
            key: 'register',
            label: (
              <FlexBox withgap alignitems="center">
                <LuQrCode />
                <span>Gerät einrichten</span>
              </FlexBox>
            ),
            onClick: async () => {
              onShowQRCode();
            },
          },
        ]
      : []),
    ...(scope.includes(writeOrganizationDevicesScope) ||
    scope.includes(writeDevicesScope)
      ? [
          {
            key: 'reset',
            label: (
              <Popconfirm
                title="Dieses Gerät wird abgemeldet, wenn es bereits registriert ist. Sind Sie sicher?"
                onConfirm={async () => {
                  await resetDeviceSecret(device.id);
                  message.success('Gerät wurde zurückgesetzt.');
                }}
                okText="Ja"
                cancelText="Nein"
              >
                <FlexBox withgap alignitems="center" style={{ color: 'red' }}>
                  <LuCircleDashed />
                  <span>Gerät zurücksetzen</span>
                </FlexBox>
              </Popconfirm>
            ),
          },
        ]
      : []),
    ...additionalControls,
  ];

  return (
    <>
      {showEdit &&
        (scope.includes(writeOrganizationDevicesScope) ||
          scope.includes(writeDevicesScope)) && (
          <>
            {onEdit === undefined ? (
              <Link
                to="/management/$organization/devices/$deviceId"
                params={{
                  organization: device.organization,
                  deviceId: device.id,
                }}
              >
                <Button icon={<EditOutlined />} type="text" />
              </Link>
            ) : (
              <Button icon={<EditOutlined />} type="text" onClick={onEdit} />
            )}
          </>
        )}

      {(scope.includes(deleteOrganizationDevicesScope) ||
        scope.includes(deleteDevicesScope)) && (
        <Popconfirm
          title="Sind Sie sicher, dass Sie dieses Gerät löschen möchten?"
          onConfirm={async () => {
            await deleteDevice(device.id);
            message.success('Gerät wurde gelöscht.');
            onDeleted();
          }}
          okText="Yes"
        >
          <Button danger type="text" icon={<DeleteOutlined />} />
        </Popconfirm>
      )}
      {dropdownItems.length > 0 && (
        <Dropdown
          menu={{
            items: dropdownItems,
          }}
        >
          <Button icon={<MoreOutlined />} type="text" />
        </Dropdown>
      )}
    </>
  );
};

export default DeviceControls;

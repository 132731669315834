export enum CursorDirection {
  forward = 'forward',
  backward = 'backward',
}

export interface PaginationQuery {
  cursor?: string | null;
  limit?: number;
  cursorDirection?: CursorDirection;
}

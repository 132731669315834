// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { useCallback } from 'react';

import { getRequiredEnv } from 'config';
import { Contract } from 'interfaces/contract';
import { Version } from 'interfaces/version';
import { readContractScope } from './contractsApiScopes';

const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

export const voizeDeviceManagementApi = Axios.create({
  baseURL: getRequiredEnv('VITE_PERMISSION_SERVICE_URL'),
});

export const usePermissionServiceVersion = () => {
  const voizeDeviceManagementServiceApi = useMemo(
    () =>
      Axios.create({
        baseURL: getRequiredEnv('VITE_PERMISSION_SERVICE_URL').replace(
          '/v1',
          '',
        ),
      }),
    [],
  );

  const fetchVersion = useCallback(async (): Promise<Version> => {
    const { data } = await voizeDeviceManagementServiceApi.get('/version');
    return data;
  }, [voizeDeviceManagementServiceApi]);

  return fetchVersion;
};

export const usePermissionServiceApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(
    async (...scopes: string[]) =>
      await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: scopes.join(' '),
        },
      }),
    [getAccessTokenSilently],
  );

  const getContracts = useCallback(async (): Promise<Contract[]> => {
    const accessToken = await getAccessToken(readContractScope);

    const { data } = await voizeDeviceManagementApi.get('/admin/contracts', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  }, [getAccessToken]);

  const getLatestContractsByType = useCallback(
    async (type: string): Promise<Contract[]> => {
      const accessToken = await getAccessToken(readContractScope);

      const { data } = await voizeDeviceManagementApi.get(
        `/admin/permissions/${type}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  return {
    getContracts,
    getLatestContractsByType,
  };
};

export const hubspotOrganizationIdMetadataKey = 'voize/hubspot-organization-id';
export const stripeOrganizationIdMetadataKey = 'voize/stripe-organization-id';

export const productsMetadataKey = 'voize/products';
export const numberBookedLicencesMetadataKey = 'voize/number-booked-licences';
export const numberLaunchedLicencesMetadataKey =
  'voize/number-launched-licences';
export const trustedEmailDomainsMetadataKey =
  'voize-device-management/device-activation-request/trusted-email-domains';

export const seniorCareAppIntegrationTypeMetadataKey =
  'voize-app-senior-care/integration-type';
export const seniorCareAppIntegrationIdentifierMetadataKey =
  'voize-app-senior-care/integration-identifier';

export const organizationTypeMetadataKey = 'voize/organization-type';

export const organizationActiveDevices = 'organization/stats/active-devices';
export const organizationActiveUsers = 'organization/stats/active-users';

export const voizeAdminShowSeniorCareUI = 'voize-admin/show-senior-care-ui';
export const organizationAnalyticsInsights = 'organization/analytics/insights';
export const organizationAnalyticsInsightsEnabled =
  'organization/analytics/insights-enabled';

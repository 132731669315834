// note that every array is an object in javascript as well. So, we need to check for array first and then for object.

const recursivelyMaskPasswordValues = (
  data: Record<string, any>,
): Record<string, any> => {
  const blacklist = ['password', 'secret', 'token'];
  const whitelist = ['duration', 'rotation'];

  if (typeof data !== 'object') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(recursivelyMaskPasswordValues);
  }

  const result: Record<string, any> = {};
  for (const [key, value] of Object.entries(data)) {
    if (value === null || value === undefined) {
      result[key] = value;
    } else if (Array.isArray(value)) {
      result[key] = value.map(recursivelyMaskPasswordValues);
    } else if (typeof value === 'object') {
      result[key] = recursivelyMaskPasswordValues(value);
    } else if (
      typeof value === 'string' &&
      blacklist.some((blacklisted) =>
        key.toLowerCase().includes(blacklisted),
      ) &&
      !whitelist.some((whitelisted) => key.toLowerCase().includes(whitelisted))
    ) {
      result[key] = '****';
    } else {
      result[key] = value;
    }
  }
  return result;
};

export default recursivelyMaskPasswordValues;

import { ArrowRightOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from '@tanstack/react-router';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  message,
  Select,
  Skeleton,
  Switch,
} from 'antd';
import { useAdminOrganizationApi } from 'api/useAdminOrganizationApi';
import MetadataEditable from 'components/admin/organization-details/MetadataEditable';
import OrganizationDetailUsers from 'components/admin/organization-details/OrganizationDetailUsers';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import { FlexBox } from 'components/Helpers';
import OrganizationControls from 'components/organizations/OrganizationControls';
import seniorCareAppIntegrationTypes from 'defaults/integrationTypes';
import organizationProducts, {
  productAppSeniorCare,
} from 'defaults/organizationProducts';
import organizationTypes from 'defaults/organizationTypes';
import {
  hubspotOrganizationIdMetadataKey,
  numberBookedLicencesMetadataKey,
  numberLaunchedLicencesMetadataKey,
  organizationActiveDevices,
  organizationActiveUsers,
  organizationAnalyticsInsightsEnabled,
  organizationTypeMetadataKey,
  productsMetadataKey,
  seniorCareAppIntegrationIdentifierMetadataKey,
  seniorCareAppIntegrationTypeMetadataKey,
  stripeOrganizationIdMetadataKey,
  trustedEmailDomainsMetadataKey,
} from 'organizationMetadataKeys';
import { adminOrganizationRoute } from 'router';
import NotFoundView from './NotFoundView';

const stripeBaseURL = 'https://dashboard.stripe.com/customers/';

const OrganizationDetailsView: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId } = adminOrganizationRoute.useParams();
  const { getOrganization, putOrganization } = useAdminOrganizationApi();

  const {
    data: organization,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['organizations', organizationId],
    queryFn: () => getOrganization(organizationId),
  });

  const { mutate: updateOrganization, isPending } = useMutation({
    mutationFn: putOrganization,

    onSuccess: () => {
      refetch();
      message.success('Organization updated');
    },
  });

  if (isLoading) return <Skeleton active />;
  if (!organization) return <NotFoundView />;

  const products = organization.metadata[productsMetadataKey]
    ? JSON.parse(organization.metadata[productsMetadataKey])
    : null;

  const organizationItems: DescriptionsProps['items'] = [
    {
      key: 'id',
      label: 'ID',
      children: organization.id,
      span: 2,
    },
    {
      key: 'name',
      label: 'Organization Name',
      children: organization.name,
      span: 2,
    },
    {
      key: 'bookedLicenses',
      label: 'Booked Licenses',
      children: organization.metadata[numberBookedLicencesMetadataKey],
      span: 1,
    },
    {
      key: 'launchedLicenses',
      label: 'Launched Licenses',
      children: organization.metadata[numberLaunchedLicencesMetadataKey],
      span: 1,
    },
    {
      key: 'activeUsers',
      label: 'Active Users',
      children: organization.metadata[organizationActiveUsers],
      span: 1,
    },
    {
      key: 'activeDevices',
      label: 'Active Devices',
      children: organization.metadata[organizationActiveDevices],
      span: 1,
    },
    {
      key: 'hubspotId',
      label: 'HubSpot ID',
      children: (
        <FlexBox withgap alignitems="center">
          {organization.metadata[hubspotOrganizationIdMetadataKey] || '-'}
          {!!organization.metadata[hubspotOrganizationIdMetadataKey] && (
            <a
              href={
                `https://app.hubspot.com/contacts/7841660/record/0-2/` +
                organization.metadata[hubspotOrganizationIdMetadataKey]
              }
              target="_blank"
              rel="noreferrer"
            >
              <Button type="link">View in HubSpot</Button>
            </a>
          )}
        </FlexBox>
      ),
      span: 2,
    },
    {
      key: 'stripeId',
      label: 'Stripe ID',
      children: (
        <FlexBox withgap alignitems="center">
          {organization.metadata[stripeOrganizationIdMetadataKey] || '-'}
          {!!organization.metadata[stripeOrganizationIdMetadataKey] && (
            <a
              href={
                stripeBaseURL +
                organization.metadata[stripeOrganizationIdMetadataKey]
              }
              target="_blank"
              rel="noreferrer"
            >
              <Button type="link">View in Stripe</Button>
            </a>
          )}
        </FlexBox>
      ),
      span: 2,
    },
    {
      key: 'products',
      label: 'Produkte',
      children: (
        <>
          <Select
            mode="multiple"
            options={organizationProducts.map((p) => ({
              value: p,
            }))}
            style={{ width: '100%', maxWidth: '350px' }}
            allowClear
            defaultValue={products}
            onChange={(values) => {
              updateOrganization({
                ...organization,
                metadata: {
                  ...organization.metadata,
                  [productsMetadataKey]: JSON.stringify(values),
                },
              });
            }}
          />
          (Changes may take up to 24 hours to take effect)
        </>
      ),
      span: 2,
    },
    ...(products?.includes(productAppSeniorCare)
      ? [
          {
            key: 'seniorCareAppIntegrationType',
            label: 'Senior Care App Integration Type',
            children: (
              <Select
                options={seniorCareAppIntegrationTypes.map((p) => ({
                  value: p,
                }))}
                style={{ width: '100%', maxWidth: '350px' }}
                allowClear
                defaultValue={
                  organization.metadata[
                    seniorCareAppIntegrationTypeMetadataKey
                  ] ?? null
                }
                onChange={(value) => {
                  updateOrganization({
                    ...organization,
                    metadata: {
                      ...organization.metadata,
                      [seniorCareAppIntegrationTypeMetadataKey]: value,
                    },
                  });
                }}
              />
            ),
            span: 2,
          },
          {
            key: 'seniorCareAppIntegrationIdentifier',
            label: 'Senior Care App Integration Identifier',
            children: (
              <MetadataEditable
                onUpdateWithDebounce={updateOrganization}
                metadataKey={seniorCareAppIntegrationIdentifierMetadataKey}
                organization={organization}
                style={{ width: '100%', maxWidth: '350px' }}
              />
            ),
            span: 2,
          },
        ]
      : []),
    {
      key: 'organizationTypeMetadataKey',
      label: 'Organization Type',
      children: (
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          allowClear
          options={organizationTypes.map((p) => ({
            value: p,
          }))}
          defaultValue={
            organization.metadata[organizationTypeMetadataKey]
              ? JSON.parse(organization.metadata[organizationTypeMetadataKey])
              : undefined
          }
          onChange={(values) => {
            updateOrganization({
              ...organization,
              metadata: {
                ...organization.metadata,
                [organizationTypeMetadataKey]: JSON.stringify(values),
              },
            });
          }}
        />
      ),
      span: 2,
    },
    {
      key: 'trustedEmailDomains',
      label: 'Trusted Email Domains',
      children: (
        <Select
          mode="tags"
          style={{ width: '100%' }}
          allowClear
          defaultValue={
            organization.metadata[trustedEmailDomainsMetadataKey]
              ? JSON.parse(
                  organization.metadata[trustedEmailDomainsMetadataKey],
                )
              : undefined
          }
          onChange={(values) => {
            updateOrganization({
              ...organization,
              metadata: {
                ...organization.metadata,
                [trustedEmailDomainsMetadataKey]: JSON.stringify(values),
              },
            });
          }}
        />
      ),
      span: 2,
    },
    {
      key: 'insightsEnabled',
      label: 'Insights',
      children: (
        <>
          <Switch
            checked={
              organization.metadata[organizationAnalyticsInsightsEnabled] ===
              'true'
            }
            onChange={(checked) => {
              updateOrganization({
                ...organization,
                metadata: {
                  ...organization.metadata,
                  [organizationAnalyticsInsightsEnabled]: checked.toString(),
                },
              });
            }}
          />
          (Changes may take up to 24 hours to take effect)
        </>
      ),
      span: 1,
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          { name: 'Organisationen', href: '/admin/organizations' },
          {
            name: organization.name,
            href: `/admin/organizations/${organization.id}`,
          },
        ]}
      />
      <PageTitleBar
        title={organization.name}
        Icon={ResourceIcons.Organization}
        renderToolbar={() => (
          <FlexBox withgap alignitems="baseline">
            <Link
              to="/management/$organization"
              params={{
                organization: organization.id,
              }}
            >
              <Button icon={<ArrowRightOutlined />} type="primary">
                Go to Management View
              </Button>
            </Link>
            <Link
              to="/management/$organization/devices"
              params={{
                organization: organization.id,
              }}
            >
              <Button icon={<ArrowRightOutlined />} type="primary">
                Go to Devices View
              </Button>
            </Link>
            <Button
              type="primary"
              onClick={() => refetch()}
              loading={isPending || isRefetching}
              icon={<ReloadOutlined />}
            />
            <OrganizationControls
              organization={organization}
              onDeleted={() =>
                navigate({
                  to: '/admin/organizations',
                })
              }
            />
          </FlexBox>
        )}
      />
      <PageContentContainer>
        <Descriptions
          bordered
          items={organizationItems}
          layout="vertical"
          column={4}
        />
        <OrganizationDetailUsers organizationId={organizationId} />
      </PageContentContainer>
    </>
  );
};

export default OrganizationDetailsView;

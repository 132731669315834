import {
  UpdateSeniorCareUserDto,
  useSeniorCareApi,
} from 'api/seniorcare/useSeniorCareApi';
import { PaginationQuery } from 'interfaces/PaginationQuery';
import { SeniorCareManagementUser } from 'interfaces/seniorcare/seniorCareUser';
import { useManagementPathOrganization } from 'router';
import useIsAdmin from './auth/useIsAdmin';

/**
 * Allows to access the senior care management users.
 * If the user is an admin, it uses the admin endpoints.
 */
function useSeniorCareManagementUsers(): {
  getUsers: (
    paginationQuery: PaginationQuery,
  ) => Promise<SeniorCareManagementUser[]>;
  deleteUser: (userId: string) => Promise<void>;
  updateUser: (userId: string, data: UpdateSeniorCareUserDto) => Promise<void>;
} {
  const isAdmin = useIsAdmin();
  const organization = useManagementPathOrganization();

  const {
    getAdminSeniorCareUsersV2,
    getManagementSeniorCareUsersV2,
    deleteAdminSeniorCareUser,
    updateAdminSeniorCareUser,
    deleteManagementSeniorCareUser,
    updateManagementSeniorCareUser,
  } = useSeniorCareApi();

  if (isAdmin) {
    return {
      getUsers: async (paginationQuery: PaginationQuery) => {
        const adminUsers = await getAdminSeniorCareUsersV2(
          paginationQuery,
          organization,
        );
        return adminUsers.map<SeniorCareManagementUser>((adminUser) => ({
          id: adminUser.id,
          firstname: adminUser.firstname,
          lastname: adminUser.lastname,
          createdAt: adminUser.createdAt,
          lastSeenAt: adminUser.lastSeenAt,
          metadata: adminUser.metadata,
          integrationCredentials: adminUser.integrationCredentials,
        }));
      },
      deleteUser: deleteAdminSeniorCareUser,
      updateUser: updateAdminSeniorCareUser,
    };
  } else {
    return {
      getUsers: getManagementSeniorCareUsersV2,
      deleteUser: deleteManagementSeniorCareUser,
      updateUser: updateManagementSeniorCareUser,
    };
  }
}

export default useSeniorCareManagementUsers;

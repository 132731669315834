import { Navigate, useNavigate, useRouterState } from '@tanstack/react-router';
import { Card } from 'antd';
import { FlexBox, FlexOne } from 'components/Helpers';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import useUserOrg from 'hooks/auth/useUserOrg';
import React from 'react';
import OrganizationSwitcher from './OrganizationSwitcher';

interface Props {
  routePrefix: string;
}

const MyOrganizationView: React.FC<Props> = ({ routePrefix }) => {
  const isAdmin = useIsAdmin();
  const organization = useUserOrg();
  const router = useRouterState();
  const navigate = useNavigate();
  const routeSuffix = router.location.pathname.replace(routePrefix, '');

  if (isAdmin) {
    return (
      <Card
        style={{ height: '100%' }}
        bodyStyle={{
          height: '100%',
        }}
      >
        <FlexBox
          style={{
            height: '100%',
          }}
          direction="column"
        >
          <FlexOne>
            <OrganizationSwitcher
              onSubmit={(organization) => {
                navigate({
                  to: `/management/$organization/${routeSuffix}`,
                  params: { organization },
                  search: router.location.search,
                  hash: router.location.hash,
                });
              }}
            />
          </FlexOne>
        </FlexBox>
      </Card>
    );
  } else {
    return (
      <Navigate
        to={`/management/$organization/${routeSuffix}`}
        params={{ organization }}
        search={router.location.search}
        hash={router.location.hash}
      />
    );
  }
};

export default MyOrganizationView;

export enum Operator {
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  EXISTS = 'EXISTS',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
}

export const operatorToName: Record<Operator, string> = {
  [Operator.IN]: 'in',
  [Operator.NOT_IN]: 'not in',
  [Operator.EXISTS]: 'exists',
  [Operator.DOES_NOT_EXIST]: 'does not exist',
};

export interface MatchExpression {
  key: string;
  operator: Operator;
  values: string[];
}

export interface SetMatch {
  in?: string[];
  notIn?: string[];
}

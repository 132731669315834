import { useQuery } from '@tanstack/react-query';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

export enum SortOptions {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  APPROVED_AT_ASC = 'APPROVED_AT_ASC',
}

const useAdminDeviceActivationRequests = (
  sorting = SortOptions.APPROVED_AT_ASC,
) => {
  const { getAdminDeviceActivationRequests } = useAdminDeviceApi();

  const query = useQuery({
    queryKey: ['admin-device-activation-requests'],
    queryFn: () => getAdminDeviceActivationRequests(),
  });

  const openRequests = useMemo(
    () => query.data?.filter((request) => request.approvedAt === null) ?? [],
    [query.data],
  );

  const sortedRequests = useMemo(() => {
    const data = [...(query.data ?? [])];
    switch (sorting) {
      case SortOptions.CREATED_AT_ASC:
        return data.sort((a, b) => {
          return (
            DateTime.fromISO(b.createdAt).toMillis() -
            DateTime.fromISO(a.createdAt).toMillis()
          );
        });
      case SortOptions.CREATED_AT_DESC:
        return data.sort((a, b) => {
          return (
            DateTime.fromISO(a.createdAt).toMillis() -
            DateTime.fromISO(b.createdAt).toMillis()
          );
        });
      case SortOptions.APPROVED_AT_ASC:
        return data.sort((a, b) => {
          if (a.approvedAt && b.approvedAt)
            return (
              DateTime.fromISO(b.approvedAt).toMillis() -
              DateTime.fromISO(a.approvedAt).toMillis()
            );
          if (a.approvedAt) return 1;
          if (b.approvedAt) return -1;
          return (
            DateTime.fromISO(b.createdAt).toMillis() -
            DateTime.fromISO(a.createdAt).toMillis()
          );
        });
      default:
        throw Error(`Unknown sorting option ${sorting}`);
    }
  }, [query.data, sorting]);

  return { ...query, openRequests, sortedRequests };
};

export default useAdminDeviceActivationRequests;

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from '@tanstack/react-router';
import { Button, Skeleton, Switch, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { FlexBox, FlexOne } from 'components/Helpers';
import DeviceNameEditable from 'components/admin/list/DeviceNameEditable';
import ActivationRequestEmail from 'components/common/ActivationRequestEmail';
import Breadcrumb from 'components/common/Breadcrumb';
import IdTag from 'components/common/IdTag';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import ResourceIcons from 'components/common/resourceIcons';
import ManagementDeviceActivationActions from 'components/device-activation/ManagementDeviceActivationActions';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import LabelList from 'components/views/LabelList';
import device from 'defaults/deviceRoles';
import useJsonSearch from 'hooks/useJsonSearch';
import useManagementCombinedActivationRequestsAndDevices, {
  CombinedActivationRequestAndDevice,
  CombinedActivationRequestAndDeviceType,
} from 'hooks/useManagementCombinedActivationRequestsAndDevices';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import {
  ManagementDevice,
  ManagementDevicePostResponse,
} from 'interfaces/device';
import { deviceCreatedAtLabel, deviceDisplayNameLabel } from 'labels';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useManagementPathOrganization } from 'router';
import { UnreachableCaseError } from 'ts-essentials';
import DeviceControls from '../DeviceControls';
import ManagementDeviceActivationCodeModal from '../ManagementDeviceActivationCodeModal';
import ManagementDeviceCreateModal from '../ManagementDeviceCreateModal';

const ManagementDevicesList: React.FC = () => {
  const navigate = useNavigate();
  const organization = useManagementPathOrganization();

  const { deleteDevice } = useManagementApiFromContext();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [showQrCode, setShowQrCode] = useState<string | null>(null);

  const {
    data: combined,
    refetch,
    isLoading,
    invalidateDevices,
  } = useManagementCombinedActivationRequestsAndDevices();

  const [currentDataSource, setCurrentDataSource] =
    useState<CombinedActivationRequestAndDevice[]>(combined);

  useEffect(() => {
    setCurrentDataSource(combined);
  }, [combined]);

  const currentDevicesCount = useMemo(
    () =>
      currentDataSource.filter(
        (d) => d.type === CombinedActivationRequestAndDeviceType.DEVICE,
      ).length,
    [currentDataSource],
  );
  const currentActivationRequestsCount = useMemo(
    () => currentDataSource.filter((d) => d.activationRequest !== null).length,
    [currentDataSource],
  );

  const { filteredItems, setSearchText } = useJsonSearch(combined);

  const onCreated = useCallback(
    (device: ManagementDevicePostResponse) => {
      setCreateModalOpen(false);
      navigate({
        to: '/management/$organization/devices/$deviceId',
        params: { organization, deviceId: device.id },
        search: { isNew: true },
      });
    },
    [navigate, organization],
  );

  const onDelete = useCallback(
    async (device: ManagementDevice) => {
      await deleteDevice(device.id);
      invalidateDevices();
    },
    [deleteDevice, invalidateDevices],
  );

  const getColumnSearchProps =
    useTableSearchFilter<CombinedActivationRequestAndDevice>();

  const columns: ColumnsType<CombinedActivationRequestAndDevice> = [
    {
      title: 'Device ID',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        return a.device.id.localeCompare(b.device.id);
      },
      ...getColumnSearchProps(
        'device id',
        'device_id',
        (record) => {
          switch (record.type) {
            case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
              return null;
            case CombinedActivationRequestAndDeviceType.DEVICE:
              return record.device.id;
            default:
              throw new UnreachableCaseError(record);
          }
        },
        (record, _, searchText) => {
          const deviceId = (() => {
            switch (record.type) {
              case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
                return record.activationRequest.deviceId;
              case CombinedActivationRequestAndDeviceType.DEVICE:
                return record.device.id;
              default:
                throw new UnreachableCaseError(record);
            }
          })();

          if (deviceId === null) return 'Noch nicht aktiviert';

          return (
            <Link
              to="/management/$organization/devices/$deviceId"
              params={{
                organization,
                deviceId,
              }}
            >
              <IdTag
                copyable={false}
                id={deviceId}
                isCollapsed={true}
                highlight={searchText}
              />
            </Link>
          );
        },
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return null;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return <DeviceNameEditable device={record.device} />;
          default:
            throw new UnreachableCaseError(record);
        }
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;

        return (
          a.device.labels[deviceDisplayNameLabel]?.localeCompare(
            b.device.labels[deviceDisplayNameLabel],
          ) ?? 0
        );
      },
    },
    {
      title: 'Aktivierungsanfrage',
      filters: [
        {
          text: 'Aktiviert',
          value: 'activated',
        },
        {
          text: 'Nicht aktiviert',
          value: 'notActivated',
        },
        {
          text: 'Nicht existent',
          value: 'notExistent',
        },
      ],
      sorter: (a, b) => {
        if (!a.activationRequest?.approvedAt) return -1;
        if (!b.activationRequest?.approvedAt) return 1;
        return (
          a.activationRequest.approvedAt.localeCompare(
            b.activationRequest.approvedAt,
          ) ?? 0
        );
      },
      onFilter: (value, record) => {
        if (value === 'activated') {
          return !!record.activationRequest?.approvedAt;
        }
        if (value === 'notActivated') {
          return record.activationRequest?.approvedAt === null;
        }
        if (value === 'notExistent') {
          return record.activationRequest === null;
        }
        return false;
      },
      render: (_, { activationRequest }) => {
        if (!activationRequest)
          return (
            <Tooltip
              title="Es existiert keine Aktivierungsanfrage"
              mouseEnterDelay={0.7}
            >
              <CloseOutlined />
            </Tooltip>
          );
        return (
          <FlexBox withgap direction="column">
            <FlexBox withgap>
              <ManagementDeviceActivationActions
                activationRequest={activationRequest}
                onDeleted={() => {
                  refetch();
                }}
                shouldShowDeviceId={false}
              />
            </FlexBox>
            {!activationRequest.approvedAt &&
            activationRequest.userEmailVerifiedAt ? (
              <ListItemInfoTag
                label="E-Mail Adresse bestätigt"
                valueStyle={{
                  backgroundColor: activationRequest.userEmailVerifiedAt
                    ? 'hsla(90, 100%, 72%, 1)'
                    : 'hsla(0, 100%, 72%, 1)',
                }}
              >
                <RelativeDateTimeWithTooltip
                  timestamp={activationRequest.userEmailVerifiedAt}
                  noneText="Noch nicht bestätigt"
                />
              </ListItemInfoTag>
            ) : null}
          </FlexBox>
        );
      },
    },
    {
      title: 'Akt.Anfr. erstellt am',
      ...getColumnSearchProps(
        'request date',
        'activation_request_date',
        (record) => {
          if (!record.activationRequest) return null;
          return record.activationRequest.createdAt;
        },
        (record) => {
          if (!record.activationRequest) return null;
          return (
            <RelativeDateTimeWithTooltip
              timestamp={record.activationRequest.createdAt}
              noneText="Noch nie"
            />
          );
        },
      ),
      sorter: (a, b) => {
        if (!a.activationRequest) return -1;
        if (!b.activationRequest) return 1;
        return (
          a.activationRequest.createdAt.localeCompare(
            b.activationRequest.createdAt,
          ) ?? 0
        );
      },
    },
    {
      title: 'Nutzer*in',
      ...getColumnSearchProps('user name', 'user_name', (record) => {
        if (!record.activationRequest) return null;
        return record.activationRequest.userName;
      }),
    },
    {
      title: 'E-Mail Adresse',
      ...getColumnSearchProps(
        'email',
        'user_email',
        (record) => {
          if (!record.activationRequest) return null;
          return record.activationRequest.userEmail;
        },
        (record, _, searchText) => {
          if (!record.activationRequest) return null;
          return (
            <ActivationRequestEmail
              activationRequest={record.activationRequest}
              searchWords={[searchText]}
            />
          );
        },
      ),
    },
    {
      title: 'Rolle',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return null;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return record.device.role;
          default:
            throw new UnreachableCaseError(record);
        }
      },
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        return a.device.role?.localeCompare(b.device.role ?? '') ?? 0;
      },
      filters: device.map((role) => ({ text: role, value: role })),
      filterSearch: true,
      onFilter: (value, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return false;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return record.device.role === value;
          default:
            throw new UnreachableCaseError(record);
        }
      },
    },
    {
      title: 'Gerät erstellt am',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return 'Noch nie';
          case CombinedActivationRequestAndDeviceType.DEVICE: {
            const createdAt =
              record.device.labels[deviceCreatedAtLabel] ?? null;

            return createdAt
              ? DateTime.fromISO(
                  record.device.labels[deviceCreatedAtLabel],
                ).toLocaleString(DateTime.DATETIME_SHORT)
              : 'Noch nie';
          }
          default:
            throw new UnreachableCaseError(record);
        }
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        return (
          a.device.labels[deviceCreatedAtLabel]?.localeCompare(
            b.device.labels[deviceCreatedAtLabel],
          ) ?? 0
        );
      },
      responsive: ['xxl'],
    },
    {
      title: 'Labels',
      hidden: !showLabels,
      ...getColumnSearchProps(
        'device labels',
        'device_labels',
        (record) => {
          switch (record.type) {
            case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
              return null;
            case CombinedActivationRequestAndDeviceType.DEVICE:
              return JSON.stringify(record.device.labels);
            default:
              throw new UnreachableCaseError(record);
          }
        },
        (record, _, searchText) => {
          switch (record.type) {
            case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
              return null;
            case CombinedActivationRequestAndDeviceType.DEVICE:
              return (
                <LabelList
                  labels={record.device.labels}
                  highlightTexts={searchText ? [searchText] : []}
                  containerStyle={{
                    maxWidth: '350px',
                    overflowX: 'auto',
                  }}
                />
              );
            default:
              throw new UnreachableCaseError(record);
          }
        },
      ),
    },
    {
      title: 'Zuletzt aktiv',
      sorter: (a, b) => {
        if (a.type !== CombinedActivationRequestAndDeviceType.DEVICE) return 1;
        if (b.type !== CombinedActivationRequestAndDeviceType.DEVICE) return -1;
        if (a.device.lastSeenAt === b.device.lastSeenAt) return 0;
        if (a.device.lastSeenAt === null) return 1;
        if (b.device.lastSeenAt === null) return -1;
        return a.device.lastSeenAt > b.device.lastSeenAt ? -1 : 1;
      },
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return null;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return (
              <RelativeDateTimeWithTooltip
                timestamp={record.device.lastSeenAt}
                noneText="Noch nie"
              />
            );
          default:
            throw new UnreachableCaseError(record);
        }
      },
    },
    {
      title: 'Aktionen',
      render: (_, record) => {
        switch (record.type) {
          case CombinedActivationRequestAndDeviceType.ACTIVATION_REQUEST:
            return null;
          case CombinedActivationRequestAndDeviceType.DEVICE:
            return (
              <FlexBox
                withgap
                gap={4}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <DeviceControls
                  device={record.device}
                  onShowQRCode={() => setShowQrCode(record.device.id)}
                  onDeleted={() => onDelete(record.device)}
                />
              </FlexBox>
            );
          default:
            throw new UnreachableCaseError(record);
        }
      },
    },
  ];

  return (
    <>
      {showQrCode !== null && (
        <ManagementDeviceActivationCodeModal
          deviceId={showQrCode}
          onClose={() => setShowQrCode(null)}
        />
      )}
      <Breadcrumb
        items={[
          {
            name: 'Geräte',
            href: `/management/${organization}/devices`,
          },
        ]}
      />
      <PageTitleBar
        title="Geräte"
        Icon={ResourceIcons.Device}
        renderToolbar={() => (
          <Button
            onClick={() => setCreateModalOpen(true)}
            icon={<PlusOutlined />}
          >
            Gerät hinzufügen
          </Button>
        )}
      />
      <PageContentContainer>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <FlexBox withgap direction="column" style={{ minHeight: '100%' }}>
            <ManagementDeviceCreateModal
              isOpen={createModalOpen}
              onClose={() => setCreateModalOpen(false)}
              onCreated={onCreated}
            />

            <FlexBox withgap alignitems="center">
              <FlexOne>
                <b>{currentDataSource.length}</b> Elemente in Tabelle, davon
                haben <b>{currentDevicesCount}</b> Geräte und{' '}
                <b>{currentActivationRequestsCount}</b> Aktivierungsanfragen
              </FlexOne>
              Labels anzeigen{' '}
              <Switch checked={showLabels} onChange={setShowLabels} />
            </FlexBox>
            <Search
              placeholder="Schnellsuche"
              onChange={_.debounce((e) => setSearchText(e.target.value), 500)}
              style={{
                maxWidth: '800px',
              }}
            />

            <Table
              style={{ marginTop: 5 }}
              size="small"
              dataSource={filteredItems}
              columns={columns}
              rowKey={(item) => item.id}
              pagination={{
                defaultPageSize: 100,
                position: ['bottomRight', 'topRight'],
                pageSizeOptions: [
                  '20',
                  '50',
                  '100',
                  '500',
                  '1000',
                  '5000',
                  '500000',
                ],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                setCurrentDataSource(extra.currentDataSource);
              }}
            />
          </FlexBox>
        )}
      </PageContentContainer>
    </>
  );
};

export default ManagementDevicesList;

import { AutoComplete, Tooltip } from 'antd';
import { DurationDeviceProperty } from 'defaults/deviceProperties';

interface Props {
  deviceProperty: DurationDeviceProperty;
  onChange?: (value: string) => void;
  value: string;
  style?: React.CSSProperties;
}

const DurationDevicePropertyValue: React.FC<Props> = ({
  deviceProperty,
  value,
  onChange,
  style,
}) => {
  const isDefault = deviceProperty.default === value;

  return (
    <Tooltip
      title={isDefault ? 'Standardwert' : undefined}
      mouseEnterDelay={0.3}
    >
      <AutoComplete
        value={value}
        onChange={(value) => onChange?.(value)}
        status={isDefault ? 'warning' : undefined}
        style={style}
      />
    </Tooltip>
  );
};

export default DurationDevicePropertyValue;

export enum ShortcutEvent {
  SWITCH_ORGANIZATION = 'SWITCH_ORGANIZATION',
  CREATE_DEVICE = 'CREATE_DEVICE',
}

export interface Shortcut {
  key: string;
  withCtrl: boolean;
  withShift: boolean;
  type: string;
}

type ShortcutMap = {
  [key in ShortcutEvent]: Shortcut | null;
};

const shortcuts: ShortcutMap = {
  [ShortcutEvent.SWITCH_ORGANIZATION]: {
    key: 'o',
    withCtrl: true,
    withShift: false,
    type: 'keydown',
  },
  [ShortcutEvent.CREATE_DEVICE]: {
    key: 'd',
    withCtrl: true,
    withShift: false,
    type: 'keydown',
  },
};

export default shortcuts;

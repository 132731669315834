import { AlignLeftOutlined } from '@ant-design/icons';
import { Editor, EditorProps, OnMount } from '@monaco-editor/react';
import { Button, theme, Tooltip } from 'antd';
import { clamp } from 'lodash';
import { editor, Uri } from 'monaco-editor';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import recursivelyMaskPasswordValues from 'utlis/maskSecrets';
import { v4 as uuid } from 'uuid';

const EDITOR_INNER_MIN_HEIGHT = theme.getDesignToken().controlHeight;
const EDITOR_PADDING = theme.getDesignToken().paddingXXS;
const EDITOR_MIN_HEIGHT = EDITOR_INNER_MIN_HEIGHT + 2 * EDITOR_PADDING;
const EDITOR_MAX_HEIGHT = 500;
const TAB_SIZE_IN_SPACES = 2;

export enum Language {
  CSS = 'css',
  HTML = 'html',
  JSON = 'json',
  TYPESCRIPT = 'typescript',
}

const CodeEditor = ({
  disabled = false,
  editorProps: {
    options: editorPropsOptions,
    ...editorPropsWithoutOptions
  } = {},
  jsonSchema,
  language,
  onChange,
  style,
  value,
}: {
  disabled?: boolean;
  editorProps?: EditorProps;
  jsonSchema?: object;
  language: Language;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  value?: string;
}) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const [editorHeight, setEditorHeight] = useState(EDITOR_INNER_MIN_HEIGHT);
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const virtualFilename = useMemo(() => uuid(), []);
  const virtualFilenameUri = Uri.parse(virtualFilename);

  const [isValueFormatted, setIsValueFormatted] = useState(false);
  const transformedValue = transformValue(
    value,
    language,
    isValueFormatted,
    !isEditorFocused,
  );

  const updateEditorHeight = () => {
    setEditorHeight(
      clamp(
        editorRef.current?.getContentHeight() ?? 0,
        EDITOR_INNER_MIN_HEIGHT,
        EDITOR_MAX_HEIGHT,
      ),
    );
  };

  useEffect(() => {
    updateEditorHeight();
  }, [transformedValue]);

  const handleEditorOnMount: OnMount = (editor, monaco) => {
    editorRef.current = editor;

    editor.onDidFocusEditorWidget(() => {
      setIsEditorFocused(true);
    });

    editor.onDidBlurEditorWidget(() => {
      setIsEditorFocused(false);
    });

    if (language === Language.JSON && jsonSchema !== undefined) {
      editorRef.current?.setModel(
        monaco.editor.getModel(virtualFilenameUri) ??
          monaco.editor.createModel(
            transformedValue,
            Language.JSON,
            virtualFilenameUri,
          ),
      );

      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        schemas: [
          ...(monaco.languages.json.jsonDefaults.diagnosticsOptions.schemas ??
            []),
          {
            fileMatch: [virtualFilename],
            schema: jsonSchema,
            uri: virtualFilename,
          },
        ],
        schemaValidation: 'error',
        validate: true,
      });
    }

    // TODO: Is there a better solution for this?
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        updateEditorHeight();
      });
    });
  };

  return (
    <Wrapper style={style}>
      <Tooltip title="Formatieren">
        <FormatButton
          icon={<AlignLeftOutlined />}
          onClick={() => {
            setIsValueFormatted((isValueFormatted) => !isValueFormatted);
          }}
          type={isValueFormatted ? 'primary' : undefined}
        />
      </Tooltip>
      <Editor
        defaultLanguage={language}
        height={editorHeight}
        onChange={(value) => {
          onChange?.(transformValue(value, language, false));
        }}
        onMount={handleEditorOnMount}
        options={{
          contextmenu: false,
          folding: false,
          lineNumbers: 'off',
          minimap: {
            enabled: false,
          },
          overviewRulerLanes: 0,
          readOnly: disabled,
          renderLineHighlight: 'none',
          scrollbar: {
            alwaysConsumeMouseWheel: false,
            horizontalScrollbarSize: 4,
            verticalScrollbarSize: 4,
          },
          scrollBeyondLastLine: false,
          tabSize: TAB_SIZE_IN_SPACES,
          wordWrap: !isValueFormatted ? 'on' : undefined,
          ...editorPropsOptions,
        }}
        value={transformedValue}
        {...editorPropsWithoutOptions}
      />
    </Wrapper>
  );
};

const transformValue = (
  value: string | undefined,
  language: Language,
  shouldFormat = false,
  shouldMaskSecrets = false,
) => {
  if (value === undefined) {
    return '';
  }

  if (language === Language.JSON) {
    try {
      let jsonValue = JSON.parse(value);

      if (shouldMaskSecrets) {
        jsonValue = recursivelyMaskPasswordValues(jsonValue);
      }

      return JSON.stringify(
        jsonValue,
        undefined,
        shouldFormat ? TAB_SIZE_IN_SPACES : undefined,
      );

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
  }

  return value;
};

const Wrapper = styled.div`
  border: ${({ theme }) =>
    `${theme.antd.lineWidth}px ${theme.antd.lineType} ${theme.antd.colorBorder}`};
  border-radius: ${({ theme }) => theme.antd.borderRadius}px;
  min-height: ${EDITOR_MIN_HEIGHT}px;
  padding: ${EDITOR_PADDING}px;
  position: relative;
`;

const FormatButton = styled(Button)`
  position: absolute;
  right: ${EDITOR_PADDING}px;
  top: ${EDITOR_PADDING}px;
  z-index: 100;
`;

export default CodeEditor;

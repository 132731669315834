const userRoles = [
  'sdk-developer',
  'organization-admin',
  'senior-care-app-organization-admin',
  'organization-admin-tool-user',
  'data-verification',
  'data-read-only',
] as const;

export default userRoles;

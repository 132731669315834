import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import {
  Button,
  Flex,
  message,
  Pagination,
  Popconfirm,
  Skeleton,
  Table,
} from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import {
  writeDevicesScope,
  writeOrganizationDevicesScope,
} from 'api/devices/deviceManagementApiScopes';
import DeviceNameEditable from 'components/admin/list/DeviceNameEditable';
import ActionBar from 'components/common/ActionBar';
import Breadcrumb from 'components/common/Breadcrumb';
import CopyableId from 'components/common/CopyableId';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import ResourceIcons from 'components/common/resourceIcons';
import { getConfigSelectorLabelValueOrThrow } from 'components/device-configuration/DeviceConfigurationHelper';
import DeviceControls from 'components/device/DeviceControls';
import ManagementDeviceActivationCodeModal from 'components/device/ManagementDeviceActivationCodeModal';
import ManagementDeviceCreateModal from 'components/device/ManagementDeviceCreateModal';
import ManagementSeniorCareDeviceEditModal from 'components/device/ManagementSeniorCareDeviceEditModal';
import { FlexBox, FlexOne } from 'components/Helpers';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { SeniorCareDeviceConfig } from 'components/seniorcare/SeniorCareDeviceConfig';
import {
  useSeniorCareDeviceConfigs,
  useSeniorCareIntegrationConfigs,
} from 'components/seniorcare/SeniorCareDeviceConfigurationAssignment';
import { SeniorCareIntegrationConfig } from 'components/seniorcare/SeniorCareIntegrationConfig';
import { seniorCareAppDeviceRole } from 'defaults/deviceRoles';
import {
  deviceConfigurationKey,
  seniorCareDeviceManagementIntegrationConfigurationKey,
  voizeAppSeniorCareReleaseTrackKey,
  voizeAppSeniorCareVersionKey,
} from 'deviceMetadataKeys';
import useAuthContext from 'hooks/auth/useAuthContext';
import useManagementDevices from 'hooks/management-devices/useManagementDevices';
import useDeleteSeniorCareDeviceData from 'hooks/senior-care/useDeleteSeniorCareDeviceData';
import useAntdPagination from 'hooks/useAntdPagination';
import useAntdTableRowSelection from 'hooks/useAntdTableRowSelection';
import useJsonSearch from 'hooks/useJsonSearch';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import {
  ManagementDevice,
  ManagementDevicePostResponse,
} from 'interfaces/device';
import { deviceDisplayNameLabel } from 'labels';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TiDocumentDelete } from 'react-icons/ti';
import { useManagementPathOrganization } from 'router';
import { toBaseError } from 'utlis/BaseError';

const ManagementSeniorCareDevicesView: React.FC = () => {
  const navigate = useNavigate();
  const organization = useManagementPathOrganization();
  const integrationConfigs = useSeniorCareIntegrationConfigs();
  const deviceConfigs = useSeniorCareDeviceConfigs();
  const deleteSeniorCareDeviceData = useDeleteSeniorCareDeviceData();
  const { scope } = useAuthContext();

  const [showQrCode, setShowQrCode] = useState<string | null>(null);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editDevice, setEditDevice] = useState<null | ManagementDevice>(null);

  const { devices, isLoading, invalidateDevices } = useManagementDevices();

  const seniorCareDevices = useMemo(() => {
    return devices?.filter((d) => d.role === seniorCareAppDeviceRole);
  }, [devices]);

  const { filteredItems, setSearchText } = useJsonSearch(
    seniorCareDevices ?? [],
  );

  const [currentDataSource, setCurrentDataSource] = useState<
    ManagementDevice[]
  >(filteredItems ?? []);

  useEffect(() => {
    setCurrentDataSource(filteredItems ?? []);
  }, [filteredItems]);

  const onCreated = useCallback(
    (device: ManagementDevicePostResponse) => {
      setCreateModalOpen(false);
      navigate({
        to: '/management/$organization/devices/$deviceId',
        params: { organization, deviceId: device.id },
        search: { isNew: true },
      });
    },
    [navigate, organization],
  );

  const pagination = useAntdPagination(filteredItems.length);

  const getIntegrationForDevice = useCallback(
    (device: ManagementDevice) => {
      return (
        integrationConfigs.find(
          (config) =>
            device.labels[
              seniorCareDeviceManagementIntegrationConfigurationKey
            ] ===
            getConfigSelectorLabelValueOrThrow(
              config,
              seniorCareDeviceManagementIntegrationConfigurationKey,
            ),
        ) ?? null
      );
    },
    [integrationConfigs],
  );

  const getIntegrationDisplayName = useCallback(
    (config: SeniorCareIntegrationConfig) => {
      return config.name.replace('Schnittstelle ', '');
    },
    [],
  );

  const getDeviceConfigForDevice = useCallback(
    (device: ManagementDevice) => {
      return (
        deviceConfigs.find(
          (config) =>
            device.labels[deviceConfigurationKey] ===
            getConfigSelectorLabelValueOrThrow(config, deviceConfigurationKey),
        ) ?? null
      );
    },
    [deviceConfigs],
  );

  const getDeviceConfigDisplayName = useCallback(
    (config: SeniorCareDeviceConfig) => {
      return config.name.replace('Konfiguration ', '');
    },
    [],
  );

  const getColumnSearchProps = useTableSearchFilter<ManagementDevice>();

  const columns: ColumnsType<ManagementDevice> = [
    {
      title: 'ID',
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (_, record) => <CopyableId id={record.id} />,
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => <DeviceNameEditable device={record} />,
      sorter: (a, b) => {
        return (
          a.labels[deviceDisplayNameLabel]?.localeCompare(
            b.labels[deviceDisplayNameLabel],
          ) ?? 0
        );
      },
    },
    {
      title: 'Schnittstelle',
      ...getColumnSearchProps(
        'Schnittstelle',
        'integration',
        (record) => {
          const integration = getIntegrationForDevice(record);

          return integration ? getIntegrationDisplayName(integration) : null;
        },
        (record) => {
          const integration = getIntegrationForDevice(record);

          return integration ? (
            <span>{getIntegrationDisplayName(integration)}</span>
          ) : (
            'Keine Schnittstelle'
          );
        },
      ),
    },
    {
      title: 'Gerätekonfiguration',
      ...getColumnSearchProps(
        'Gerätekonfiguration',
        'deviceConfiguration',
        (record) => {
          const activeDeviceConfig = getDeviceConfigForDevice(record);

          return activeDeviceConfig
            ? getDeviceConfigDisplayName(activeDeviceConfig)
            : null;
        },
        (record) => {
          const activeDeviceConfig = getDeviceConfigForDevice(record);

          return activeDeviceConfig ? (
            <span>{getDeviceConfigDisplayName(activeDeviceConfig)}</span>
          ) : (
            'Keine Konfiguration'
          );
        },
      ),
    },
    {
      title: 'App Version',
      width: 170,
      render: (_, record) => (
        <span className="whitespace-nowrap">
          {record.metadata[voizeAppSeniorCareVersionKey]
            ? record.metadata[voizeAppSeniorCareVersionKey] +
              (record.metadata[voizeAppSeniorCareReleaseTrackKey] !==
              'production'
                ? ` (${record.metadata[voizeAppSeniorCareReleaseTrackKey]})`
                : '')
            : ''}
        </span>
      ),
      sorter: (a, b) =>
        a.metadata[voizeAppSeniorCareVersionKey]?.localeCompare(
          b.metadata[voizeAppSeniorCareVersionKey] ?? '',
        ) ?? 0,
    },
    {
      title: 'Zuletzt aktiv',
      width: 170,
      render: (_, record) => (
        <RelativeDateTimeWithTooltip
          timestamp={record.lastSeenAt}
          noneText="Noch nie"
          className="whitespace-nowrap"
        />
      ),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.lastSeenAt?.localeCompare(b.lastSeenAt ?? '') ?? 0,
    },
    {
      title: 'Aktionen',
      render: (_, device) => (
        <FlexBox
          withgap
          gap={4}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <DeviceControls
            device={device}
            onShowQRCode={() => setShowQrCode(device.id)}
            onDeleted={() => invalidateDevices}
            onEdit={() => setEditDevice(device)}
            additionalControls={
              scope.includes(writeOrganizationDevicesScope) ||
              scope.includes(writeDevicesScope)
                ? [
                    {
                      key: 'delete-data',
                      label: (
                        <Popconfirm
                          title="Sind Sie sicher, dass Sie die Daten dieses Geräts löschen wollen?"
                          onConfirm={async () => {
                            await deleteSeniorCareDeviceData(device.id);
                            message.success(
                              'Löschen der Daten wurde angestoßen. Es kann bis zu einer Stunde dauern, bis die Daten gelöscht sind, vorausgesetzt das Gerät ist aktiv.',
                            );
                          }}
                          okText="Ja"
                          cancelText="Nein"
                        >
                          <FlexBox
                            withgap
                            alignitems="center"
                            style={{ color: 'red' }}
                          >
                            <TiDocumentDelete />
                            <span>Daten löschen</span>
                          </FlexBox>
                        </Popconfirm>
                      ),
                    },
                  ]
                : []
            }
          />
        </FlexBox>
      ),
    },
  ];

  const rowSelection = useAntdTableRowSelection();

  const queryClient = useQueryClient();

  const { patchDeviceLabels } = useManagementApiFromContext();
  const { mutate: mutateDeviceLabels } = useMutation({
    mutationFn: async ({
      deviceIds,
      labels,
    }: {
      deviceIds: string[];
      labels: Record<string, string | null>;
    }) => {
      await Promise.all(
        deviceIds.map((deviceId) => patchDeviceLabels(deviceId, labels)),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['management-devices'] });
      message.success('Gerät(e) wurde(n) erfolgreich bearbeitet.');
    },
    onError: (error) => {
      message.error(
        `Gerät(e) konnte(n) nicht bearbeitet werden: ${toBaseError(error).message}`,
      );
    },
  });

  const integrationConfigSelectOptions = integrationConfigs.map(
    (integrationConfig) => ({
      label: getIntegrationDisplayName(integrationConfig),
      value: getConfigSelectorLabelValueOrThrow(
        integrationConfig,
        seniorCareDeviceManagementIntegrationConfigurationKey,
      ),
    }),
  );

  const onSaveIntegrationConfig = (value: string | undefined) => {
    if (value === undefined) {
      return;
    }

    mutateDeviceLabels({
      deviceIds: rowSelection.selectedRowKeys.map((deviceId) =>
        deviceId.toString(),
      ),
      labels: {
        [seniorCareDeviceManagementIntegrationConfigurationKey]: value,
      },
    });
  };

  const deviceConfigSelectOptions = deviceConfigs.map((deviceConfig) => ({
    label: getDeviceConfigDisplayName(deviceConfig),
    value: getConfigSelectorLabelValueOrThrow(
      deviceConfig,
      deviceConfigurationKey,
    ),
  }));

  const onSaveDeviceConfig = (value: string | undefined) => {
    if (value === undefined) {
      return;
    }

    mutateDeviceLabels({
      deviceIds: rowSelection.selectedRowKeys.map((deviceId) =>
        deviceId.toString(),
      ),
      labels: {
        [deviceConfigurationKey]: value,
      },
    });
  };

  return (
    <>
      {showQrCode !== null && (
        <ManagementDeviceActivationCodeModal
          deviceId={showQrCode}
          onClose={() => setShowQrCode(null)}
        />
      )}
      <Breadcrumb
        items={[
          {
            name: 'Pflege App Geräte',
            href: `/management/${organization}/senior-care-devices`,
          },
        ]}
      />
      <PageTitleBar
        title="Geräte"
        Icon={ResourceIcons.Device}
        renderToolbar={() => (
          <Button
            type="primary"
            onClick={() => setCreateModalOpen(true)}
            icon={<PlusOutlined />}
          >
            Gerät hinzufügen
          </Button>
        )}
      />
      <PageContentContainer>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <FlexBox withgap direction="column" style={{ minHeight: '100%' }}>
            <ManagementDeviceCreateModal
              isOpen={createModalOpen}
              onClose={() => setCreateModalOpen(false)}
              onCreated={onCreated}
              isSeniorCare
            />

            {editDevice !== null && (
              <ManagementSeniorCareDeviceEditModal
                device={editDevice}
                onClose={() => setEditDevice(null)}
                onEdited={() => setEditDevice(null)}
              />
            )}

            <FlexBox withgap alignitems="center">
              <FlexOne>
                <b>{currentDataSource.length}</b> Elemente in Tabelle
              </FlexOne>
            </FlexBox>
            <Search
              placeholder="Schnellsuche"
              onChange={_.debounce((e) => setSearchText(e.target.value), 500)}
              style={{
                maxWidth: '800px',
              }}
            />
            <Flex>
              <ActionBar isVisible={rowSelection.selectedRowKeys.length > 0}>
                <ActionBar.Button
                  onClick={() => {
                    rowSelection.onChange([]);
                  }}
                >
                  {`${rowSelection.selectedRowKeys.length} abwählen`}
                </ActionBar.Button>
                <ActionBar.Select
                  onSave={onSaveIntegrationConfig}
                  options={integrationConfigSelectOptions}
                  placeholder="Schnittstelle"
                />
                <ActionBar.Select
                  onSave={onSaveDeviceConfig}
                  options={deviceConfigSelectOptions}
                  placeholder="Gerätekonfiguration"
                />
              </ActionBar>
              <FlexOne />
              <Pagination {...pagination} />
            </Flex>
            <Table
              dataSource={filteredItems}
              columns={columns}
              rowKey={(item) => item.id}
              pagination={{
                ...pagination,
                position: [],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                setCurrentDataSource(extra.currentDataSource);
              }}
              rowSelection={rowSelection}
            />
            <Pagination {...pagination} align="end" />
          </FlexBox>
        )}
      </PageContentContainer>
    </>
  );
};

export default ManagementSeniorCareDevicesView;

import { FlexBox } from 'components/Helpers';
import styled from 'styled-components';

interface Props {
  label: string;
  value: string;
}

const LabelRow: React.FC<Props> = ({ label, value }) => {
  return (
    <FlexBox withgap>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </FlexBox>
  );
};

const Label = styled.div`
  width: 230px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: hsl(200, 10%, 60%);
  white-space: nowrap;

  span {
    display: none;
  }
`;

const Value = styled.div`
  color: hsl(200, 10%, 40%);
  display: flex;
  align-items: center;
  gap: 7px;
  white-space: nowrap;
  flex-direction: row;
`;

export default LabelRow;

import { FeatureRenderProps } from '../../../../defaults/featuresToDeviceProperties';
import SeniorCareIntegrationConfigForm from './SeniorCareIntegrationConfigForm';
import SeniorCareIntegrationConfigSummary from './SeniorCareIntegrationConfigSummary';

const SeniorCareIntegrationConfigFeature: React.FC<FeatureRenderProps> = ({
  values,
  onUpdate,
  isEditing,
}) => {
  if (isEditing) {
    return (
      <SeniorCareIntegrationConfigForm
        initialProperties={values}
        onSubmit={onUpdate}
      />
    );
  }

  return <SeniorCareIntegrationConfigSummary properties={values} />;
};

export default SeniorCareIntegrationConfigFeature;

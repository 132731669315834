import {
  deviceConfigurationKey,
  seniorCareDeviceManagementIntegrationConfigurationKey,
} from 'deviceMetadataKeys';
import { deviceDisplayNameLabel } from 'labels';

const wellKnownAdminDeviceLabels: Record<string, string[]> = {
  [deviceDisplayNameLabel]: [],
  [deviceConfigurationKey]: [],
  [seniorCareDeviceManagementIntegrationConfigurationKey]: [],
  'tuevnord.voize.de/device-management/device-configuration': [
    'production',
    'testing',
    'demo',
  ],
};

export default wellKnownAdminDeviceLabels;

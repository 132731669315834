import { Card, Select, Typography } from 'antd';
import { FlexBox } from 'components/Helpers';
import useOrganizations from 'hooks/organizations/useOrganizations';

interface Props {
  onSubmit: (organization: string) => void;
}

const OrganizationSwitcher: React.FC<Props> = ({ onSubmit }) => {
  const { organizations } = useOrganizations();

  if (organizations === undefined) return null;

  return (
    <Card>
      <Typography.Title level={4}>Select Organization</Typography.Title>

      <FlexBox withgap>
        <Select
          style={{
            width: '600px',
          }}
          placeholder="Organization"
          options={organizations.map((org) => ({
            value: org.id,
            label: org.name,
          }))}
          showSearch
          onChange={(value) => onSubmit(value)}
        />
      </FlexBox>
    </Card>
  );
};

export default OrganizationSwitcher;

import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { getRequiredEnv } from 'config';
import { Organization } from 'interfaces/organization';
import { useCallback } from 'react';
import {
  deleteOrganizationsScope,
  readOrganizationsScope,
  writeOrganizationsScope,
} from './devices/deviceManagementApiScopes';

const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

export const voizeDeviceManagementApi = Axios.create({
  baseURL: getRequiredEnv('VITE_DEVICE_MANAGEMENT_API_URL'),
});

export const useAdminOrganizationApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(
    async (...scopes: string[]) =>
      await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: scopes.join(' '),
        },
      }),
    [getAccessTokenSilently],
  );

  const getOrganizations = useCallback(async (): Promise<Organization[]> => {
    const accessToken = await getAccessToken(readOrganizationsScope);

    const { data } = await voizeDeviceManagementApi.get(
      '/v1/admin/organizations',
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  }, [getAccessToken]);

  const getOrganization = useCallback(
    async (organizationId: string): Promise<Organization> => {
      const accessToken = await getAccessToken(readOrganizationsScope);

      const { data } = await voizeDeviceManagementApi.get(
        `/v1/admin/organizations/${organizationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const postOrganization = useCallback(
    async (organization: Organization): Promise<Organization> => {
      const accessToken = await getAccessToken(writeOrganizationsScope);

      const { data } = await voizeDeviceManagementApi.post(
        '/v1/admin/organizations',
        organization,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return data;
    },
    [getAccessToken],
  );

  const putOrganization = useCallback(
    async (organization: Organization): Promise<void> => {
      const accessToken = await getAccessToken(writeOrganizationsScope);

      await voizeDeviceManagementApi.put(
        `/v1/admin/organizations/${organization.id}`,
        organization,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  const deleteOrganization = useCallback(
    async (organizationId: string): Promise<void> => {
      const accessToken = await getAccessToken(deleteOrganizationsScope);

      await voizeDeviceManagementApi.delete(
        `/v1/admin/organizations/${organizationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    [getAccessToken],
  );

  return {
    getOrganizations,
    getOrganization,
    postOrganization,
    putOrganization,
    deleteOrganization,
  };
};

import { useMemo, useState } from 'react';

const useJsonSearch = <T extends { id: string }>(items: T[]) => {
  const [searchText, setSearchText] = useState('');

  const jsonItems: Map<string, string> = useMemo(() => {
    if (!items) return new Map();
    const jsonMap = new Map();
    for (const request of items) {
      jsonMap.set(request.id, JSON.stringify(request));
    }
    return jsonMap;
  }, [items]);

  const filteredItems = useMemo(() => {
    return (
      items?.filter((request) => {
        const json = jsonItems.get(request.id);
        if (!json) return false;
        return json.toLowerCase().includes(searchText.toLowerCase());
      }) ?? []
    );
  }, [searchText, items, jsonItems]);

  return {
    filteredItems,
    searchText,
    setSearchText,
  };
};

export default useJsonSearch;

import { AxiosError, CanceledError } from 'axios';
import { isNetworkError } from './axiosUtil';

export class BaseError extends Error {
  constructor(
    message: string,
    public name: string,
    public isSilentRetryable: boolean = false,
    public extra: Record<string, any> = {},
  ) {
    super(message);
  }
}

export interface SerializedBaseError {
  message: string;
  name: string;
  isSilentRetryable: boolean;
  extra: Record<string, any>;
}

const USER_INFO_IS_SILENT_RETRYABLE_KEY = 'IS_SILENT_RETRYABLE';

export const toBaseError = (error: any): BaseError => {
  if (error instanceof BaseError) {
    return error;
  } else if (error instanceof CanceledError) {
    // subclass of AxiosError
    return new BaseError(
      `Request canceled: ${error.message}`,
      'AXIOS_CANCEL',
      true,
    );
  } else if (error instanceof AxiosError) {
    if (isNetworkError(error)) {
      return new BaseError(
        `Network Error: ${error.config?.url ?? '-'}`,
        'NETWORK',
        true,
      );
    } else if (error.request && error.response) {
      const isSilentRetryable =
        [502, 503, 504].includes(error.response.status) ||
        (error.response.status === 442 &&
          typeof error.response.data === 'object' &&
          error.response.data?.Message ===
            'Diese Aktion kann nur von angemeldeten Benutzern d…ührt werden (Token ist abgelaufen oder ungültig).');

      return new BaseError(
        `Response error for request ${error.config?.url ?? '-'} with code ${
          error.response.status
        }: ${error.message}`,
        isSilentRetryable ? 'NETWORK' : 'SERVER_RESPONSE',
        isSilentRetryable,
      );
    }
    return new BaseError(
      `Failed building request: ${JSON.stringify(error)}`,
      'AXIOS',
      false,
    );
  } else if (
    error instanceof Error &&
    typeof (error as any).userInfo === 'object' &&
    (error as any).userInfo !== null
  ) {
    const extra = (error as any).userInfo;
    const isSilentRetryable = extra[USER_INFO_IS_SILENT_RETRYABLE_KEY] == true; // also matches the number 1 from ios

    return new BaseError(error.message, error.name, isSilentRetryable, extra);
  } else if (error instanceof Error) {
    return new BaseError(error.message, error.name, false);
  } else if (typeof error === 'string') {
    return new BaseError(error, 'UNKOWN', false);
  } else if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'name' in error &&
    'isSilentRetryable' in error &&
    'extra' in error &&
    typeof error.message === 'string' &&
    typeof error.name === 'string' &&
    typeof error.isSilentRetryable === 'boolean' &&
    typeof error.extra === 'object' &&
    error.extra !== null
  ) {
    return deserializeBaseError(error as SerializedBaseError);
  } else {
    return new BaseError(JSON.stringify(error), 'UNKOWN', false);
  }
};

export function deserializeBaseError(
  serialized: SerializedBaseError,
): BaseError {
  return new BaseError(
    serialized.message,
    serialized.name,
    serialized.isSilentRetryable,
    serialized.extra,
  );
}

export function withContext(context: string, error: any): BaseError {
  const baseError = toBaseError(error);
  const newError = new BaseError(
    `${context}: ${baseError.message}`,
    baseError.name,
    baseError.isSilentRetryable,
    baseError.extra,
  );
  // preserve stack trace of original error
  newError.cause = baseError;
  return newError;
}

import { Descriptions, DescriptionsProps } from 'antd';
import { DeviceProperty } from 'defaults/deviceProperties';

interface Props {
  deviceProperty: DeviceProperty;
}
const DevicePropertyInfo: React.FC<Props> = ({ deviceProperty }) => {
  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Property',
      children: deviceProperty.name,
      span: 4,
    },
    {
      key: 'type',
      label: 'Typ',
      children: deviceProperty.type,
      span: 4,
    },
    {
      key: 'source',
      label: 'Source',
      children: deviceProperty.source,
      span: 4,
    },
    {
      key: 'description',
      children: deviceProperty.description,
      span: 4,
    },
    {
      key: 'default',
      label: 'Standarteinstellung',
      children: deviceProperty.default?.toString() ?? 'Keine',
      span: 4,
    },
  ];

  return <Descriptions items={items} />;
};

export default DevicePropertyInfo;

import { useMutation } from '@tanstack/react-query';
import { Form, Input, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAdminUserApi } from 'api/devices/useAdminUserApi';
import { AxiosError } from 'axios';
import useAntdIsFormSubmittable from 'hooks/useAntdIsFormSubmittable';
import getErrorMessage from 'utlis/getErrorMessage';

interface FormValues {
  password: string;
}

const AssignNewPasswordModal = (props: {
  isOpen?: boolean;
  onClose?: () => void;
  userId: string;
}) => {
  const [form] = useForm<FormValues>();
  const isFormSubmittable = useAntdIsFormSubmittable(form);

  const adminUserApi = useAdminUserApi();
  const mutation = useMutation({
    mutationFn: async (formValues: FormValues) => {
      await adminUserApi.putUser(props.userId, formValues);
    },
    onSuccess: () => {
      message.success('Neues Passwort erfolgreich vergeben');
      props.onClose?.();
    },
    onError: (error) => {
      const translations = new Map([
        ['Password must not be blank', 'Passwort darf nicht leer sein'],
        ['Password is too weak', 'Passwort ist zu schwach'],
      ]);

      if (
        error instanceof AxiosError &&
        error.response?.status === 400 &&
        translations.has(error.response.data)
      ) {
        form.setFields([
          {
            name: 'password',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            errors: [translations.get(error.response.data)!],
          },
        ]);
      } else {
        message.error(getErrorMessage(error));
      }
    },
  });

  return (
    <Modal
      cancelText="Abbrechen"
      confirmLoading={mutation.isPending}
      destroyOnClose
      okButtonProps={{ disabled: !isFormSubmittable }}
      okText="Speichern"
      onCancel={props.onClose}
      onOk={form.submit}
      open={props.isOpen}
      title="Neues Passwort vergeben"
    >
      <Form
        form={form}
        initialValues={{ password: '' }}
        onFinish={mutation.mutate}
        preserve={false}
        requiredMark={false}
      >
        <Form.Item
          label="Neues Passwort"
          name="password"
          rules={[
            {
              message: 'Passwort ist erforderlich',
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignNewPasswordModal;

import Editable from 'components/common/Editable';
import { Organization } from 'interfaces/organization';
import _ from 'lodash';

interface Props {
  metadataKey: string;
  organization: Organization;
  onUpdateWithDebounce?: (organization: Organization) => void;
  style?: React.CSSProperties;
}
const MetadataEditable: React.FC<Props> = ({
  onUpdateWithDebounce,
  organization,
  metadataKey,
  style,
}) => {
  return (
    <Editable
      onChange={_.debounce((value) => {
        onUpdateWithDebounce?.({
          ...organization,
          metadata: {
            ...organization.metadata,
            [metadataKey]: value,
          },
        });
      }, 500)}
      initialValue={organization.metadata[metadataKey]}
      style={style}
    />
  );
};

export default MetadataEditable;

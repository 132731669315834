import { Alert } from 'antd';
import LabelRow from 'components/common/LabelRow';
import { FlexBox } from 'components/Helpers';
import { useMemo } from 'react';
import { validateJsonProperty } from 'utlis/validationUtil';
import {
  integrationCacheServiceConfigPropertyId,
  integrationCacheServiceConfigSchema,
} from './IntegrationCacheServiceConfigFeature';

interface Props {
  properties: Record<string, string>;
}

const IntegrationCacheServiceConfigSummary: React.FC<Props> = ({
  properties,
}) => {
  const [config, validationError] = useMemo(
    () =>
      validateJsonProperty(
        properties[integrationCacheServiceConfigPropertyId],
        integrationCacheServiceConfigSchema,
      ),
    [properties],
  );

  return (
    <FlexBox direction="column">
      {validationError !== null && (
        <Alert
          message="Fehler"
          description={validationError}
          type="error"
          showIcon
        />
      )}
      {config !== null && (
        <FlexBox direction="column" withgap>
          <FlexBox direction="column">
            <LabelRow
              label="Cache Service Base URL"
              value={config.cacheServiceBaseUrl}
            />
            <LabelRow label="Cache Service Token" value={'*****'} />
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default IntegrationCacheServiceConfigSummary;

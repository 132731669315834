import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import SeniorCareAppUserTable from 'components/seniorcare/SeniorCareAppUserTable';
import React from 'react';
import { useNullablePathOrganization } from 'router';

const ManagementSeniorCareUsersView: React.FC = () => {
  const organization = useNullablePathOrganization();

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Benutzer',
            href: `/management/${organization}/users`,
          },
        ]}
      />
      <PageTitleBar title="Benutzer" Icon={ResourceIcons.User} />
      <PageContentContainer>
        <SeniorCareAppUserTable />
      </PageContentContainer>
    </>
  );
};

export default ManagementSeniorCareUsersView;

import useUserOrg from 'hooks/auth/useUserOrg';
import { PaginationQuery } from 'interfaces/PaginationQuery';
import { ManagementDevice, ManagementDevicePost } from 'interfaces/device';
import { ManagementApi } from 'interfaces/managementApi';
import { ManagementDeviceV3Dto } from 'interfaces/managementDeviceV3';
import { useCallback } from 'react';
import { useRawManagementApi } from './useRawManagementApi';

function useManagementApi(): ManagementApi {
  const rawApi = useRawManagementApi();
  const userOrganization = useUserOrg();

  /**
   * Transforms an AdminDevice into a ManagementDevice
   */
  const transformDevice = useCallback(
    (device: ManagementDeviceV3Dto): ManagementDevice => {
      return {
        id: device.id,
        organization: userOrganization,
        role: device.role,
        labels: device.labels,
        lastSeenAt: device.lastSeenAt,
        metadata: device.metadata,
        organizationConfigurations: device.organizationConfigurations,
        properties: device.properties,
      };
    },
    [],
  );

  const getDevices = useCallback(
    async (paginationQuery: PaginationQuery) =>
      (await rawApi.getDevices(paginationQuery)).map(transformDevice),
    [transformDevice],
  );

  const getDevice = useCallback(
    async (deviceId: string) => {
      const device = await rawApi.getDevice(deviceId);

      return transformDevice(device);
    },
    [transformDevice],
  );

  const postDevice = useCallback(
    async (device: ManagementDevicePost) => {
      const response = await rawApi.postDevice(device);

      return {
        id: response.id,
        organization: userOrganization,
      };
    },
    [rawApi, userOrganization],
  );

  return {
    organization: userOrganization,
    ...rawApi,
    getDevice,
    getDevices,
    postDevice,
  };
}

export default useManagementApi;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, Modal } from 'antd';
import ManagementDeviceActivationModal from 'components/device-activation/ManagementDeviceActivationModal';
import { useManagementApiFromContext } from 'components/scaffold/OrganizationView';
import { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { useState } from 'react';
import getErrorMessage from 'utlis/getErrorMessage';
import DevicesActivationRequestInfo from './DevicesActivationRequestInfo';

interface Props {
  activationRequest: ManagementDeviceActivationRequest;
  isIdCollapsed?: boolean;
  searchText?: string;
  toggleSelect?: (id: string) => void;
  isChecked?: boolean;
}
const ManagementDeviceActivationRequestInfo: React.FC<Props> = (props) => {
  const { activationRequest } = props;

  const queryClient = useQueryClient();
  const { deleteDeviceActivationRequest, postDeviceActivationRequestApprove } =
    useManagementApiFromContext();

  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const { mutateAsync: remove } = useMutation({
    mutationFn: () => deleteDeviceActivationRequest(activationRequest.id),

    onSuccess: () => {
      Modal.success({
        title: 'Anfrage gelöscht',
        content: `Die Anfrage mit der ID ${activationRequest.id} wurde gelöscht.`,
      });
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
    },
    onError: (error: any) => {
      Modal.error({
        title: 'Anfrage konnte nicht gelöscht werden',
        content: `Die Anfrage mit der ID ${
          activationRequest.id
        } konnte nicht gelöscht werden. ${getErrorMessage(error)}`,
      });
    },
  });

  const { mutateAsync: approveActivationRequest } = useMutation({
    mutationFn: ({
      deviceRole,
      deviceLabels,
    }: {
      deviceRole: string;
      deviceLabels: Record<string, string>;
    }) => {
      return postDeviceActivationRequestApprove(
        activationRequest.id,
        deviceRole,
        deviceLabels,
      );
    },
    onSuccess: () => {
      message.success('Anfrage angenommen');
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
      setIsActivationModalOpen(false);
    },
    onError: (error: any) => {
      message.error(getErrorMessage(error));
      queryClient.invalidateQueries({
        queryKey: ['management-device-activation-requests'],
      });
    },
  });

  return (
    <>
      <ManagementDeviceActivationModal
        open={isActivationModalOpen}
        activationRequest={activationRequest}
        onClose={() => setIsActivationModalOpen(false)}
        onActivate={(deviceRole, deviceLabels) =>
          approveActivationRequest({ deviceRole, deviceLabels })
        }
      />

      <DevicesActivationRequestInfo
        {...props}
        remove={remove}
        setIsActivationModalOpen={setIsActivationModalOpen}
      />
    </>
  );
};

export default ManagementDeviceActivationRequestInfo;

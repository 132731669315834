import Axios from 'axios';
import { getRequiredEnv } from 'config';
import { UserDeviceActivationRequest } from 'interfaces/UserDeviceActivationRequest';
import { useCallback } from 'react';

export const voizeDeviceManagementApi = Axios.create({
  baseURL: getRequiredEnv('VITE_DEVICE_MANAGEMENT_API_URL'),
});

export const useUserApi = () => {
  const getDeviceActivationRequest = useCallback(
    async (id: string, token: string): Promise<UserDeviceActivationRequest> => {
      const headers = {
        'x-token': token,
      };

      const { data } =
        await voizeDeviceManagementApi.get<UserDeviceActivationRequest>(
          `/v1/user/device-activation-request/${id}`,
          {
            headers,
          },
        );

      return data;
    },
    [],
  );

  const postDeviceActivationRequestVerifyEmail = useCallback(
    async (id: string, token: string): Promise<void> => {
      const headers = {
        'x-token': token,
      };

      await voizeDeviceManagementApi.post(
        `/v1/user/device-activation-request/${id}/verify-email`,
        {},
        {
          headers,
        },
      );
    },
    [],
  );

  return {
    getDeviceActivationRequest,
    postDeviceActivationRequestVerifyEmail,
  };
};

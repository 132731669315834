import styled from 'styled-components';

interface Props {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  placeholder?: string;
  initialValue?: string | undefined;
  isLoading?: boolean;
  style?: React.CSSProperties;
}
const Editable: React.FC<Props> = ({
  onChange,
  initialValue,
  onBlur,
  isLoading,
  placeholder,
  style,
}) => {
  return (
    <Input
      style={style}
      onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
        e.stopPropagation()
      }
      defaultValue={initialValue}
      onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
        onBlur?.(e.target.value);
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange?.(e.target.value)
      }
      readOnly={isLoading}
      placeholder={placeholder}
    />
  );
};

export default Editable;

const Input = styled.input`
  all: unset;
  background: #fbfbfb;
  padding: 10px;
  border-radius: 10px;
  cursor: text;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    background: #e0e0e0;
    box-shadow: 0 0 0 2px #6e6e6e inset;
  }

  &:empty {
    min-height: 1em;
  }
`;

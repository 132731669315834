import { useAdminOrganizationApi } from 'api/useAdminOrganizationApi';
import { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import { OrganizationDeviceConfiguration } from 'interfaces/adminOrganizationDeviceConfig';
import {
  ManagementDevice,
  ManagementDevicePost,
  ManagementDevicePostResponse,
} from 'interfaces/device';
import { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { ManagementApi } from 'interfaces/managementApi';
import {
  ManagementDeviceConfiguration,
  PostManagementDeviceConfiguration,
  PutManagementDeviceConfiguration,
} from 'interfaces/managementDeviceConfiguration';
import { PaginationQuery } from 'interfaces/PaginationQuery';
import { useCallback } from 'react';
import { useAdminDeviceApi } from './useAdminDeviceApi';
import { useAdminDeviceApiV4 } from './useAdminDeviceApiV4';

const useAdminToManagementApi = (organization: string): ManagementApi => {
  const {
    getAdminDeviceActivationRequests,
    getOrganizationDeviceConfigurations,
    getOrganizationDeviceConfiguration,
    postOrganizationDeviceConfiguration,
    putOrganizationDeviceConfiguration,
    deleteOrganizationDeviceConfiguration,
    postAdminDeviceActivationRequestApprove,
    deleteAdminDeviceActivationRequest,
  } = useAdminDeviceApi();

  const {
    getDevices: getDevicesAdmin,
    getDevice: getDeviceAdmin,
    postDevice: postDeviceAdmin,
    patchDeviceLabels: patchDeviceLabelsAdmin,
    deleteDevice: deleteDeviceAdmin,
    getDeviceActivationCode: getAdminDeviceActivationCode,
    resetDeviceSecret: resetAdminDeviceSecret,
    updateDeviceProperties,
  } = useAdminDeviceApiV4();

  const { getOrganization: getOrganizationById } = useAdminOrganizationApi();

  const getOrganization = useCallback(async () => {
    const organizationData = await getOrganizationById(organization);

    return {
      name: organizationData.name,
      metadata: organizationData.metadata,
    };
  }, [getOrganizationById, organization]);

  // Devices

  /**
   * Transforms an AdminDevice into a ManagementDevice
   */
  const transformDevice = useCallback(
    (device: AdminDeviceV4Dto): ManagementDevice => {
      return {
        id: device.id,
        organization: device.organization,
        role: device.role,
        labels: device.labels,
        lastSeenAt: device.lastSeenAt,
        metadata: device.metadata,
        organizationConfigurations: device.organizationConfigurations,
        properties: device.properties,
      };
    },
    [],
  );

  const getDevices = useCallback(
    async (paginationQuery: PaginationQuery) =>
      (await getDevicesAdmin(paginationQuery, organization)).map(
        transformDevice,
      ),
    [organization, getDevicesAdmin, transformDevice],
  );

  const getDevice = useCallback(
    async (deviceId: string) => {
      const device = await getDeviceAdmin(deviceId);

      return transformDevice(device);
    },
    [getDeviceAdmin, transformDevice],
  );

  const postDevice = useCallback(
    async (
      device: ManagementDevicePost,
    ): Promise<ManagementDevicePostResponse> => {
      const response = await postDeviceAdmin({
        organization: organization,
        role: device.role,
        labels: device.labels,
      });

      return {
        id: response.id,
        organization: response.organization,
      };
    },
    [organization, postDeviceAdmin],
  );

  const patchDeviceLabels = useCallback(
    (deviceId: string, labels: Record<string, string | null>) =>
      patchDeviceLabelsAdmin(deviceId, labels),
    [patchDeviceLabelsAdmin],
  );

  const deleteDevice = useCallback(
    (deviceId: string) => deleteDeviceAdmin(deviceId),
    [deleteDeviceAdmin],
  );

  // Device Activation Requests
  const getDeviceActivationRequests = useCallback(async () => {
    const transformDeviceActivationRequest = (
      request: DeviceActivationRequest,
    ): ManagementDeviceActivationRequest => ({
      id: request.id,
      userName: request.userName,
      userEmail: request.userEmail,
      metadata: request.metadata,
      labels: request.labels,
      deviceId: request.deviceId,
      createdAt: request.createdAt,
      userEmailVerifiedAt: request.userEmailVerifiedAt,
      approvedAt: request.approvedAt,
    });

    const activationRequests =
      await getAdminDeviceActivationRequests(organization);

    return activationRequests.map(transformDeviceActivationRequest);
  }, [organization, getAdminDeviceActivationRequests]);

  const postDeviceActivationRequestApprove = useCallback(
    (
      deviceActivationRequestId: string,
      deviceRole: string,
      deviceLabels: Record<string, string>,
    ) =>
      postAdminDeviceActivationRequestApprove(
        deviceActivationRequestId,
        deviceRole,
        deviceLabels,
      ),
    [postAdminDeviceActivationRequestApprove],
  );

  const deleteDeviceActivationRequest = useCallback(
    (requestId: string) => deleteAdminDeviceActivationRequest(requestId),
    [deleteAdminDeviceActivationRequest],
  );

  // Device Secrets

  const getDeviceActivationCode = useCallback(
    (id: string) => getAdminDeviceActivationCode(id),
    [getAdminDeviceActivationCode],
  );

  const resetDeviceSecret = useCallback(
    (deviceId: string) => resetAdminDeviceSecret(deviceId),
    [resetAdminDeviceSecret],
  );

  // Device Configurations

  const getManagementDeviceConfigurations = useCallback(async () => {
    const orgDeviceConfigs =
      await getOrganizationDeviceConfigurations(organization);

    return orgDeviceConfigs.map(transformOrganizationDeviceConfig);
  }, [getOrganizationDeviceConfigurations, organization]);

  const getManagementDeviceConfiguration = useCallback(
    async (configId: string) => {
      const orgDeviceConfig =
        await getOrganizationDeviceConfiguration(configId);

      if (orgDeviceConfig.organization !== organization) {
        throw Error('Organization mismatch');
      }

      return transformOrganizationDeviceConfig(orgDeviceConfig);
    },
    [organization, getOrganizationDeviceConfiguration],
  );

  const postManagementDeviceConfiguration = useCallback(
    async (config: PostManagementDeviceConfiguration) => {
      const orgDeviceConfig = await postOrganizationDeviceConfiguration({
        name: config.name,
        organization,
        properties: config.properties,
        deviceSelector: config.deviceSelector,
        priority: config.priority,
      });

      return transformOrganizationDeviceConfig(orgDeviceConfig);
    },
    [organization, postOrganizationDeviceConfiguration],
  );

  const putManagementDeviceConfiguration = useCallback(
    (configId: string, config: PutManagementDeviceConfiguration) =>
      putOrganizationDeviceConfiguration(configId, {
        name: config.name,
        properties: config.properties,
        deviceSelector: config.deviceSelector,
        priority: config.priority,
      }),
    [putOrganizationDeviceConfiguration],
  );

  const deleteManagementDeviceConfiguration = useCallback(
    (configId: string) => deleteOrganizationDeviceConfiguration(configId),
    [deleteOrganizationDeviceConfiguration],
  );

  return {
    organization,
    getOrganization,
    getDevices,
    getDevice,
    postDevice,
    patchDeviceLabels,
    deleteDevice,
    resetDeviceSecret,
    updateDeviceProperties,
    getDeviceActivationRequests,
    getDeviceActivationCode,
    postDeviceActivationRequestApprove,
    getManagementDeviceConfigurations,
    getManagementDeviceConfiguration,
    postManagementDeviceConfiguration,
    putManagementDeviceConfiguration,
    deleteManagementDeviceConfiguration,
    deleteDeviceActivationRequest,
  };
};

/**
 * Transforms an OrganizationDeviceConfiguration into a ManagementDeviceConfiguration
 */
export const transformOrganizationDeviceConfig = (
  dc: OrganizationDeviceConfiguration,
): ManagementDeviceConfiguration => ({
  id: dc.id,
  name: dc.name,
  properties: dc.properties,
  deviceSelector: dc.deviceSelector,
  priority: dc.priority,
  devicesCount: dc.devicesCount,
});

export default useAdminToManagementApi;

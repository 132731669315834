import { Flex, Modal, Switch } from 'antd';
import { useAtom } from 'jotai';
import { isUserAdvancedAtom, shouldAnonymiseTelemetryAtom } from 'state/state';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SettingsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [isUserAdvanced, setIsUserAdvanced] = useAtom(isUserAdvancedAtom);
  const [shouldAnonymiseTelemetry, setShouldAnonymiseTelemetry] = useAtom(
    shouldAnonymiseTelemetryAtom,
  );

  return (
    <Modal
      footer={null}
      onCancel={onClose}
      open={isOpen}
      title="Einstellungen"
      width={300}
    >
      <Flex gap="small" vertical>
        <Flex justify="space-between">
          <span>Expertenmodus</span>
          <Switch
            checked={isUserAdvanced}
            onChange={(checked) => {
              setIsUserAdvanced(checked);
            }}
          />
        </Flex>
        <Flex justify="space-between">
          <span>Telemetrie anonymisieren</span>
          <Switch
            checked={shouldAnonymiseTelemetry}
            onChange={(checked) => {
              setShouldAnonymiseTelemetry(checked);
            }}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SettingsModal;

import { Tag } from 'antd';
import { FlexBox } from 'components/Helpers';
import metadataCommonNames from 'defaults/metadataCommonNames';

interface Props {
  metadata: Record<string, string>;
}
const Metadata: React.FC<Props> = ({ metadata }) => {
  return (
    <FlexBox wrap withgap gap={6}>
      {Object.entries(metadata).map(([key, value]) => {
        const commonName =
          metadataCommonNames[key as keyof typeof metadataCommonNames] ?? key;

        return (
          <Tag key={key} style={{ margin: 0 }}>
            {commonName}: {value}
          </Tag>
        );
      })}
    </FlexBox>
  );
};

export default Metadata;

import styled from 'styled-components';

const PageContentContainer = styled.div`
  padding: 20px 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export default PageContentContainer;

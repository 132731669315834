import { Button, Form, Input, Select } from 'antd';
import {
  IntegrationAdapterType,
  integrationTypeName,
  TICIntegrationConfig,
  ticIntegrationConfigSchema,
} from 'interfaces/ticIntegrationConfig';
import Joi from 'joi';
import { useMemo, useState } from 'react';
import { validateJsonProperty, validateOrThrow } from 'utlis/validationUtil';

interface Props {
  initialProperties?: Record<string, string>;
  onSubmit: (config: Record<string, string>) => void;
}

type FormData = TICIntegrationConfig;

const TICIntegrationConfigForm: React.FC<Props> = ({
  initialProperties = {},
  onSubmit,
}) => {
  const formId = 'tic-integration-config-feature';
  const [form] = Form.useForm<FormData>();

  const [tuevNordIntegrationConfig] = useMemo(
    () =>
      validateJsonProperty(
        initialProperties[
          'TIC_APP_INTEGRATION_ADAPTER_TUEV_NORD_CONFIGURATION'
        ],
        Joi.object({
          baseUrl: Joi.string().uri().optional().allow(''),
        }),
      ),
    [initialProperties],
  );

  const [selectedIntegrationAdapterType, setSelectedIntegrationAdapterType] =
    useState<null | IntegrationAdapterType>(
      (initialProperties.TIC_APP_INTEGRATION_ADAPTER_TYPE as IntegrationAdapterType) ??
        null,
    );

  return (
    <Form<FormData>
      id={formId}
      form={form}
      labelAlign="left"
      labelCol={{ span: 6 }}
      labelWrap={true}
      onFinish={(formData) => {
        const integrationConfig = validateOrThrow(
          formData,
          ticIntegrationConfigSchema,
        );

        onSubmit({
          TIC_APP_INTEGRATION_ADAPTER_TYPE: integrationConfig.type,
          TIC_APP_INTEGRATION_ADAPTER_TUEV_NORD_CONFIGURATION: JSON.stringify({
            baseUrl: integrationConfig.baseUrl,
          }),
        });
      }}
      initialValues={{
        type: initialProperties.TIC_APP_INTEGRATION_ADAPTER_TYPE,
        baseUrl: tuevNordIntegrationConfig?.baseUrl ?? '',
      }}
    >
      <Form.Item
        label="Integrations-Adapter"
        name="type"
        rules={[{ required: true }]}
      >
        <Select
          options={Object.values(IntegrationAdapterType).map((type) => ({
            label: integrationTypeName[type],
            value: type,
          }))}
          onChange={(value) => setSelectedIntegrationAdapterType(value)}
        />
      </Form.Item>
      {selectedIntegrationAdapterType === IntegrationAdapterType.TUEV_NORD && (
        <Form.Item
          label="Integrations-URL"
          name="baseUrl"
          rules={[
            {
              required: true,
              type: 'url',
              message: 'Bitte geben Sie eine gültige URL ein.',
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      <Button
        type="primary"
        key="submit"
        form={formId}
        onClick={() => form.submit()}
      >
        Speichern
      </Button>
    </Form>
  );
};

export default TICIntegrationConfigForm;

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { Alert, Button, message, Popconfirm, Skeleton, Space } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import Breadcrumb from 'components/common/Breadcrumb';
import IdTag from 'components/common/IdTag';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import DeviceSelectorVizualizer from 'components/device-configuration/DeviceSelectorVisualizer';
import useGlobalDeviceConfigurations from 'hooks/useGlobalDeviceConfigurations';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { GlobalDeviceConfiguration } from 'interfaces/adminGlobalDeviceConfig';
import { useMemo } from 'react';
import getErrorMessage from 'utlis/getErrorMessage';
import DevicePropertiesList from './DevicePropertiesList';

const AdminGlobalDeviceConfigurationView: React.FC = () => {
  const { deleteGlobalDeviceConfiguration } = useAdminDeviceApi();
  const { postUpdateAllDeviceProperties } = useAdminDeviceApiV4();

  const {
    data: configurations,
    refetch: refreshConfigurations,
    isLoading: isDeviceConfigsLoading,
    isError: isDeviceConfigError,
  } = useGlobalDeviceConfigurations();

  const { mutateAsync: removeGlobalDeviceConfiguration } = useMutation({
    mutationFn: deleteGlobalDeviceConfiguration,

    onSuccess: () => {
      refreshConfigurations();
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    },
  });

  const getColumnSearchProps =
    useTableSearchFilter<GlobalDeviceConfiguration>();

  const columns = useMemo<ColumnsType<GlobalDeviceConfiguration>>(
    () => [
      {
        title: 'ID',
        width: '80px',
        sorter: (a, b) => a.id.localeCompare(b.id),
        ...getColumnSearchProps(
          'id',
          'id',
          (record) => record.id,
          (record, _, searchText) => (
            <IdTag id={record.id} highlight={searchText} />
          ),
        ),
      },
      {
        title: 'Anzahl der Geräte',
        width: '40px',
        sorter: (a, b) => a.devicesCount - b.devicesCount,
        render: (_, config) => config.devicesCount,
      },
      {
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps('name', 'name', (record) => record.name),
      },
      {
        title: 'Priority',
        sorter: (a, b) => a.priority - b.priority,
        render: (_, config) => config.priority,
      },
      {
        title: 'Properties',
        ...getColumnSearchProps(
          'properties',
          'properties',
          (record) => Object.keys(record.properties).join(', '),
          (config) => (
            <DevicePropertiesList deviceProperties={config.properties} />
          ),
        ),
      },
      {
        title: 'Device Selector',
        width: '50%',
        render: (_, config) => (
          <DeviceSelectorVizualizer deviceSelector={config.deviceSelector} />
        ),
      },
      {
        title: 'Actions',
        render: (_, config) => (
          <Space>
            <Link
              to="/admin/global-device-configurations/$globalDeviceConfigurationId/edit"
              params={{
                globalDeviceConfigurationId: config.id,
              }}
            >
              <Button type="text" icon={<EditOutlined />} />
            </Link>
            <Popconfirm
              title="Are you sure delete this Config?"
              onConfirm={() => removeGlobalDeviceConfiguration(config.id)}
              okText="Yes"
            >
              <Button danger type="text" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [getColumnSearchProps, removeGlobalDeviceConfiguration],
  );

  return (
    <>
      <Breadcrumb
        items={[
          {
            name: 'Globale Gerätekonfigurationen',
            href: '/admin/global-device-configurations',
          },
        ]}
      />
      <PageTitleBar
        title="Globale Gerätekonfigurationen"
        Icon={ResourceIcons.GlobalDeviceConfiguration}
        renderToolbar={() => (
          <>
            <Button
              onClick={() => postUpdateAllDeviceProperties()}
              icon={<ReloadOutlined />}
            >
              Update All Device Properties
            </Button>
            <Link to="/admin/global-device-configurations/create">
              <Button icon={<PlusOutlined />}>Add Config</Button>
            </Link>
          </>
        )}
      />
      <PageContentContainer>
        {isDeviceConfigError && (
          <Alert type="error" message="Something went wrong" />
        )}
        {isDeviceConfigsLoading ? (
          <Skeleton active />
        ) : (
          <Table
            dataSource={configurations}
            columns={columns}
            rowKey={(dc) => dc.id}
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default AdminGlobalDeviceConfigurationView;

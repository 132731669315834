import { PaginationProps } from 'antd';
import { useEffect, useState } from 'react';

const useAntdPagination = (total: number) => {
  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    onChange: (current, pageSize) => {
      setPagination((pagination) => ({
        ...pagination,
        current,
        pageSize,
      }));
    },
    pageSize: 50,
    pageSizeOptions: ['50', '100', '500', '1000', '5000', '10000'],
    showSizeChanger: true,
    total,
  });

  useEffect(() => {
    setPagination((pagination) => ({
      ...pagination,
      total,
    }));
  }, [total]);

  return pagination;
};

export default useAntdPagination;

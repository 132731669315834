import { AxiosError } from 'axios';

/**
 * check for response status 0 is a workaround for axios bug that response is not empty on network error
 * see: https://github.com/axios/axios/issues/4655 and https://github.com/axios/axios/issues/4709
 */
export const isNetworkError = (axiosError: AxiosError) => {
  return (
    axiosError.request &&
    (!axiosError.response ||
      axiosError.response.status === 0 ||
      axiosError.message === 'Network Error')
  );
};

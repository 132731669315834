import { BiBuilding, BiUser } from 'react-icons/bi';
import { FaSignature } from 'react-icons/fa6';
import { FiSmartphone } from 'react-icons/fi';
import {
  TbCloud,
  TbDeviceMobileCog,
  TbHome,
  TbHomeCog,
  TbMailPlus,
  TbWorldCog,
} from 'react-icons/tb';

const ResourceIcons = {
  Organization: BiBuilding,
  Contract: FaSignature,
  User: BiUser,
  Device: FiSmartphone,
  DeviceConfiguration: TbDeviceMobileCog,
  Integration: TbCloud,
  GlobalDeviceConfiguration: TbWorldCog,
  OrganizationDeviceConfiguration: TbHomeCog,
  ActivationRequest: TbMailPlus,
  Home: TbHome,
};

export default ResourceIcons;

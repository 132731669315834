import { Tooltip } from 'antd';
import { JSONDeviceProperty } from 'defaults/deviceProperties';
import CodeEditor, { Language } from '../CodeEditor';

interface Props {
  deviceProperty: JSONDeviceProperty;
  onChange: (value: string) => void;
  value: string;
  style?: React.CSSProperties;
}

const JSONDevicePropertyValue: React.FC<Props> = ({
  deviceProperty,
  onChange,
  value,
  style,
}) => {
  const isDefault = deviceProperty.default === value;

  return (
    <Tooltip
      title={isDefault ? 'Standardwert' : undefined}
      mouseEnterDelay={0.3}
    >
      <CodeEditor
        language={Language.JSON}
        onChange={onChange}
        style={style}
        value={value}
      />
    </Tooltip>
  );
};

export default JSONDevicePropertyValue;

const organizationTypes = [
  'voize-senior-care-app-customer',
  'voize-tic-app-customer',
  'voize-sdk-customer',
  'voize-standardized-api-developer',
  'voize-sdk-developer',
  'voize-internal',
  'integration-partner',
  'customer',
] as const;

export default organizationTypes;

import { GlobalToken, theme } from 'antd';
import React, { PropsWithChildren } from 'react';
import { ThemeProvider as DefaultThemeProvider } from 'styled-components';

export const getTheme = (token: GlobalToken) => ({
  antd: token,
});

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { token } = theme.useToken();

  return (
    <DefaultThemeProvider theme={getTheme(token)}>
      {children}
    </DefaultThemeProvider>
  );
};

export default ThemeProvider;

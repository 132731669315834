import { Tag } from 'antd';
import { FlexBox } from 'components/Helpers';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { shouldForwardProp } from 'utlis/styledPropforwarding';

interface Props {
  tags: string[];
  isEditable?: boolean;
  lowercase?: boolean;
  isSet?: boolean;
  onChange?: (tags: string[]) => void;
}

const TagList: React.FC<Props> = ({
  tags,
  isEditable,
  lowercase,
  isSet,
  onChange,
}) => {
  const [currentTag, setCurrentTag] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const checkEnterAndAddTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      if (isSet && tags.includes(currentTag)) return;
      const newTags = [...tags, currentTag];
      onChange && onChange(newTags);
      inputRef.current && (inputRef.current.value = '');
      setCurrentTag('');
    }
  };

  const changeTag = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTag(lowercase ? target.value.toLowerCase() : target.value);
  };

  const removeTag = (deleteTag: string) => {
    const newTags = tags.filter((tag) => tag != deleteTag);
    onChange && onChange(newTags);
  };

  return (
    <TagInput borderless={!isEditable}>
      {tags.map((tag, index) => (
        <Tag
          key={tag + index}
          closable={isEditable}
          onClose={() => removeTag(tag)}
        >
          {tag}
        </Tag>
      ))}
      {isEditable && (
        <input
          ref={inputRef}
          style={{
            flex: 1,
            width: 'auto',
            minWidth: 0,
            border: 'none',
            outlineWidth: 0,
            margin: 0,
          }}
          onKeyDown={checkEnterAndAddTag}
          onChange={changeTag}
        />
      )}
    </TagInput>
  );
};

export default TagList;

export const TagInput = styled.div.withConfig({
  shouldForwardProp: shouldForwardProp(['borderless']),
})<{ borderless: boolean }>`
  ${(props) =>
    props.borderless
      ? ''
      : `  
  padding: 8px;
  border: 1px solid hsl(200, 10%, 90%);
  border-radius: 5px;
  `}

  display: flex;
  flex-wrap: wrap;
  gap: 5px 0;
`;

export const TagInputLabel = styled.label`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: hsl(200, 10%, 50%);
  margin-left: 10px;
`;

export const TagsBox: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <FlexBox direction="column" withgap gap={2}>
      <TagInputLabel>{title}</TagInputLabel>
      <TagInput borderless={false}>{children}</TagInput>
    </FlexBox>
  );
};

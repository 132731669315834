export const deviceConfigurationKey =
  'voize.de/device-management/device-configuration';

export const seniorCareDeviceManagementIntegrationConfigurationKey =
  'senior-care.app.voize.de/device-management/integration-configuration';

export const seniorCareDeviceManagementResetDataConfigurationKey =
  'senior-care.app.voize.de/device-management/reset-data-configuration';

// voize-app-senior-care
export const voizeAppSeniorCareVersionKey = 'voize-app-senior-care/version';
export const voizeAppSeniorCareReleaseTrackKey =
  'voize-app-senior-care/release-track';

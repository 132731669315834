import { useQuery } from '@tanstack/react-query';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import DeviceActivationCodeModal from 'components/device/DeviceActivationCodeModal';

interface Props {
  onClose?: () => void;
  deviceId: string;
}
const AdminDeviceActivationCodeModal: React.FC<Props> = ({
  onClose,
  deviceId,
}) => {
  const { getDeviceActivationCode } = useAdminDeviceApiV4();

  const {
    data: activationCode,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['devices', deviceId, 'activation-code'],
    queryFn: () => getDeviceActivationCode(deviceId),
    staleTime: 0,
  });

  return (
    <DeviceActivationCodeModal
      onClose={onClose}
      deviceId={deviceId}
      activationCode={activationCode ?? null}
      isLoading={isLoading}
      error={error ?? null}
    />
  );
};

export default AdminDeviceActivationCodeModal;

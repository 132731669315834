import React, { useMemo } from 'react';

import { Alert, Button, Form, Input, InputNumber, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import DeviceLabelSelectors from 'components/common/device-labels/DeviceLabelSelectors';
import DevicePropertiesForm from 'components/common/device-properties/DevicePropertiesForm';
import device from 'defaults/deviceRoles';
import useOrganizationIds from 'hooks/organizations/useOrganizationIds';
import {
  GlobalDeviceConfiguration,
  PostGlobalDeviceConfiguration,
} from 'interfaces/adminGlobalDeviceConfig';
import { MatchExpression, SetMatch } from 'interfaces/matchers';
import getErrorMessage from 'utlis/getErrorMessage';
import SetMatchSelector from './selectors/SetMatchSelector';

interface Props {
  initialValues?: GlobalDeviceConfiguration;
  create?: boolean;
  readonly?: boolean;
  onSubmit?: (dc: PostGlobalDeviceConfiguration) => void | Promise<void>;
  isLoading?: boolean;
  error: any;
}

interface FormData {
  name: string;
  properties: Record<string, string>;
  priority: number;
  matchLabels: MatchExpression[] | undefined;
  matchRole: SetMatch | undefined;
  matchOrganization: SetMatch | undefined;
}

const GlobalDeviceConfigurationForm: React.FC<Props> = ({
  initialValues,
  readonly = false,
  create,
  onSubmit,
  isLoading,
  error,
}) => {
  const orgs = useOrganizationIds();

  const [form] = useForm<FormData>();

  const inits = useMemo<FormData>(() => {
    if (initialValues === undefined) {
      return {
        name: '',
        priority: 100,
        properties: {},
        matchLabels: undefined,
        matchRole: undefined,
        matchOrganization: undefined,
      };
    } else {
      return {
        name: initialValues.name,
        properties: initialValues.properties,
        matchLabels: initialValues.deviceSelector.matchLabels,
        matchRole: initialValues.deviceSelector.matchRole,
        matchOrganization: initialValues.deviceSelector.matchOrganization,
        priority: 100,
      };
    }
  }, [initialValues]);

  const transformFormValues = (
    values: FormData,
  ): PostGlobalDeviceConfiguration => {
    return {
      name: values.name,
      properties: values.properties,
      deviceSelector: {
        matchLabels: values.matchLabels,
        matchRole: values.matchRole,
        matchOrganization: values.matchOrganization,
      },
      priority: values.priority,
    };
  };

  const formId = 'device-config-form';

  return (
    <Form<FormData>
      labelAlign="left"
      labelCol={{ span: 3 }}
      initialValues={inits}
      id={formId}
      onFinish={(values) => {
        const deviceConfig = transformFormValues(values);
        onSubmit?.(deviceConfig);
      }}
      form={form}
      style={{ width: '900px' }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Geben Sie einen Namen ein.' }]}
        hasFeedback
      >
        <Input readOnly={readonly} />
      </Form.Item>

      <Form.Item name="properties" noStyle />

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.properties !== currentValues.properties
        }
      >
        {({ getFieldValue, setFieldValue }) => {
          return (
            <DevicePropertiesForm
              value={getFieldValue('properties') ?? {}}
              onChange={(properties) => setFieldValue('properties', properties)}
            />
          );
        }}
      </Form.Item>

      <Typography.Title level={5}>Selektoren</Typography.Title>
      <Form.Item label="Labels">
        <DeviceLabelSelectors />
      </Form.Item>

      <Form.Item name="matchRole" label="Rollen">
        <SetMatchSelector
          options={device.map((role) => ({
            label: role,
            value: role,
          }))}
        />
      </Form.Item>

      <Form.Item name="matchOrganization" label="Organisationen">
        <SetMatchSelector
          options={orgs.map((org) => ({
            label: org,
            value: org,
          }))}
          freeText
        />
      </Form.Item>

      <Form.Item
        label="Priorität"
        name="priority"
        rules={[
          { required: true, message: 'Geben Sie eine Priorität ein.' },
          {
            type: 'number',
            min: 0,
            message: 'Geben Sie eine Zahl größer als 0 ein.',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>

      {error && <Alert type="error" message={getErrorMessage(error)} />}

      <Button
        type="primary"
        form={formId}
        htmlType="submit"
        loading={isLoading}
      >
        {create ? 'Erstellen' : 'Speichern'}
      </Button>
    </Form>
  );
};

export default GlobalDeviceConfigurationForm;

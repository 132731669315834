export const labelFirstname =
  'device-management.voize.de/device/user/firstname';
export const labelLastname = 'device-management.voize.de/device/user/lastname';
export const labelEmailAddress =
  'device-management.voize.de/device/user/email-address';
export const labelSalutation =
  'device-management.voize.de/device/user/salutation';

export const activationRequestsMetadataNamespace =
  'device-management.voize.de/device-activation-request/metadata';
export const deviceRoleLabel = `${activationRequestsMetadataNamespace}/device-role`;
export const commentLabel = `${activationRequestsMetadataNamespace}/comment`;
export const organizationLabel = `${activationRequestsMetadataNamespace}/organization`;
export const areaLabel = `${activationRequestsMetadataNamespace}/area`;
export const sourceLabel = `${activationRequestsMetadataNamespace}/source`;
export const industryLabel = `${activationRequestsMetadataNamespace}/industry`;
export const languageLabel = `${activationRequestsMetadataNamespace}/language`;
export const platformLabel = `${activationRequestsMetadataNamespace}/platform`;

export const deviceCreatedAtLabel =
  'device-management.voize.de/device/created-at';

export const deviceDisplayNameLabel =
  'device-management.voize.de/device/display-name';

export const deviceConfigurationLabel =
  'voize.de/device-management/device-configuration';

import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAtom } from 'jotai';
import { isSidebarCollapsedAtom } from 'state/state';
import Sidebar, { sidebarWidth } from './Sidebar';

interface Props {
  children?: React.ReactNode;
}

const Scaffold: React.FC<Props> = ({ children }) => {
  const [isSidebarCollapsed] = useAtom(isSidebarCollapsedAtom);

  return (
    <Layout
      style={{
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'white',
        minHeight: '100%',
      }}
    >
      <Sidebar />
      <Content
        style={{
          display: 'flex',
          minHeight: '100%',
          marginLeft: isSidebarCollapsed ? 0 : sidebarWidth,
          flexDirection: 'column',
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default Scaffold;

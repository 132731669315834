import { AuthContext } from 'components/scaffold/AuthProvider';
import { useContext } from 'react';

const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return authContext;
};

export default useAuthContext;

import { Form } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import CodeEditor, { Language } from 'components/common/CodeEditor';
import styled from 'styled-components';
import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';
import { fromError } from 'zod-validation-error';

export const createCodeEditorFormItem = (schema: z.ZodSchema) => {
  const CodeEditorFormItem = ({
    isEditing,
    name,
  }: {
    isEditing: boolean;
    name: NamePath;
  }) => (
    <Wrapper
      name={name}
      preserve
      rules={[
        {
          validator: async (rule, value) => {
            try {
              schema.parse(JSON.parse(value));
            } catch (error) {
              return Promise.reject(
                fromError(error, { prefix: null }).toString(),
              );
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <CodeEditor
        disabled={!isEditing}
        editorProps={{ width: '99%' }}
        jsonSchema={zodToJsonSchema(schema)}
        language={Language.JSON}
      />
    </Wrapper>
  );

  return CodeEditorFormItem;
};

const Wrapper = styled(Form.Item)`
  flex: 1;
  margin-bottom: 0;
`;

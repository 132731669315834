import { useIsUser } from 'hooks/auth/useIsUser';
import NoPermissionsView from './NoPermissionsView';

interface Props {
  children: React.ReactNode;
}

const PermissionGate: React.FC<Props> = ({ children }) => {
  const isUser = useIsUser();

  if (!isUser) {
    return <NoPermissionsView />;
  }

  return <>{children}</>;
};

export default PermissionGate;

import AdminDevicesList from 'components/admin/list/AdminDevicesList';
import Breadcrumb from 'components/common/Breadcrumb';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';

const AdminDevicesView: React.FC = () => {
  return (
    <>
      <Breadcrumb
        items={[
          { name: 'Geräte & Aktivierungsanfragen', href: '/admin/devices' },
        ]}
      />
      <PageTitleBar
        title="Geräte & Aktivierungsanfragen"
        Icon={ResourceIcons.Device}
      />
      <PageContentContainer>
        <AdminDevicesList />
      </PageContentContainer>
    </>
  );
};

export default AdminDevicesView;

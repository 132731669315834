import Joi from 'joi';
import recursivelyMaskPasswordValues from './maskSecrets';

export const validate = <T>(
  value: any,
  schema: Joi.Schema,
): [T | null, string | null] => {
  try {
    const result = schema.validate(value);
    if (result.error) {
      return [null, result.error.message];
    }
    return [result.value, null];
  } catch (e: unknown) {
    if (e instanceof Error) {
      return [null, e.message];
    } else {
      return [null, 'Unbekannter Fehler'];
    }
  }
};

export const validateOrThrow = <T>(value: any, schema: Joi.Schema<T>): T => {
  const [parsed, error] = validate<T>(value, schema);
  if (error !== null) {
    throw new Error(error);
  }
  return parsed as T;
};

export const validateJsonProperty = <T>(
  propertyValue: string | undefined,
  schema: Joi.Schema<T>,
): [T | null, string | null] => {
  if (propertyValue === undefined) {
    return [null, null];
  }

  try {
    const parsed = JSON.parse(propertyValue);
    return validate<T>(parsed, schema);
  } catch (e: unknown) {
    if (e instanceof Error) {
      return [null, e.message];
    } else {
      return [null, 'Unbekannter Fehler'];
    }
  }
};

export const validateJsonPropertyAndMaskSecrets = (
  propertyValue: string | undefined,
  schema: Joi.Schema<Record<string, any>>,
): [Record<string, any> | null, string | null] => {
  if (propertyValue === undefined) {
    return [null, null];
  }

  try {
    const parsed = JSON.parse(propertyValue);
    const [validated, error] = validate<Record<string, any>>(parsed, schema);
    if (error !== null) {
      return [null, error];
    }
    if (validated === null) {
      return [null, null];
    }
    return [recursivelyMaskPasswordValues(validated), null];
  } catch (e: unknown) {
    if (e instanceof Error) {
      return [null, e.message];
    } else {
      return [null, 'Unbekannter Fehler'];
    }
  }
};

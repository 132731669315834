import { Switch } from 'antd';
import { FeatureRenderProps } from 'defaults/featuresToDeviceProperties';
import _ from 'lodash';

interface BooleanFeatureStates {
  on: Record<string, string>;
  off: Record<string, string>;
}

export const BooleanFeature: React.FC<
  FeatureRenderProps & {
    states: BooleanFeatureStates;
  }
> = ({ values, onUpdate, states }) => {
  const isOn = _.isEqual(states.on, values);
  const isOff = _.isEqual(states.off, values);
  const isUndefined = !isOn && !isOff;

  return (
    <div>
      {isUndefined && 'broken'}
      <Switch
        checked={isOn}
        onChange={(checked) => {
          onUpdate(checked ? states.on : states.off);
        }}
      />
    </div>
  );
};

export const createBooleanFeature =
  // eslint-disable-next-line react/display-name
  (states: BooleanFeatureStates) => (props: FeatureRenderProps) => (
    <BooleanFeature {...props} states={states} />
  );

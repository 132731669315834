import { Tooltip } from 'antd';
import { DateTime } from 'luxon';

interface Props {
  timestamp: string | null | undefined;
  noneText?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
const RelativeDateTimeWithTooltip: React.FC<Props> = ({
  timestamp,
  noneText = 'Keine Angabe',
  style,
  className,
}) => {
  return (
    <>
      {timestamp ? (
        <Tooltip
          title={DateTime.fromISO(timestamp)
            .toLocal()
            .toFormat('dd.MM.yyyy HH:mm:ss')}
        >
          <span style={style} className={className}>
            {DateTime.fromISO(timestamp).toRelative()}
          </span>
        </Tooltip>
      ) : (
        <span style={style} className={className}>
          {noneText}
        </span>
      )}
    </>
  );
};

export default RelativeDateTimeWithTooltip;

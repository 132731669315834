import { LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  AutoComplete,
  Button,
  Form,
  Input,
  List,
  Modal,
  Select,
  Tag,
  Typography,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import { FlexBox } from 'components/Helpers';
import wellKnownAdminDeviceLabels from 'defaults/deviceLabels';
import device from 'defaults/deviceRoles';
import useOrganizations from 'hooks/organizations/useOrganizations';
import { DeviceData } from 'interfaces/adminDevice';
import { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import _ from 'lodash';
import * as Papa from 'papaparse';
import React, { useState } from 'react';
import LabelsFormList, { convertFromFormList } from '../common/LabelsFormList';
import RegistrationMailForm from './RegistrationmailForm';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onCreated?: (devices: AdminDeviceV4Dto[]) => void;
}
const CreateDevicesFromCSVModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCreated,
}) => {
  const { postDevicesAndSendRegistrationMails } = useAdminDeviceApi();

  const { organizations } = useOrganizations();

  const [errors, setErrors] = useState(false);
  const [entries, setEntries] = useState<any>([]);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();

  const parseCSV = (csv: string) => {
    const parsed = Papa.parse(csv, { delimiter: ',' });

    if (parsed.errors.length > 0) {
      setErrors(true);
      return;
    } else setErrors(false);

    parsed.data.forEach((entry: any) => {
      if (entry.length != 4) {
        setErrors(true);
        return;
      }
    });

    setEntries(parsed.data);
  };

  const matchEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const data = form.getFieldsValue();
      const emailData = emailForm.getFieldsValue();

      if (!emailData.body.includes('%qr-code%'))
        throw new Error('QR Code is not in email');

      entries.forEach((entry: any[]) => {
        if (!matchEmail(entry[3].trim()))
          throw new Error('Email address is not valid');

        if (entry.length != 4) throw new Error('Entry does not have 3 columns');
      });

      const devicePost: DeviceData = {
        ...data,
        labels: convertFromFormList(data.labels),
      };

      const devices = await postDevicesAndSendRegistrationMails({
        mailTemplate: {
          subject: emailData.subject,
          body: emailData.body,
        },
        device: devicePost,
        recipients: entries.map((entry: any[]) => ({
          firstname: entry[0].trim(),
          lastname: entry[1].trim(),
          salutation: entry[2].trim(),
          emailAddress: entry[3].trim(),
        })),
      });

      onCreated && onCreated(devices);
      onClose && onClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrors(true);
    }
  };

  return (
    <Modal
      title="Create Devices from CSV"
      open={isOpen}
      onCancel={onClose}
      width={1000}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          type="primary"
          key="create-devices-and-send"
          onClick={() => onSubmit()}
          disabled={!!isLoading}
        >
          {isLoading ? <LoadingOutlined /> : 'Create Devices and send Emails'}
        </Button>,
      ]}
    >
      <FlexBox withgap>
        <Form form={form} style={{ flex: 1 }}>
          <Typography.Title level={4}>Device Settings</Typography.Title>
          <Form.Item name="organization" label="Organization">
            <AutoComplete
              options={(organizations ?? []).map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              filterOption={(input: string, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase()) ??
                false
              }
            />
          </Form.Item>
          <Form.Item name="role" label="Role">
            <Select
              defaultActiveFirstOption={false}
              showSearch={true}
              filterOption={true}
              optionFilterProp="label"
              options={_.sortBy(device, 'name').map((roleOption) => ({
                label: roleOption,
                value: roleOption,
              }))}
            />
          </Form.Item>
          <LabelsFormList
            name="labels"
            label="Label"
            labelOptions={wellKnownAdminDeviceLabels}
          />
          <Form.Item label="CSV Data">
            <Input.TextArea
              rows={10}
              onChange={(e) => parseCSV(e.target.value)}
            />
            <Text code>firstname, lastname, salutation, email</Text>
          </Form.Item>
          <FlexBox direction="column" withgap>
            {errors && (
              <Alert type="error" message="Invalid CSV (inspect console)" />
            )}
            {entries.length > 0 && (
              <>
                <Alert message={`Found ${entries.length} entries`} />
                <List
                  size="small"
                  bordered
                  dataSource={showAll ? entries : entries.slice(0, 5)}
                  renderItem={(item: any[]) => (
                    <List.Item style={{ justifyContent: 'flex-start' }}>
                      <Tag>firstname: {item[0]?.trim()}</Tag>
                      <Tag>lastname: {item[1]?.trim()}</Tag>
                      <Tag>salutation: {item[2]?.trim()}</Tag>
                      <Tag>email: {item[3]?.trim()}</Tag>
                    </List.Item>
                  )}
                />
                {entries.length > 5 && !showAll && (
                  <>
                    And {entries.length - 5} more entries{' '}
                    <a onClick={() => setShowAll(true)}>show all</a>
                  </>
                )}
              </>
            )}
          </FlexBox>
        </Form>
        <div style={{ width: '2px', background: '#dddddd' }}></div>
        <RegistrationMailForm
          style={{ width: '100%', flex: 1 }}
          form={emailForm}
        />
      </FlexBox>
    </Modal>
  );
};

export default CreateDevicesFromCSVModal;

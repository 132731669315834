import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { FlexBox } from 'components/Helpers';
import {
  getPresentFeatures,
  hasPropertyFeature,
} from 'defaults/featuresToDeviceProperties';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { isUserAdvancedAtom } from 'state/state';
import styled from 'styled-components';
import DeviceFeatures from './DeviceFeatures';
import DevicePropertiesModal from './DevicePropertiesModal';
import DevicePropertyInput from './DevicePropertyInput';

interface Props {
  value: Record<string, string>;
  onChange: (value: Record<string, string>) => void;
  canDelete?: boolean;
  canAdd?: boolean;
}

const DevicePropertiesForm: React.FC<Props> = ({
  value,
  onChange,
  canDelete = true,
  canAdd = true,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);

  const remove = (key: string) => {
    onChange(_.omit(value, key));
  };

  const update = (key: string, localValue: string) => {
    onChange({
      ...value,
      [key]: localValue,
    });
  };

  const presentFeatures = useMemo(() => {
    return getPresentFeatures(value);
  }, [value]);

  return (
    <Wrapper direction="column">
      {canAdd && (
        <Typography.Title level={5}>
          {isUserAdvanced ? 'Features und Device Properties' : 'Features'}
        </Typography.Title>
      )}
      {canAdd && (
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            setModalOpen(true);
          }}
          type="dashed"
        >
          Feature{isUserAdvanced && ' oder Device Property'} hinzufügen
        </Button>
      )}
      <DevicePropertiesModal
        currentDeviceProperties={value}
        onChange={onChange}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <DeviceFeatures
        deviceProperties={value}
        onChange={onChange}
        canDelete={canDelete}
        isAllInEditMode={!canAdd}
      />
      {isUserAdvanced && (
        <>
          {Object.keys(value).length > 0 && (
            <Typography.Title level={5}>Device Properties</Typography.Title>
          )}
          {Object.entries(value)
            .sort((a, b) => {
              const aHasFeature = hasPropertyFeature(a[0]);
              const bHasFeature = hasPropertyFeature(b[0]);
              if (aHasFeature && !bHasFeature) {
                return 1;
              }
              if (!aHasFeature && bHasFeature) {
                return -1;
              }
              return a[0].localeCompare(b[0]);
            })
            .map(([k, v], i) => (
              <DevicePropertyInput
                key={k}
                v={{ index: i, key: k, value: v }}
                onChange={(value) => update(k, value)}
                onDelete={() => remove(k)}
                presentFeatures={presentFeatures}
              />
            ))}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexBox)`
  margin-bottom: ${({ theme }) => theme.antd.marginSM}px;
`;

export default DevicePropertiesForm;

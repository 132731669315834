import { Typography } from 'antd';
import TagList from 'components/common/TagList';
import { FlexBox } from 'components/Helpers';
import { DeviceSelector } from 'interfaces/adminGlobalDeviceConfig';
import { Operator, operatorToName } from 'interfaces/matchers';
import { styled } from 'styled-components';

interface Props {
  deviceSelector: DeviceSelector;
}
const DeviceSelectorVisualizer: React.FC<Props> = ({ deviceSelector }) => {
  return (
    <FlexBox withgap wrap>
      {deviceSelector.matchLabels?.map(({ key, operator, values }) => (
        <Selector
          key={key}
          header={
            <FlexBox withgap>
              Label
              <Typography.Text code>{key}</Typography.Text>{' '}
            </FlexBox>
          }
        >
          <FlexBox withgap>
            <Typography.Text type="secondary">
              {operatorToName[operator]}
            </Typography.Text>
            {operator !== Operator.DOES_NOT_EXIST &&
              operator !== Operator.EXISTS && <TagList tags={values} />}
          </FlexBox>
        </Selector>
      ))}

      {deviceSelector.matchRole &&
        ((deviceSelector.matchRole.in &&
          deviceSelector.matchRole.in.length > 0) ||
          (deviceSelector.matchRole.notIn &&
            deviceSelector.matchRole.notIn.length > 0)) && (
          <Selector header={<>Role</>}>
            {deviceSelector.matchRole.in &&
              deviceSelector.matchRole.in.length > 0 && (
                <FlexBox withgap>
                  <Typography.Text type="secondary">in</Typography.Text>
                  <TagList tags={deviceSelector.matchRole?.in} />
                </FlexBox>
              )}
            {deviceSelector.matchRole.notIn &&
              deviceSelector.matchRole?.notIn.length > 0 && (
                <FlexBox withgap>
                  <Typography.Text type="secondary">not in</Typography.Text>
                  <TagList tags={deviceSelector.matchRole?.notIn} />
                </FlexBox>
              )}
          </Selector>
        )}

      {deviceSelector.matchOrganization && (
        <Selector header={<>Organization</>}>
          {deviceSelector.matchOrganization.in &&
            deviceSelector.matchOrganization.in.length > 0 && (
              <FlexBox withgap>
                <Typography.Text type="secondary">in</Typography.Text>
                <TagList tags={deviceSelector.matchOrganization?.in} />
              </FlexBox>
            )}
          {deviceSelector.matchOrganization.notIn &&
            deviceSelector.matchOrganization?.notIn.length > 0 && (
              <FlexBox withgap>
                <Typography.Text type="secondary">not in</Typography.Text>
                <TagList tags={deviceSelector.matchOrganization?.notIn} />
              </FlexBox>
            )}
        </Selector>
      )}
    </FlexBox>
  );
};

export default DeviceSelectorVisualizer;

const Selector = ({
  header,
  children,
}: {
  header: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <SelectorBox>
      <FlexBox direction="column">
        <SelectorBoxHeader>{header}</SelectorBoxHeader>
        <SelectorBoxBody>{children}</SelectorBoxBody>
      </FlexBox>
    </SelectorBox>
  );
};

const SelectorBoxHeader = styled.div`
  background-color: #f5f5f5;
  border-radius: 15px 15px 0 0;
  padding: 5px 10px;
`;
const SelectorBoxBody = styled.div`
  padding: 5px 10px;
`;

const SelectorBox = styled.div`
  transition: background-color 0.2s ease-in-out;

  background-color: #fff;
  color: #000;

  border: 1px solid #cdcdcd;
  border-radius: 15px;
`;

import { CheckOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AutoComplete, Button, message } from 'antd';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';
import { FlexBox } from 'components/Helpers';
import useOrganizationIds from 'hooks/organizations/useOrganizationIds';
import { DeviceActivationRequest } from 'interfaces/deviceActivationRequest';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  deviceActivationRequest: DeviceActivationRequest;
}
const UpdateDeviceActivationRequestOrganizationInput: React.FC<Props> = ({
  deviceActivationRequest,
}) => {
  const queryClient = useQueryClient();

  const organizations = useOrganizationIds();

  const [organization, setOrganization] = useState(
    deviceActivationRequest.organization ?? '',
  );

  const filteredOrganizations = useMemo(() => {
    return organizations.filter((org) =>
      org.toLowerCase().includes(organization.toLowerCase()),
    );
  }, [organization, organizations]);

  useEffect(() => {
    setOrganization(deviceActivationRequest.organization ?? '');
  }, [deviceActivationRequest.organization]);

  const { putAdminDeviceActivationRequestOrganization } = useAdminDeviceApi();

  const { mutate: updateOrganization, isPending } = useMutation({
    mutationFn: (organization: string) =>
      putAdminDeviceActivationRequestOrganization(
        deviceActivationRequest.id,
        organization,
      ),
    onSuccess: () => {
      message.success('Organisation wurde aktualisiert');
      queryClient.invalidateQueries({
        queryKey: ['admin-device-activation-requests'],
      });
    },
    onError: (error: any) => {
      message.error(
        error.response?.data.message ??
          error.message ??
          'Ein unbekannter Fehler ist aufgetreten',
      );
      setOrganization(deviceActivationRequest.organization ?? '');
    },
  });

  return (
    <FlexBox withgap>
      <AutoComplete
        style={{ width: 200 }}
        size="small"
        value={organization}
        onChange={(value) => setOrganization(value)}
        options={filteredOrganizations.map((org) => ({
          value: org,
        }))}
      />
      <Button
        size="small"
        icon={<CheckOutlined />}
        onClick={() => updateOrganization(organization)}
        loading={isPending}
      />
    </FlexBox>
  );
};

export default UpdateDeviceActivationRequestOrganizationInput;

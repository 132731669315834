import { Form, FormProps, Input, Typography } from 'antd';
import React from 'react';

const { Text, Title, Paragraph } = Typography;

const defaultMail = `<strong>Hallo %firstname% %lastname%,</strong>
<p>Mit diesem QR Code kann dein voize-Gerät aktiviert werden:</p>
<p>%qr-code%</p>
<p>Viele Grüße </p>
<p>Dein voize-Team</p>
`;

const RegistrationMailForm: React.FC<FormProps> = (props) => {
  return (
    <Form {...props}>
      <Title level={4}>Email settings</Title>
      <Form.Item
        name="subject"
        label="Subject"
        initialValue="Aktiviere deine voize App"
      >
        <Input />
      </Form.Item>
      <Paragraph>
        Use <Text code>%firstname%</Text>, <Text code>%lastname%</Text> and{' '}
        <Text code>%salutation%</Text> to insert the first name, last name and
        saluation respectively and
        <Text code>%qr-code%</Text> for a qr code png to be rendered at that
        point.
      </Paragraph>
      <Form.Item name="body" label="Body (HTML)" initialValue={defaultMail}>
        <Input.TextArea rows={15} />
      </Form.Item>
    </Form>
  );
};

export default RegistrationMailForm;

import { useQuery } from '@tanstack/react-query';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';

const useOrganizationDeviceConfigurations = () => {
  const { getOrganizationDeviceConfigurations } = useAdminDeviceApi();

  const query = useQuery({
    queryKey: ['admin-organization-device-configurations'],
    queryFn: () => getOrganizationDeviceConfigurations(),
  });

  return query;
};

export default useOrganizationDeviceConfigurations;

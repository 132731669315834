import { useCallback, useEffect } from 'react';
import shortcuts, { ShortcutEvent } from 'utlis/shortcuts';

const useRegisterShortcutListener = (
  e: ShortcutEvent,
  handler: () => void,
  isEnabled: boolean = true,
) => {
  const keyHandler = useCallback(
    (args: KeyboardEvent) => {
      if (!isEnabled) return;

      const isEvent = Object.entries(shortcuts).some(([key, shortcut]) => {
        return (
          e === (key as unknown as ShortcutEvent) &&
          shortcut &&
          shortcut.key === args.key &&
          shortcut.withCtrl === args.ctrlKey &&
          shortcut.withShift === args.shiftKey &&
          shortcut.type === args.type
        );
      });

      if (!isEvent) return;
      args.preventDefault();
      handler();
    },
    [isEnabled, handler, e],
  );

  useEffect(() => {
    window.addEventListener('keydown', keyHandler);
    window.addEventListener('keyup', keyHandler);
    return () => {
      window.removeEventListener('keydown', keyHandler);
      window.removeEventListener('keyup', keyHandler);
    };
  }, [keyHandler]);

  return null;
};

export default useRegisterShortcutListener;

import { useState } from 'react';

const useDataPagination = <T>(
  entries: T[],
  defaultPage = 0,
  defaultLimit = 50,
) => {
  const [page, setPage] = useState(defaultPage);
  const [limit, setLimit] = useState(defaultLimit);

  const paginatedEntries = entries.slice(page * limit, (page + 1) * limit);

  return {
    entries: paginatedEntries,
    page,
    setPage,
    limit,
    setLimit,
  };
};

export default useDataPagination;

import { Button, message } from 'antd';
import styled from 'styled-components';

interface Props {
  propertyName: string;
}

const CopyableDevicePropertyKey: React.FC<Props> = ({ propertyName }) => {
  return (
    <StyledButton
      type="text"
      size="small"
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        navigator.clipboard.writeText(propertyName);
        message.success('In Zwischenablage kopiert');
      }}
    >
      {propertyName}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  font-family: monospace;
  padding: 0;
`;

export default CopyableDevicePropertyKey;

import { useQuery } from '@tanstack/react-query';
import { useAdminDeviceApi } from 'api/devices/useAdminDeviceApi';

const useGlobalDeviceConfigurations = () => {
  const { getGlobalDeviceConfigurations } = useAdminDeviceApi();

  const query = useQuery({
    queryKey: ['global-device-configurations'],
    queryFn: getGlobalDeviceConfigurations,
  });

  return query;
};

export default useGlobalDeviceConfigurations;

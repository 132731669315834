import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm } from 'antd';
import { FlexBox, FlexOne } from 'components/Helpers';
import { Feature } from 'defaults/featuresToDeviceProperties';
import { useState } from 'react';

interface Props {
  feature: Feature;
  allDeviceProperties: Record<string, string>;
  featureDeviceProperties: Record<string, string>;
  wasJustAdded?: boolean;
  onUpdate: (properties: Record<string, string>) => void;
  onDelete: () => void;
  canDelete: boolean;
  isEditModeForced: boolean;
}

const DeviceFeature: React.FC<Props> = ({
  feature,
  featureDeviceProperties,
  allDeviceProperties,
  wasJustAdded = false,
  onUpdate,
  onDelete,
  canDelete,
  isEditModeForced,
}) => {
  const [isEditing, setIsEditing] = useState(wasJustAdded);

  const isInEditMode = isEditModeForced || isEditing;

  const renderContent = () => (
    <feature.Component
      values={featureDeviceProperties}
      onUpdate={(properties) => {
        onUpdate(properties);
      }}
      isEditing={isInEditMode}
      onEditDone={isEditModeForced ? undefined : () => setIsEditing(false)}
      allDeviceProperties={allDeviceProperties}
    />
  );

  return (
    <Card
      key={feature.name}
      style={{
        width: '100%',
      }}
    >
      <FlexBox direction="column" withgap gap={20}>
        <FlexBox direction="row" withgap>
          <span style={{ fontWeight: '600' }}>{feature.name}</span>
          {!feature.isMultiline && renderContent()}
          <FlexOne />
          {feature.isEditable && !isEditModeForced && (
            <Button
              icon={<EditOutlined />}
              type={isEditing ? 'primary' : 'text'}
              size="small"
              onClick={() => setIsEditing(!isEditing)}
            />
          )}
          {canDelete && (
            <Popconfirm
              title="Sind Sie sicher, dass Sie dieses Feature löschen möchten?"
              onConfirm={onDelete}
              okText="Ja"
              cancelText="Abbrechen"
            >
              <Button
                icon={<DeleteOutlined />}
                danger
                type="text"
                size="small"
              />
            </Popconfirm>
          )}
        </FlexBox>
        {feature.isMultiline && renderContent()}
      </FlexBox>
    </Card>
  );
};

export default DeviceFeature;

import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Skeleton } from 'antd';
import ErrorAlert from 'components/common/ErrorAlert';
import { useAtom } from 'jotai';
import { createContext, useEffect, useState } from 'react';
import { shouldAnonymiseTelemetryAtom } from 'state/state';
import parseJwt from 'utlis/parseJwt';

interface AuthInfo {
  organization: string;
  user: User;
  scope: string[];
  allowedDeviceRoles: string[];
}

export const allowedDeviceRolesPayloadKey =
  'https://voize.de/device-management/allowed-device-roles';

export const AuthContext = createContext<AuthInfo | undefined>(undefined);

interface Props {
  children?: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { getAccessTokenSilently, user, getIdTokenClaims } = useAuth0();
  const [organization, setOrganization] = useState<string | null | undefined>(
    null,
  );
  const [scope, setScope] = useState<string[] | null>(null);
  const [allowedDeviceRoles, setAllowedDeviceRoles] = useState<string[] | null>(
    null,
  );

  useEffect(() => {
    const getScope = async () => {
      const idToken = await getIdTokenClaims();
      const accessToken = await getAccessTokenSilently();
      const payload = parseJwt(accessToken);
      if (idToken === undefined) throw new Error('No idToken found');
      setOrganization(idToken['https://voize.de/organization']);
      setScope(payload.scope.split(' ') ?? []);
      setAllowedDeviceRoles(payload[allowedDeviceRolesPayloadKey] ?? []);
    };

    getScope();
  }, [getAccessTokenSilently, getIdTokenClaims]);

  const [shouldAnonymiseTelemetry] = useAtom(shouldAnonymiseTelemetryAtom);

  useEffect(() => {
    if (shouldAnonymiseTelemetry || user === undefined) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        id: user.sub,
        username: user.name,
        email: user.email,
      });
    }
  }, [shouldAnonymiseTelemetry, user]);

  if (organization === undefined) {
    return (
      <ErrorAlert
        errorCode="Error"
        subtitle="Der User Account scheint nicht richtig konfiguriert zu sein. Bitte melde dich bei einem Customer Success Mitarbeiter."
      />
    );
  }

  if (!organization || !user || !scope || !allowedDeviceRoles) {
    return <Skeleton active />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        organization,
        scope,
        allowedDeviceRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default withAuthenticationRequired(AuthProvider, {
  returnTo: window.location.pathname,
});

import useAuthContext from './useAuthContext';

/**
 * Get the organization id of the current user
 */
const useUserOrg = () => {
  const { organization } = useAuthContext();
  return organization;
};

export default useUserOrg;

import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAdminUserApi } from 'api/devices/useAdminUserApi';
import { FlexBox } from 'components/Helpers';
import deviceRoles, {
  sdkDeviceRole,
  seniorCareAppDeviceRole,
  ticAppDeviceRole,
} from 'defaults/deviceRoles';
import userGroups from 'defaults/userGroups';
import userRoles from 'defaults/userRoles';
import { PostUserRequest, User } from 'interfaces/user';
import getErrorMessage from 'utlis/getErrorMessage';

interface CommonProps {
  open: boolean;
  onClose: () => void;
}

interface CreateUserProps extends CommonProps {
  user: undefined;
  organizationId: string;
  onCreated: (user: User) => void;
  onUpdated: undefined;
}

interface EditUserProps extends CommonProps {
  user: User;
  organizationId: undefined;
  onCreated: undefined;
  onUpdated: () => void;
}

type Props = CreateUserProps | EditUserProps;

const userPresets: (Partial<Omit<PostUserRequest, 'organization'>> & {
  presetName: string;
})[] = [
  {
    presetName: 'Senior Care SDK Organization Admin',
    roles: ['organization-admin'],
    allowedDeviceRoles: [sdkDeviceRole],
    groups: [],
  },
  {
    presetName: 'Senior Care App Organization Admin',
    roles: ['senior-care-app-organization-admin'],
    allowedDeviceRoles: [seniorCareAppDeviceRole],
    groups: [],
  },
  {
    presetName: 'TIC SDK Organization Admin',
    roles: ['organization-admin'],
    allowedDeviceRoles: [sdkDeviceRole],
    groups: [],
  },
  {
    presetName: 'TIC App Organization Admin',
    roles: ['organization-admin'],
    allowedDeviceRoles: [ticAppDeviceRole],
    groups: [],
  },
  {
    presetName: 'Organization Insights User',
    roles: ['organization-admin-tool-user'],
    allowedDeviceRoles: [],
    groups: [],
  },
  {
    presetName: 'SDK Developer',
    roles: ['sdk-developer'],
    allowedDeviceRoles: [sdkDeviceRole],
    groups: ['voize-sdk-developer'],
  },
  {
    presetName: 'Standardized Developer',
    roles: ['organization-admin'],
    allowedDeviceRoles: [seniorCareAppDeviceRole],
    groups: ['voize-api-developer'],
  },
];

const AdminUserModal: React.FC<Props> = ({
  open,
  onClose,
  user,
  organizationId,
  onCreated,
  onUpdated,
}) => {
  const { postUser, putUser } = useAdminUserApi();

  const [form] = useForm<PostUserRequest>();

  const updateOrCreate = async (values: PostUserRequest) => {
    const email = values.email.trim();
    const name = values.name.trim();
    const roles = values.roles;
    const allowedDeviceRoles = values.allowedDeviceRoles;
    const groups = values.groups;
    const organization = values.organization;
    const password = values.password;

    if (user) {
      await putUser(user.id, {
        email,
        name,
        roles,
        allowedDeviceRoles,
        groups,
        organization,
      });
      onUpdated();
    } else {
      const newUser = await postUser({
        email,
        name,
        roles,
        allowedDeviceRoles,
        groups,
        organization,
        password,
      });
      onCreated(newUser);
    }
    onClose();
  };

  const { mutate, isPending } = useMutation({
    mutationFn: updateOrCreate,
    onSuccess: () => {
      if (!user) {
        message.success('Nutzer erstellt');
      } else {
        message.success('Nutzer bearbeitet');
      }
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    },
  });

  return (
    <Modal
      title={user ? 'Nutzer bearbeiten' : 'Nutzer erstellen'}
      open={open}
      onCancel={() => onClose()}
      okText={user ? 'Speichern' : 'Erstellen'}
      cancelText="Abbrechen"
      onOk={() => form.submit()}
      confirmLoading={isPending}
      width={800}
      destroyOnClose
    >
      {!user && (
        <FlexBox style={{ marginBottom: '10px' }} withgap wrap>
          {userPresets.map((preset) => (
            <Button
              key={preset.presetName}
              onClick={() => form.setFieldsValue(preset)}
            >
              {preset.presetName}
            </Button>
          ))}
        </FlexBox>
      )}
      <Form
        initialValues={{
          organization: user ? user.organization : organizationId,
          email: '',
          name: '',
          password: '',
          roles: [],
          allowedDeviceRoles: [],
          groups: [],
          ...user,
        }}
        form={form}
        onFinish={mutate}
        preserve={false}
      >
        <Form.Item label="E-Mail Adresse" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        {user === undefined && (
          <Form.Item label="Passwort" name="password">
            <Input.Password />
          </Form.Item>
        )}
        <Form.Item label="Rollen" name="roles">
          <Select
            mode="multiple"
            options={userRoles.map((role) => ({ value: role }))}
          />
        </Form.Item>
        <Form.Item label="Erlaubte Geräterollen" name="allowedDeviceRoles">
          <Select
            mode="multiple"
            options={deviceRoles.map((role) => ({ value: role }))}
          />
        </Form.Item>

        <Form.Item label="Gruppen" name="groups">
          <Select
            mode="multiple"
            options={userGroups.map((g) => ({
              value: g,
            }))}
          />
        </Form.Item>

        <Form.Item label="Organisation" name="organization">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminUserModal;

import { Navigate } from '@tanstack/react-router';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import useUserOrg from 'hooks/auth/useUserOrg';
import React from 'react';

const HomeView: React.FC = () => {
  const isAdmin = useIsAdmin();
  const organization = useUserOrg();

  if (isAdmin) {
    return <Navigate to="/admin/organizations" />;
  } else {
    return (
      <Navigate to="/management/$organization" params={{ organization }} />
    );
  }
};

export default HomeView;

import { Alert } from 'antd';
import LabelRow from 'components/common/LabelRow';
import { FlexBox } from 'components/Helpers';
import {
  IntegrationAdapterType,
  integrationTypeName,
} from 'interfaces/ticIntegrationConfig';
import Joi from 'joi';
import { useMemo } from 'react';
import { validateJsonProperty } from 'utlis/validationUtil';

interface Props {
  properties: Record<string, string>;
}

const TICIntegrationConfigSummary: React.FC<Props> = ({ properties }) => {
  const [tuevNordIntegrationConfig, validationError] = useMemo(
    () =>
      validateJsonProperty(
        properties['TIC_APP_INTEGRATION_ADAPTER_TUEV_NORD_CONFIGURATION'],
        Joi.object({
          baseUrl: Joi.string().uri().optional().allow(''),
        }),
      ),
    [properties],
  );

  const integrationConfigAdapterType = properties[
    'TIC_APP_INTEGRATION_ADAPTER_TYPE'
  ] as IntegrationAdapterType;

  return (
    <FlexBox direction="column">
      {validationError !== null && (
        <Alert
          message="Fehler"
          description={validationError}
          type="error"
          showIcon
        />
      )}
      <FlexBox direction="column" withgap>
        <FlexBox direction="column">
          <LabelRow
            label="Integrations-Typ"
            value={integrationTypeName[integrationConfigAdapterType]}
          />
          {tuevNordIntegrationConfig !== null &&
            integrationConfigAdapterType ===
              IntegrationAdapterType.TUEV_NORD && (
              <LabelRow
                label="Integrations-URL"
                value={tuevNordIntegrationConfig.baseUrl}
              />
            )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default TICIntegrationConfigSummary;

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { NamePath } from 'antd/es/form/interface';
import styled from 'styled-components';
import { defaultValues } from '../../InspectionCatalogMetadataFeature';

const FormList = ({
  InputComponent,
  isEditing,
  label,
  path,
}: {
  InputComponent: React.ComponentType<{
    isEditing: boolean;
    name: NamePath;
  }>;
  isEditing: boolean;
  label: string;
  path: keyof typeof defaultValues;
}) => {
  const form = useFormInstance();
  const name = path.split('/');

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <FormItem label={label}>
          {fields.length === 0 ? (
            isEditing ? null : (
              <NoItems value="Keine Angaben" />
            )
          ) : (
            fields.map((field, index) => (
              <Item
                align="center"
                key={`${path}/${field.key}`}
                withMarginBottom={isEditing || index < fields.length - 1}
              >
                <InputComponent isEditing={isEditing} name={field.name} />
                {isEditing && (
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      remove(index);
                      requestAnimationFrame(() => {
                        form.validateFields([name], { recursive: true });
                      });
                    }}
                    type="text"
                  />
                )}
              </Item>
            ))
          )}
          {isEditing && (
            <Button
              block
              onClick={() => {
                add(JSON.stringify(defaultValues[path]));
                requestAnimationFrame(() => {
                  form.validateFields([name], { recursive: true });
                });
              }}
              type="dashed"
            >
              <PlusOutlined /> {label} hinzufügen
            </Button>
          )}
        </FormItem>
      )}
    </Form.List>
  );
};

const FormItem = styled(Form.Item)`
  margin-bottom: ${({ theme }) => theme.antd.paddingSM}px;
`;

const NoItems = styled(Input)`
  pointer-events: none;
`;

const Item = styled(Flex)<{ withMarginBottom: boolean }>`
  margin-bottom: ${({ theme, withMarginBottom }) =>
    withMarginBottom ? theme.antd.marginXS : 0}px;
`;

export default FormList;

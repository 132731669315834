import {
  deleteAdminDeviceActivationRequestsScope,
  deleteDeviceConfigurationsScope,
  deleteDevicesScope,
  readAdminDeviceActivationRequestsScope,
  readDeviceConfigurationsScope,
  readDevicesScope,
  readUsersScope,
  writeAdminDeviceActivationRequestsScope,
  writeDeviceConfigurationsScope,
  writeDevicesScope,
} from 'api/devices/deviceManagementApiScopes';
import {
  deleteSeniorCareAdminUsers,
  readSeniorCareAdminUsers,
  writeSeniorCareAdminUsers,
} from 'api/seniorcare/seniorCareApiScopes';
import _ from 'lodash';
import useAuth0Scope from './useAuth0Scope';

const adminScopes = [
  readDeviceConfigurationsScope,
  writeDeviceConfigurationsScope,
  deleteDeviceConfigurationsScope,
  readDevicesScope,
  writeDevicesScope,
  deleteDevicesScope,
  readAdminDeviceActivationRequestsScope,
  writeAdminDeviceActivationRequestsScope,
  deleteAdminDeviceActivationRequestsScope,
  readUsersScope,
  readSeniorCareAdminUsers,
  writeSeniorCareAdminUsers,
  deleteSeniorCareAdminUsers,
];

const useIsAdmin = () => {
  const scope = useAuth0Scope();

  return _.intersection(scope, adminScopes).length === adminScopes.length;
};

export default useIsAdmin;

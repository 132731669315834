import { z } from 'zod';

export enum InspectionCatalogMetadataType {
  INDUSTRY_SERVICES = 'industry-services',
  VEHICLE_INSPECTION = 'vehicle-inspection',
}

export const DefectCatalogVersionsSchema = z
  .object({
    hu: z.string(),
    specialHu: z.string().nullable().default(null),
    sp: z.string().nullable().default(null),
    specialFtu: z.string().nullable().default(null),
    specialUvv: z.string().nullable().default(null),
    tmp: z.string().nullable().default(null),
  })
  .strict();

export type DefectCatalogVersions = z.infer<typeof DefectCatalogVersionsSchema>;

export const NoticeCatalogVersionsSchema = z
  .object({
    hu: z.string(),
    sp: z.string().nullable().default(null),
    specialFtu: z.string().nullable().default(null),
  })
  .strict();

export type NoticeCatalogVersions = z.infer<typeof NoticeCatalogVersionsSchema>;

export const CatalogCollectionMetadataVehicleInspectionSchema = <
  T extends z.ZodTypeAny,
>(
  catalogVersionsSchema: T,
) =>
  z
    .object({
      id: z.string(),
      downloadId: z.string(),
      startDate: z.string().date(),
      catalogVersions: catalogVersionsSchema,
    })
    .strict();

export type CatalogCollectionMetadataVehicleInspection<T extends z.ZodTypeAny> =
  z.infer<
    ReturnType<typeof CatalogCollectionMetadataVehicleInspectionSchema<T>>
  >;

export const VehicleInspectionSchema = z
  .object({
    type: z.literal(InspectionCatalogMetadataType.VEHICLE_INSPECTION),
    defectCatalogs: z.array(
      CatalogCollectionMetadataVehicleInspectionSchema(
        DefectCatalogVersionsSchema,
      ),
    ),
    noticeCatalogs: z.array(
      CatalogCollectionMetadataVehicleInspectionSchema(
        NoticeCatalogVersionsSchema,
      ),
    ),
  })
  .strict();

export const CatalogCollectionMetadataIndustryServicesSchema = z
  .object({
    id: z.string(),
    downloadId: z.string(),
    startDate: z.string().date(),
    catalogVersion: z.string(),
  })
  .strict();

export type CatalogCollectionMetadataIndustryServices = z.infer<
  typeof CatalogCollectionMetadataIndustryServicesSchema
>;

export type VehicleInspection = z.infer<typeof VehicleInspectionSchema>;

export const IndustryServicesSchema = z
  .object({
    type: z.literal(InspectionCatalogMetadataType.INDUSTRY_SERVICES),
    elevator: z
      .object({
        defectCatalogs: z.array(
          CatalogCollectionMetadataIndustryServicesSchema,
        ),
        noticeCatalogs: z.array(
          CatalogCollectionMetadataIndustryServicesSchema,
        ),
      })
      .strict(),
  })
  .strict();

export type IndustryServices = z.infer<typeof IndustryServicesSchema>;

export const DevicePropertyInspectionCatalogsMetadataSchema =
  z.discriminatedUnion('type', [
    VehicleInspectionSchema,
    IndustryServicesSchema,
  ]);

export type DevicePropertyInspectionCatalogsMetadata = z.infer<
  typeof DevicePropertyInspectionCatalogsMetadataSchema
>;

import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from '@tanstack/react-router';
import { Alert, Button, Skeleton, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Breadcrumb from 'components/common/Breadcrumb';
import IdTag from 'components/common/IdTag';
import PageContentContainer from 'components/common/PageContentContainer';
import PageTitleBar from 'components/common/PageTitleBar';
import ResourceIcons from 'components/common/resourceIcons';
import { FlexBox, FlexOne } from 'components/Helpers';
import OrganizationModal from 'components/organizations/OrganizationModal';
import organizationTypes from 'defaults/organizationTypes';
import useOrganizations from 'hooks/organizations/useOrganizations';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { Organization } from 'interfaces/organization';
import {
  numberLaunchedLicencesMetadataKey,
  organizationActiveDevices,
  organizationActiveUsers,
  organizationTypeMetadataKey,
} from 'organizationMetadataKeys';
import { useState } from 'react';
import Highlighter from 'react-highlight-words';

const OrganizationsView: React.FC = () => {
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { organizations: data, isLoading, refetch } = useOrganizations();

  const getColumnSearchProps = useTableSearchFilter<Organization>();

  const columns: ColumnsType<Organization> = [
    {
      title: 'Management View',
      key: 'go to organization management view',
      render: (org) => (
        <FlexBox withgap>
          <Link
            to="/management/$organization"
            params={{
              organization: org.id,
            }}
          >
            <Button type="primary" icon={<ArrowRightOutlined />} />
          </Link>
          <Link
            to="/admin/organizations/$organizationId"
            params={{ organizationId: org.id }}
          >
            <Button>Details</Button>
          </Link>
        </FlexBox>
      ),
    },
    {
      title: 'ID',
      ...getColumnSearchProps(
        'id',
        'id',
        (record) => record.id,
        (record, _, searchText) => (
          <Link
            to="/admin/organizations/$organizationId"
            params={{ organizationId: record.id }}
          >
            <IdTag
              copyable={false}
              id={record.id}
              isCollapsed={false}
              highlight={searchText}
            />
          </Link>
        ),
      ),
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: 'Name',
      ...getColumnSearchProps(
        'name',
        'name',
        (record) => record.name,
        (record, _, searchText) => (
          <Highlighter
            searchWords={[searchText]}
            textToHighlight={record.name}
          />
        ),
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Organisation Typ',
      key: 'organizationTypes',
      filters: organizationTypes.map((type) => ({ text: type, value: type })),
      filterSearch: true,
      onFilter: (value, org) =>
        org.metadata[organizationTypeMetadataKey]
          ? JSON.parse(org.metadata[organizationTypeMetadataKey]).includes(
              value,
            )
          : false,
      render: (org) => {
        if (!org.metadata[organizationTypeMetadataKey]) return null;
        return (
          <>
            {JSON.parse(org.metadata[organizationTypeMetadataKey]).map(
              (type: string) => (
                <Tag key={type}>{type}</Tag>
              ),
            )}
          </>
        );
      },
    },
    {
      title: 'Launched Licences',
      render: (_, record) => record.metadata[numberLaunchedLicencesMetadataKey],
      sorter: (a, b) =>
        Number(a.metadata[numberLaunchedLicencesMetadataKey] ?? 0) -
        Number(b.metadata[numberLaunchedLicencesMetadataKey] ?? 0),
    },
    {
      title: 'Active Devices',
      render: (_, record) => record.metadata[organizationActiveDevices],
      sorter: (a, b) =>
        Number(a.metadata[organizationActiveDevices] ?? 0) -
        Number(b.metadata[organizationActiveDevices] ?? 0),
    },
    {
      title: 'Active Users',
      render: (_, record) => record.metadata[organizationActiveUsers],
      sorter: (a, b) =>
        Number(a.metadata[organizationActiveUsers] ?? 0) -
        Number(b.metadata[organizationActiveUsers] ?? 0),
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[{ name: 'Organisationen', href: '/admin/organizations' }]}
      />
      <PageTitleBar
        title="Organisationen"
        Icon={ResourceIcons.Organization}
        renderToolbar={() => (
          <FlexBox withgap>
            <FlexOne />
            <Button
              type="primary"
              onClick={() => setIsCreateModalOpen(true)}
              icon={<PlusOutlined />}
            >
              Anlegen
            </Button>
          </FlexBox>
        )}
      />
      <PageContentContainer>
        <OrganizationModal
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          onCreated={(organization) => {
            refetch();
            navigate({
              to: '/admin/organizations/$organizationId',
              params: { organizationId: organization.id },
            });
          }}
        />
        {isLoading ? (
          <Skeleton active />
        ) : data ? (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              position: ['bottomRight', 'topRight'],
              showSizeChanger: true,
              defaultPageSize: 100,
            }}
          />
        ) : (
          <Alert message="Error fetching organizations" type="error" />
        )}
      </PageContentContainer>
    </>
  );
};

export default OrganizationsView;

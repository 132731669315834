import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  message,
  Skeleton,
  Table,
  TableColumnsType,
} from 'antd';
import IdTag from 'components/common/IdTag';
import ListItemInfoTag from 'components/common/ListItemInfoTag';
import RelativeDateTimeWithTooltip from 'components/common/RelativeDateTimeWithTooltip';
import { FlexBox, FlexOne } from 'components/Helpers';
import LabelList from 'components/views/LabelList';
import useManagementDeviceActivationRequests from 'hooks/management-device-activation-requests/useManagementDeviceActivationRequests';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { ManagementDeviceActivationRequest } from 'interfaces/managementActivationRequest';
import { deviceRoleLabel } from 'labels';
import { DateTime } from 'luxon';
import Highlighter from 'react-highlight-words';
import ManagementDeviceActivationActions from './ManagementDeviceActivationActions';

const ManagementDevicesActivationRequestTable: React.FC = () => {
  const { sortedRequests, refetch, isLoading, isRefetching } =
    useManagementDeviceActivationRequests();

  const reload = async () => {
    await refetch();
    message.success('Aktivierungsanfragen wurden aktualisiert');
  };

  const getColumnSearchProps =
    useTableSearchFilter<ManagementDeviceActivationRequest>();

  const columns: TableColumnsType<ManagementDeviceActivationRequest> = [
    {
      title: 'Status',
      sorter: (a, b) => {
        if (!a.approvedAt) {
          return 1;
        }
        if (!b.approvedAt) {
          return -1;
        }
        return a.approvedAt.localeCompare(b.approvedAt);
      },
      ...getColumnSearchProps(
        'device id',
        'device_id',
        (activationRequest) => activationRequest.id,
        (activationRequest) => (
          <FlexBox
            withgap
            gap={4}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <ManagementDeviceActivationActions
              activationRequest={activationRequest}
              onDeleted={reload}
            />
          </FlexBox>
        ),
      ),
    },
    {
      title: 'ID',
      sorter: (a, b) => a.id.localeCompare(b.id),
      onFilter: (value, record) => record.id === value,
      ...getColumnSearchProps(
        'id',
        'id',
        (request) => request.id,
        (request, _, searchText) => (
          <IdTag id={request.id} isCollapsed={true} highlight={searchText} />
        ),
      ),
    },
    {
      title: 'E-Mail Adresse bestätigt',
      sorter: (a, b) => a.userName?.localeCompare(b.userName ?? '') ?? 0,
      filterSearch: true,
      onFilter: (value, record) => record.userName === value,
      render: (_, request) => (
        <ListItemInfoTag
          valueStyle={{
            backgroundColor: request.userEmailVerifiedAt
              ? 'hsla(90, 100%, 72%, 1)'
              : 'hsla(0, 100%, 72%, 1)',
          }}
        >
          <RelativeDateTimeWithTooltip
            timestamp={request.userEmailVerifiedAt}
            noneText="Noch nicht bestätigt"
          />
        </ListItemInfoTag>
      ),
    },
    {
      title: 'Name',
      sorter: (a, b) => a.userName?.localeCompare(b.userName ?? '') ?? 0,
      filterSearch: true,
      onFilter: (value, record) => record.userName === value,
      ...getColumnSearchProps(
        'user name',
        'user_name',
        (request) => request.userName,
        (request, _, searchText) => (
          <Highlighter
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={request.userName}
          />
        ),
      ),
    },
    {
      title: 'E-Mail Adresse',
      sorter: (a, b) => a.userEmail?.localeCompare(b.userEmail ?? '') ?? 0,
      filterSearch: true,
      onFilter: (value, record) => record.userEmail === value,
      ...getColumnSearchProps(
        'user email',
        'user_email',
        (request) => request.userEmail,
        (request, _, searchText) => (
          <Highlighter
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={request.userEmail}
          />
        ),
      ),
    },
    {
      title: 'Rolle',
      sorter: (a, b) =>
        a.metadata[deviceRoleLabel]?.localeCompare(
          b.metadata[deviceRoleLabel] ?? '',
        ) ?? 0,
      filterSearch: true,
      onFilter: (value, record) => record.metadata[deviceRoleLabel] === value,
      ...getColumnSearchProps(
        'user role',
        'user_role',
        (request) => request.metadata[deviceRoleLabel],
        (request, _, searchText) => (
          <Highlighter
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={request.metadata[deviceRoleLabel]}
          />
        ),
      ),
    },
    {
      title: 'Erstellt',
      sorter: (a, b) => a.createdAt?.localeCompare(b.createdAt ?? '') ?? 0,
      filterSearch: true,
      onFilter: (value, record) => record.createdAt === value,
      render: (_, request) =>
        DateTime.fromISO(request.createdAt).toFormat('dd.MM.yyyy HH:mm:ss'),
    },
    {
      title: 'Labels',
      hidden:
        sortedRequests?.filter((r) => Object.keys(r.labels).length > 0)
          .length === 0,
      ...getColumnSearchProps(
        'labels',
        'labels',
        (request) => JSON.stringify(request.labels),
        (request, i, searchText) => (
          <LabelList
            labels={request.labels}
            highlightTexts={searchText ? [searchText] : []}
          />
        ),
      ),
    },
  ];

  if (isLoading) return <Skeleton active />;

  if (!sortedRequests)
    return (
      <Empty description="Es wurden keine Aktivierungsanfragen gefunden." />
    );

  return (
    <FlexBox direction="column" withgap>
      <FlexBox>
        <FlexOne>
          {sortedRequests?.filter((r) => !r.approvedAt).length} offene
          Aktivierungsanfrage(n)
        </FlexOne>
        <Button
          onClick={reload}
          loading={isRefetching}
          icon={<ReloadOutlined />}
        />
      </FlexBox>
      <Table columns={columns} dataSource={sortedRequests} />
    </FlexBox>
  );
};

export default ManagementDevicesActivationRequestTable;

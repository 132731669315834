import { Form, Modal } from 'antd';
import { useAdminDeviceApiV4 } from 'api/devices/useAdminDeviceApiV4';
import wellKnownAdminDeviceLabels from 'defaults/deviceLabels';
import { AdminDeviceV4Dto } from 'interfaces/adminDeviceV4';
import React from 'react';
import { getLabelsDiff } from 'utlis/getLabelsDiff';
import LabelsFormList, {
  convertFromFormList,
  convertToFormList,
} from '../common/LabelsFormList';

interface Props {
  device: AdminDeviceV4Dto;
  onClose?: () => void;
  onEdit?: () => Promise<void>;
}

interface FormData {
  labels: {
    key: string;
    value: string;
  }[];
}

const AdminDeviceEditModal: React.FC<Props> = ({ device, onClose, onEdit }) => {
  const [form] = Form.useForm<FormData>();

  const { patchDeviceLabels } = useAdminDeviceApiV4();

  const onSubmit = async (data: FormData) => {
    const labels = convertFromFormList(data.labels);
    const patchLabels = getLabelsDiff(device.labels, labels);
    await patchDeviceLabels(device.id, patchLabels);
    onEdit && (await onEdit());
  };

  return (
    <Modal
      title="Edit Device"
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      okText="Update Device"
      width={900}
    >
      <Form<FormData>
        form={form}
        onFinish={onSubmit}
        initialValues={{
          labels: convertToFormList(device.labels),
        }}
      >
        <LabelsFormList
          name="labels"
          label="Label"
          labelOptions={wellKnownAdminDeviceLabels}
        />
      </Form>
    </Modal>
  );
};

export default AdminDeviceEditModal;

import OrganizationSwitcherShortcut from 'components/admin/OrganizationSwitcherShortcut';
import PermissionGate from 'components/views/PermissionGate';
import AuthProvider from './AuthProvider';
import Scaffold from './Scaffold';

interface Props {
  children?: React.ReactNode;
}

const ScaffoldWithAuth: React.FC<Props> = ({ children }) => {
  return (
    <AuthProvider>
      <PermissionGate>
        <OrganizationSwitcherShortcut />
        <Scaffold>{children}</Scaffold>
      </PermissionGate>
    </AuthProvider>
  );
};

export default ScaffoldWithAuth;

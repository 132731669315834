export const readDevicesScope = 'device-registration.api.voize.de/read:devices';
export const writeDevicesScope =
  'device-registration.api.voize.de/write:devices';
export const deleteDevicesScope =
  'device-registration.api.voize.de/delete:devices';
export const readDeviceConfigurationsScope =
  'device-registration.api.voize.de/read:device-configurations';
export const writeDeviceConfigurationsScope =
  'device-registration.api.voize.de/write:device-configurations';
export const deleteDeviceConfigurationsScope =
  'device-registration.api.voize.de/delete:device-configurations';
export const readDeviceActivationCodes =
  'device-registration.api.voize.de/read:device-activation-codes';
export const sendDeviceRegistrationMails =
  'device-registration.api.voize.de/send:device-registration-mails';

// users
export const readUsersScope = 'device-registration.api.voize.de/read:users';
export const writeUsersScope = 'device-registration.api.voize.de/write:users';
export const deleteUsersScope = 'device-registration.api.voize.de/delete:users';

// device activation request
export const readAdminDeviceActivationRequestsScope =
  'device-registration.api.voize.de/read:device-activation-requests';
export const writeAdminDeviceActivationRequestsScope =
  'device-registration.api.voize.de/write:device-activation-requests';
export const deleteAdminDeviceActivationRequestsScope =
  'device-registration.api.voize.de/delete:device-activation-requests';

// organization-devices
export const readOrganizationDevicesScope =
  'device-registration.api.voize.de/read:organization-devices';
export const writeOrganizationDevicesScope =
  'device-registration.api.voize.de/write:organization-devices';
export const deleteOrganizationDevicesScope =
  'device-registration.api.voize.de/delete:organization-devices';
export const readOrganizationDeviceActivationCodes =
  'device-registration.api.voize.de/read:organization-device-activation-codes';

// management device activation request
export const readManagementDeviceActivationRequestsScope =
  'device-registration.api.voize.de/read:organization-device-activation-requests';
export const writeManagementDeviceActivationRequestsScope =
  'device-registration.api.voize.de/write:organization-device-activation-requests';
export const deleteManagementDeviceActivationRequestsScope =
  'device-registration.api.voize.de/delete:organization-device-activation-requests';

// management device configuration
export const readOrganizationDeviceConfigurationsScope =
  'device-registration.api.voize.de/read:organization-device-configurations';
export const writeOrganizationDeviceConfigurationsScope =
  'device-registration.api.voize.de/write:organization-device-configurations';
export const deleteOrganizationDeviceConfigurationsScope =
  'device-registration.api.voize.de/delete:organization-device-configurations';

// organizations
export const readOrganizationsScope =
  'device-registration.api.voize.de/read:organizations';
export const writeOrganizationsScope =
  'device-registration.api.voize.de/write:organizations';
export const deleteOrganizationsScope =
  'device-registration.api.voize.de/delete:organizations';

export const readOrganizationSelfScope =
  'device-registration.api.voize.de/read:organization-self';

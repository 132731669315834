import { CloseOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Tag } from 'antd';
import { FlexBox } from 'components/Helpers';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

type Labels = Record<string, string>;

interface Props {
  labels: Labels;
  editable?: boolean;
  onChange?: (labels: Labels) => void;
  highlightTexts?: string[];
  containerStyle?: React.CSSProperties;
  labelOptions?: Record<string, string[]>;
}

const EllipsisLabel = ({ children }: { children: React.ReactNode }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div
      onClick={() => setIsClicked((isClicked) => !isClicked)}
      style={{
        textOverflow: 'ellipsis',
        maxWidth: '500px',
        overflow: isClicked ? 'initial' : 'hidden',
        cursor: 'pointer',
        wordWrap: 'break-word',
        whiteSpace: isClicked ? 'pre-wrap' : undefined,
      }}
    >
      {children}
    </div>
  );
};

const LabelList: React.FC<Props> = ({
  labels,
  onChange = () => {},
  editable = false,
  highlightTexts = [],
  containerStyle,
  labelOptions = {},
}) => {
  const [labelList, setLabelList] = useState<Labels>(labels);
  const [newLabelKey, setNewLabelKey] = useState('');
  const [newLabelValue, setNewLabelValue] = useState('');
  useEffect(() => {
    setLabelList(labels);
  }, [labels]);

  const removeLabel = (key: string) => {
    const newLabelList = { ...labelList };
    delete newLabelList[key];
    setLabelList(newLabelList);
    onChange(newLabelList);
  };

  const addSpecificLabel = (key: string, value: string) => {
    const newLabelList = { ...labelList, [key]: value };
    setLabelList(newLabelList);
    onChange(newLabelList);
  };

  const addLabel = () => {
    addSpecificLabel(newLabelKey.trim(), newLabelValue);
    setNewLabelKey('');
    setNewLabelValue('');
  };

  return (
    <div style={{ width: '100%' }}>
      <FlexBox wrap style={containerStyle}>
        {Object.keys(labelList).map((key) => (
          <Tag key={'tag-' + key} style={{ margin: 5 }} color={'blue'}>
            <FlexBox alignitems="center">
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={highlightTexts}
                autoEscape
                textToHighlight={key}
              />
              :
              <EllipsisLabel>
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={highlightTexts}
                  autoEscape
                  textToHighlight={labelList[key]}
                />
              </EllipsisLabel>
              {editable && (
                <CloseOutlined
                  onClick={() => removeLabel(key)}
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                />
              )}
            </FlexBox>
          </Tag>
        ))}
      </FlexBox>
      {editable && (
        <Container>
          <AutoComplete
            options={Object.keys(labelOptions)
              .filter(
                (labelKey) =>
                  labelKey.toLowerCase().indexOf(newLabelKey.toLowerCase()) !==
                  -1,
              )
              .map((labelKey) => ({
                label: labelKey,
                value: labelKey,
              }))}
            style={{ flex: 1 }}
            placeholder="key"
            value={newLabelKey}
            onChange={(value) => setNewLabelKey(value)}
          />
          <AutoComplete
            options={
              newLabelKey in labelOptions
                ? labelOptions[newLabelKey]
                    .filter(
                      (labelValue) =>
                        labelValue
                          .toLowerCase()
                          .indexOf(newLabelValue.toLowerCase()) !== -1,
                    )
                    .map((labelValue) => ({
                      label: labelValue,
                      value: labelValue,
                    }))
                : []
            }
            style={{ flex: 1 }}
            placeholder="value"
            value={newLabelValue}
            onChange={(value) => setNewLabelValue(value)}
          />
          <Button
            type="primary"
            onClick={addLabel}
            disabled={!newLabelKey.trim()}
          >
            Add
          </Button>
        </Container>
      )}
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  width: 100%;
  gap: 5px;
`;

export default LabelList;

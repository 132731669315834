// import './App.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { ConfigProvider, message } from 'antd';
import de_DE from 'antd/locale/de_DE';
import ThemeProvider from 'components/theme/ThemeProvider';
import { getRequiredEnv } from 'config';
import { Settings } from 'luxon';
import router from 'router';
import { toBaseError } from 'utlis/BaseError';
import './index.css';
import { scope } from './scopes';

Settings.defaultLocale = 'de';

const auth0Domain = getRequiredEnv('VITE_AUTH0_DOMAIN');
const auth0ClientId = getRequiredEnv('VITE_AUTH0_CLIENT_ID');
const audience = getRequiredEnv('VITE_AUTH0_AUDIENCE');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => toBaseError(error).isSilentRetryable,
    },
    mutations: {
      onError: (error) => {
        message.error(
          `Es ist ein Fehler aufgetreten: ${toBaseError(error).message}`,
        );
      },
    },
  },
});

function App() {
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        scope: `${scope} openid profile email`,
      }}
      onRedirectCallback={(appState) => {
        router.navigate({
          to: appState?.returnTo || '/',
          params: {},
          replace: true,
        });
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={de_DE}>
          <ThemeProvider>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </ThemeProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </Auth0Provider>
  );
}

export default App;

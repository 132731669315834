import { AxiosError } from 'axios';
import { BaseError, toBaseError } from './BaseError';

const getErrorMessage = (
  error: any,
  defaultMessage = 'Etwas ist schief gelaufen.',
) => {
  if (error instanceof BaseError) {
    return error.message;
  }

  const baseError = toBaseError(error);

  if (baseError.isSilentRetryable) {
    return 'Der Server ist gerade nicht erreichbar. Probieren Sie es doch einfach nochmal.';
  }

  if (error instanceof AxiosError) {
    switch (error.response?.status) {
      case 400:
        return `Es gab einen Eingabefehler bei der Request: ${error.message}`;
      case 401:
        return 'Es gab einen Authentifizierungsfehler.';
      case 403:
        return 'Sie haben nicht die nötigen Rechte um diese Aktion auszuführen. Falls sie Zugang zu diesem Bereich haben sollten, geben Sie dem Support Bescheid.';
      case 404:
        return 'Wir konnten leider nicht finden, was sie suchen.';
      case 409:
        return 'Irgendetwas ist schief gelaufen. Wenn dieser Fehler häufiger auftritt, geben Sie dem Support Bescheid. Error: 409';
      case 500:
        return 'Es gab einen Serverfehler.';
      case 502:
        return 'Der Server ist gerade nicht erreichbar. Probieren Sie es doch einfach nochmal.';
      case 503:
        return 'Der Server ist gerade nicht erreichbar. Probieren Sie es doch einfach nochmal.';
      case 504:
        return 'Der Server ist gerade nicht erreichbar. Probieren Sie es doch einfach nochmal.';
      default:
        if (error.message) {
          return error.message;
        }
        return defaultMessage;
    }
  }

  return defaultMessage;
};

export default getErrorMessage;

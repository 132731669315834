import { Link } from '@tanstack/react-router';
import { Alert } from 'antd';
import { FlexBox } from 'components/Helpers';
import useAdminDeviceActivationRequests from 'hooks/admin-device-activation-requests/useAdminDeviceActivationRequests';

const AdminDeviceActivationAlert: React.FC = () => {
  const { openRequests } = useAdminDeviceActivationRequests();

  return (
    <>
      {openRequests.length > 0 ? (
        <Alert
          message={
            <FlexBox withgap>
              Es gibt {openRequests.length} offene Aktivierungsanfrage(n)
              <Link to="/admin/device-activation-requests">Ansehen</Link>
            </FlexBox>
          }
          type="warning"
        />
      ) : (
        <Alert
          message={
            <FlexBox withgap>
              Es gibt keine offenen Aktivierungsanfragen
              <Link to="/admin/device-activation-requests">
                Angenommene ansehen
              </Link>
            </FlexBox>
          }
          type="success"
          showIcon
        />
      )}
    </>
  );
};

export default AdminDeviceActivationAlert;
